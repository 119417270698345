import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const OfferCardNewPeriod = (props) => {
  const { t } = useTranslation();
  const {
    color,
    setPricingIndex,
    classCard,
    offerLength,
    isNewPeriod,
    setSelectedLabel,
    selectedLabel,
    setPeriod,
    indexButton,
    offerAllYearLength,
    allYearExists,
  } = props;

  const newPeriods = [
    { label: 'Par mois', value: 'monthly' },
    { label: "reste de l'année", value: 'reste' },
  ];

  const normalPeriods = [
    { label: 'Par mois', value: 'monthly' },
    { label: "reste de l'année", value: 'reste' },
  ];
  const ArrNewPeriods = allYearExists
    ? newPeriods
    : newPeriods.filter((item) => item.value !== 'reste');
  const ArrNormalPeriods = allYearExists
    ? normalPeriods
    : normalPeriods.filter((item) => item.value !== 'reste');

  return (
    <div className={`offer-periods ${classCard}`}>
      {ArrNewPeriods && isNewPeriod
        ? ArrNewPeriods.map((period, index) => {
            return (
              <div
                onClick={() => {
                  setPeriod(
                    period.label === "reste de l'année" ? "reste de l'année" : '1'
                    //indexButton
                  );
                  setSelectedLabel(period.label);
                  //setPricingIndex(offerLength);
                  setPricingIndex(
                    period.label === "reste de l'année"
                      ? offerAllYearLength
                      : period.label === 'Par mois'
                      ? //offerLength:
                        0
                      : ''
                  );
                }}
                className={
                  selectedLabel === period.label
                    ? period.label
                      ? 'period selected-period'
                      : 'last-period selected-period'
                    : period.label
                    ? 'period'
                    : 'last-period'
                }
                style={{
                  backgroundColor: selectedLabel === period.label ? color : null,
                  borderColor: color,
                  width: 70,
                }}
              >
                {period.label === "reste de l'année" ? t('reste de l année') : t(period.label)}{' '}
                {
                  //!period.quantity
                  !isNaN(period) && (
                    <div>
                      {period}
                      {/* { ((i18n.language === 'ar' || 'en') && (period)>=3) ? t("trois ou six mois"): t("mois")} */}
                    </div>
                  )
                }
              </div>
            );
          })
        : ArrNormalPeriods.map((period, index) => {
            return (
              <div
                onClick={() => {
                  setPeriod(
                    period.label === "reste de l'année" ? "reste de l'année" : '1'
                    //indexButton
                  );
                  setSelectedLabel(period.label);
                  setPricingIndex(
                    period.label === "reste de l'année" ? offerAllYearLength : 0
                    //offerLength
                  );
                }}
                className={
                  selectedLabel === period.label
                    ? period.label
                      ? 'period selected-period'
                      : 'last-period selected-period'
                    : period.label
                    ? 'period'
                    : 'last-period'
                }
                style={{
                  backgroundColor: selectedLabel === period.label ? color : null,
                  borderColor: color,
                  width: 70,
                }}
              >
                {period.label === "reste de l'année" ? t('reste de l année') : t(period.label)}{' '}
                {
                  //!period.quantity
                  !isNaN(period) && (
                    <div>
                      {period}
                      {/* { ((i18n.language === 'ar' || 'en') && (period)>=3) ? t("trois ou six mois"): t("mois")} */}
                    </div>
                  )
                }
              </div>
            );
          })}
    </div>
  );
};

OfferCardNewPeriod.propTypes = {
  color: PropTypes.string,
  newPeriods: PropTypes.any,
  setSeelectedLabel: PropTypes.func,
  setPricingIndex: PropTypes.func,
  selectedLabel: PropTypes.any,
};

export default OfferCardNewPeriod;
