import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import imAvatar from '../../assets/img/icons/avatar.png';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { DivisionsReformat } from '../../utilities/methods';

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const Index = ({ name, last_name, division, phone, email, adress, avatar, userId, isTest }) => {
  const { t } = useTranslation();

  return (
    <div className="profile-infos">
      <div className="inline-btns-group">
        {/* <Button
          className="subs-btn"
          variant="contained"
          color="primary"
          size="small"
          disableElevation
        >
          Silver
        </Button> */}
        {!isTest && (
          <Link to="/mon-profil/edit">
            <Button className="action-subs-btn" variant="outlined" color="primary" size="small">
              {t('modifier')}
            </Button>
          </Link>
        )}
      </div>
      <StyledBadge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        <Avatar alt={name} src={avatar} id="img-preview" />
      </StyledBadge>
      <span className="text-blue">
        {name} {last_name}
      </span>
      <span
        className="text-muted"
        dangerouslySetInnerHTML={{
          __html: `${DivisionsReformat(division)}`,
        }}
      ></span>
      <span className="mt"></span>
      {userId && (
        <span className="text-muted">
          <span className="text-dark">{t('user Id')}:</span> {userId}
        </span>
      )}
      {phone && (
        <span className="text-muted">
          <span className="text-dark">{t('tel')}:</span> {phone}
        </span>
      )}
      {email && (
        <span className="text-muted">
          <span className="text-dark">{t('email')}:</span> {email}
        </span>
      )}
      {adress && (
        <span className="text-muted">
          <span className="text-dark">{t('Adresse')}:</span> {adress}
        </span>
      )}

      <hr />
      <div className="header-bg"></div>
    </div>
  );
};

Index.propTypes = {
  name: PropTypes.string,
  division: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  adress: PropTypes.string,
  avatar: PropTypes.string,
};

export default Index;
