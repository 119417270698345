import React, { useEffect, useState } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next';
import { Button, Divider, IconButton } from '@material-ui/core';
import { deleteDiscountCodes } from '../../../redux/slices/points';
import { useDispatch } from 'react-redux';
import Im_paiment from '../../../assets/img/icons/paiment.svg';

const PaimentOoreedoo = (props) => {
  const { id, couponCode, offerId, check, icon, text, color, price,onClick,image,messageValidation,
      buttonText,buttonWidth, disabledBtn, ...rest } = props;
  const { t, i18n } = useTranslation();
  const [offerCheck, setOfferCheck] = useState(false);
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteDiscountCodes(id));
  };
  return (
    <div className="paiment-ooreedoo">
      <div className="Paiment-titel">
         { t('Paiement - Ooredoo')}
          </div>
          <Divider style={{margin:'20px' ,width: '87%' }} />      
      <div className="paiment-ooredoo-box">
       <div className="paiment-ooreedoo-icon">
          <img src={image} />
        </div >
      { messageValidation && <div className='message-validation'>
       {messageValidation}
        </div>}
        <div className="text-paiment-ooreedoo">{text}</div>
      </div>
        <Button
            style={{ backgroundColor: color, width: buttonWidth}}
            onClick={onClick} className="acheter"
            disabled={disabledBtn}
        >
            {disabledBtn ? t('You are already registered') : buttonText}
        </Button>
    </div>
  );
};

export default PaimentOoreedoo;
