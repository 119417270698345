import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {socialLoginCallback} from '../../redux/slices/auth';
import { useParams } from "react-router-dom";

const Index = (props) => {
  const dispatch = useDispatch();
  const params = new URLSearchParams(props.location.search);

  const code = params.get('code'); // bar
  const { provider } = useParams();
  useEffect(() => {
    if (!code) {
      window.location = '/';
    }
    dispatch(socialLoginCallback({provider: provider, code: code}));
  }, [provider]);
  return (
      <div></div>
  );
};

export default Index;