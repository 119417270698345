import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
  name: 'notifier',
  initialState: {
    notifications: [],
  },
  reducers: {
    addNotif: (state, action) => {
      state.notifications = [
        ...state.notifications,
        {
          key: action.payload.key,
          ...action.payload.notification,
        },
      ];
    },
    closeNotif: (state, action) => {
      state.notifications = state.notifications.map((notification) =>
        action.payload.dismissAll || notification.key === action.payload.key
          ? { ...notification, dismissed: true }
          : { ...notification }
      );
    },
    removeNotif: (state, action) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== action.payload.key
      );
    },
  },
});
export default slice.reducer;
// Actions
const { addNotif, closeNotif, removeNotif } = slice.actions;

export const enqueueSnackbar = (notification) => (dispatch) => {
  const key = notification.options && notification.options.key;
  try {
    const res = {
      notification: {
        ...notification,
        key: key || new Date().getTime() + Math.random(),
      },
    };
    dispatch(addNotif(res));
  } catch (e) {
    return console.error(e.message);
  }
};

export const closeSnackbar = (key) => (dispatch) => {
  try {
    const res = {
      dismissAll: !key, // dismiss all if no key has been defined
      key,
    };
    setTimeout(() => {
      dispatch(closeNotif(res));
    }, 2000);
  } catch (e) {
    return console.error(e.message);
  }
};

export const removeSnackbar = (key) => (dispatch) => {
  try {
    const res = {
      key,
    };
    dispatch(removeNotif(res));
  } catch (e) {
    return console.error(e.message);
  }
};
