import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from '../TextError';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import './_index.scss';

function Input({
  label,
  name,
  className,
  errorMessage,
  color,
  isDisabled,
  required,
  leftTag,
  rightTag,
  rightIcon,
  leftIcon,
  tagClassName,
  placeHolder,
  labelDescription,
  error,
  ...rest
}) {
  return (
    <div className={'ooredoo-input-field'}>
      {label && (
        <>
          <label htmlFor={name}>
            {required && (
              <>
                <p>{label}</p> <span className="required-field">*</span>
              </>
            )}
          </label>
        </>
      )}
      <div className="field-wrapper">
        <div className={`field ${error && 'field-error-style'}`}>
          {leftTag && <span className={tagClassName}>{leftTag}</span>}
          {leftIcon && <img src={leftIcon} />}
          <Field
            id={name}
            name={name}
            disabled={isDisabled}
            style={{ color: color ? color : '' }}
            placeholder={placeHolder}
            {...rest}
          />
          {rightTag && <span className={tagClassName}>{rightTag}</span>}
          {rightIcon && rightIcon}
        </div>

        {error ? (
          <p className="error">
            <>
              {error?.trim().length > 0 && <ReportProblemIcon />} {error}
            </>
          </p>
        ) : (
          <ErrorMessage component={TextError} name={name} />
        )}
      </div>
    </div>
  );
}

export default Input;
