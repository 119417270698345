import { createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';
import { ord } from '../../utilities/methods';

// Slice
const slice = createSlice({
  name: 'latest_courses',
  initialState: {
    latest_courses: null,
  },
  reducers: {
    latestCoursesSuccess: (state, action) => {
      const options = [
        { label: 'subject', accessor: 'subject_name' },
        { label: 'subject_slug', accessor: 'subject_slug' },
        { label: 'subject_thumbnail', accessor: 'subject_thumbnail' },
        { label: 'content_name', accessor: 'name' },
        { label: 'chapter', accessor: 'chapter_name' },
        { label: 'chapter_slug', accessor: 'chapter_slug' },
        { label: 'content_slug', accessor: 'slug' },
        { label: 'teacher', accessor: 'content_instructor' },
        { label: 'division', accessor: 'division' },
        { label: 'createdAt', accessor: 'createdAt' },
        { label: 'chapter_type', accessor: 'chapter_type' },

      ];
      const data =
        action.payload.data.payload.new_contents.length > 0
          ? action.payload.data.payload.new_contents.slice(0, 2)
          : action.payload.data.payload.new_contents;
      state.latest_courses = data.length > 0 ? ord(data, options) : [];
    },
  },
});
export default slice.reducer;
// Actions
const { latestCoursesSuccess } = slice.actions;

export const getLatestCourses = () => async (dispatch) => {
  try {
    const res = await api.get('/student/chapters/new-contents');
    dispatch(latestCoursesSuccess(res));
  } catch (e) {
    return console.error(e.message);
  }
};
