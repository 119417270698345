import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import notif from '../../assets/img/icons/notifications.svg';
import Badge from '@material-ui/core/Badge';
import { getPopups } from '../../redux/slices/popups';
import { Typography } from '@material-ui/core';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import * as dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
  dropdown: {
    marginLeft: '8px',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));

const Index = ({ userId }) => {
  const { popups } = useSelector((state) => state.popups);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const nbMessages = (popups && popups.popups && popups.popups.length) || (popups && popups.length);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    dispatch(getPopups(userId));
  }, [userId]);

  return (
    <div className={classes.dropdown + ' message'}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge color="secondary" badgeContent={nbMessages} showZero>
          <img alt="message" src={notif} className="message-icon" />
        </Badge>
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              marginTop: '25px',
              marginRight: '200px',
              width: '250px',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <Typography className="menu-item-title">Notification</Typography>
                  {popups && popups.length ? (
                    popups.map((popup) => (
                      <MenuItem onClick={handleClose}>
                        <div>
                          <div className="message-header">
                            <CallToActionIcon style={{ color: '#ec5542' }} />
                            <Typography className="message-title">TakiAcademy</Typography>
                          </div>
                          <div className="group-message">
                            <Typography className="message-body">
                              {/* {message.message.body.replace(/<[^>]+>/g, '')} */}
                              {popup.popup.name}
                            </Typography>
                            <Typography className="message-time">
                              {dayjs(popup.popup.updated_att).format('hh:mm')}
                            </Typography>
                          </div>
                        </div>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem onClick={handleClose}>Pas de notifications</MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default Index;
