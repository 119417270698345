import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { ShowMoreLink, SubscriptionItem2, ProfileInfos, Empty } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { me } from '../../redux/slices/user';
import Button from '@material-ui/core/Button';
import imAvatar from '../../assets/img/icons/avatar.png';
import imAvatarMale from '../../assets/img/icons/avatar-male.png';
import imAvatarFemale from '../../assets/img/icons/avatar-female.png';
import ImOffer from '../../assets/img/icons/subscription.svg';
import { avatarByGender } from '../../utilities/methods';

const Index = ({ mini }) => {
  const { t } = useTranslation();
  const { informations } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [name, setName] = React.useState('');
  const [last_name, setLastName] = React.useState('');
  const [first_name, setFirstName] = React.useState('');
  const [division, setDivision] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [adress, setAdress] = React.useState('');
  const [avatar, setAvatar] = React.useState();
  const [offers, setOffers] = React.useState([]);
  // React.useEffect(() => {
  //   if (!informations) {
  //     dispatch(me());
  //   }
  // }, []);

  React.useEffect(() => {
    if (informations) {
      setName(informations.name);
      setFirstName(informations.first_name);
      setLastName(informations.last_name);
      setDivision(informations.division);
      setPhone(informations.phone);
      setEmail(informations.email);
      setAdress(informations.state);
      setAvatar(avatarByGender(informations.avatar, informations.gender));
      setOffers(informations.offers === false ? [] : informations.offers || []);
    }
  }, [informations]);

  return (
    <Card className="ta-card">
      <CardContent>
        <div className="child-full profile-widget-container">
          {!mini && (
            <ProfileInfos
              name={name}
              last_name={last_name}
              division={division}
              phone={phone}
              email={email}
              adress={adress}
              avatar={avatar}
              userId={informations?.id}
              isTest={informations?.isTest}
            />
          )}
          {mini && (
            <span className="header">
              {t('bonjour')} <span className="bold">{first_name}</span>
            </span>
          )}
          {offers && offers.length > 0 ? (
            <div className="offers-list">
              <span className="label">{t('votre abonnement actuel est')}</span>
              {offers.map((offer, index) => {
                return <SubscriptionItem2 index={index} offer={offer} key={index} />;
              })}
              {/* <Button className="action-subs-btn" href="/offres" color="primary" size="small">
                Changer le Plan
              </Button> */}
            </div>
          ) : (
            <Empty label={t('pas d offres')} icon={ImOffer} className="no-borders" />
          )}
          <hr />
          {mini ? (
            <ShowMoreLink path="/mon-profil" />
          ) : (
            <ShowMoreLink label={t('voir les offres')} path="/offres" />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

Index.propTypes = {
  dispatch: PropTypes.func,
  informations: PropTypes.object,
  mini: PropTypes.any,
  name: PropTypes.string,
  setName: PropTypes.func,
  first_name: PropTypes.string,
  setFirstName: PropTypes.func,
  division: PropTypes.string,
  setDivision: PropTypes.func,
  phone: PropTypes.string,
  setPhone: PropTypes.func,
  email: PropTypes.func,
  setEmail: PropTypes.func,
  adress: PropTypes.func,
  setAdress: PropTypes.func,
  avatar: PropTypes.string,
  setAvatar: PropTypes.func,
  offers: PropTypes.array,
  setOffers: PropTypes.func,
};

export default Index;
