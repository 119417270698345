import React from 'react';
import PropTypes from 'prop-types';

const Index = ({ title, className }) => {
  return (
    <div className={'card-title ' + className || ''}>
      <span>{title}</span>
    </div>
  );
};

Index.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default Index;
