import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const OfferBuyButton = (props) => {
  const { t } = useTranslation();
  const { color, button, handleSubmit, isLoading, offerId, disabled, sliceAmount, disableButton } =
    props;

  const [colorCondition, setCondition] = useState(button != 'Acheter');
  useEffect(() => {
    setCondition(button != 'Acheter');
  }, [button]);

  return (
    <div
      //className={`offer-submit-buy ${classButton}`}
      className="offer-submit-buy"
      style={{
        backgroundColor: colorCondition ? 'white' : color,
        color: colorCondition ? color : 'white',
        border: colorCondition ? `1.8px solid ${color}` : 'none',
      }}
      onMouseEnter={() => setCondition(!colorCondition)}
      onMouseLeave={() => setCondition(!colorCondition)}
      onClick={!isLoading && !disabled && disableButton === false ? handleSubmit : null}
    >
      {isLoading == offerId ? (
        <CircularProgress size={35} color={color} />
      ) : (
        <>
          <p className="offerButtonText">{`${button} `}</p>
          {sliceAmount && <p className="offerButtonText"> {`  (${sliceAmount} ${t('TND')})`} </p>}
        </>
      )}
    </div>
  );
};
OfferBuyButton.propTypes = {
  color: PropTypes.string,
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  offerId: PropTypes.number,
  disabled: PropTypes.bool,
};
export default OfferBuyButton;
