import React from 'react';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getQrCodeChek } from '../../../redux/slices/points';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';



const D17 = (props) => {
  const { onCloseModal } = props;
  const { point, QrCode, QrCodeCheck, QrCodeCheckError, isQrCodeCheckLoading } = useSelector((state) => state.points)
  const theme = useTheme();
  const { t,i18n } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch =useDispatch()
  const codeId = point && point.id;

const handleCheck=()=>{
  dispatch(getQrCodeChek(codeId))
}

  return (
   
      <div className="d17">
        <div className="group-text-D17">
          <img src={QrCode?.urlQrCode} class="image-modal" />

          <DialogContentText  className="text-modal-D17">
            {(isQrCodeCheckLoading==true && !QrCodeCheck) ? <CircularProgress style={{color:'#50bf88',height:'70px',width:'70px'}}/>:
           QrCodeCheckError  ?<span style={{color:'#ec5542'}}>{(QrCodeCheckError)} </span>:
            QrCodeCheck ? <CheckCircleOutlineIcon style={{color:'#50bf88',height:'100px',width:'100px'}}/>:
            <span className={i18n.language === 'ar' ? 'SpanRtl' : 'SpanLtr'}> {t("Scanner ce QR code avec l'application D17")}<br/>{t("Ensuite appuyer sur continuer")} </span>}
            <div>
            {(QrCodeCheck||QrCodeCheckError)&&<Button  onClick={onCloseModal} className='button-modal-D17'>{t('Fermer')} </Button>}
            {!isQrCodeCheckLoading  &&<Button disabled={(isQrCodeCheckLoading || QrCodeCheck)?true:false} onClick={handleCheck} className='button-modal-D17'>{t('Continuer')}</Button>}
            
            </div>
          </DialogContentText>
        </div>
      </div>
   
  );
};

D17.propTypes = {
  onCloseModal: PropTypes.func,
};

export default D17;
