export const sortArrayByProperty = (list, arrayOfProperties, property) => {
  const sortedArray = [...list]?.sort((a, b) => {
    const propertyA = arrayOfProperties.indexOf(a[property]);
    const propertyB = arrayOfProperties.indexOf(b[property]);

    return propertyA - propertyB;
  });

  return sortedArray;
};
