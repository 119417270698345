import { useTranslation } from 'react-i18next';
import React, { useState ,useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal/Modal';
import { FormD17Dialog, FormTransferDialog, FormSimpleTransferModal } from '..';
import { useSelector, useDispatch } from 'react-redux';
import D17 from '../common/FormD17Dialog/D17'

const Index = ({ data }) => {
  const { t } = useTranslation();
  const { name, img, id, color } = data;
  const [open, setOpen] = useState(false);
  const [openD17, setOpenD17] = React.useState(false);
  const { isQrCodeLoading,QrCode } = useSelector((state) => state.points)
  
  
  
   useEffect(() => {
if(QrCode){
  setOpen(false);
 setOpenD17(true)
    }
  }, [QrCode])

  const openFormModal = () => {
    setOpen(true);
  };
  const onCloseModal = () => {
    setOpen(false);
  };
  const onClose=()=>{
    setOpen(false)
    window.location.replace("/porte-monnaie")
    
  }

  return (
    <>
      {data && (
        <>
          <div className="payment-methods-item" onClick={openFormModal}>
            <img alt={name} className={color} src={img} />
            <span className="title">{t(name)}</span>
          </div>
         {      id === 'd17'? <Modal
            onCloseModal={onCloseModal}
            component={
              FormD17Dialog
                
            }
            openModal={open}
            title={t('Paiement avec D17')}
            modalWidth={'xs'}
          />:

          <Modal
            onCloseModal={onCloseModal}
            component={
                id === 'transfer'
                ? FormTransferDialog
                : FormSimpleTransferModal
            }
            openModal={open}
            title={id === 'online' ? t('Paiement en ligne'):t('Versement bancaire')}
            modalWidth={'xs'}
          />}
               
        </>
      )}
      <Modal
            onCloseModal={onClose}
            component={
              D17
            }
            openModal={openD17}
            modalWidth={'xs'}
            title={t('Code QR')}
         
          />
    </>
  );
};

Index.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  img: PropTypes.string,
  id: PropTypes.string,
  open: PropTypes.bool,
  openModal: PropTypes.func,
  openFormModal: PropTypes.func,
  onCloseModal: PropTypes.func,
};

export default Index;
