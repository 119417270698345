import React from 'react';
import PropTypes from 'prop-types';
import constants from '../../utilities/constants';
import { PaymentMethodsItem } from '../../components';

const data = constants.PAYMENT_METHODS;

const Index = () => {
  return (
    <div className="payment-methods-list">
      {data &&
        data.length > 0 &&
        data.map((item, index) => {
          return <PaymentMethodsItem data={item} key={index} />;
        })}
    </div>
  );
};

Index.propTypes = {
  data: PropTypes.array,
};

export default Index;
