import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import './_index.scss';
import { useTranslation } from 'react-i18next';

const PreviousButton = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Button className="parrain-back-link" onClick={() => history.goBack()}>
      {t('Previous')}
    </Button>
  );
};

export default PreviousButton;
