import React from 'react';
import tickImg from '../../../../assets/img/icons/tick.svg';
import Silver from '../../../../assets/img/icons/Silver.png';
import MoreInformation from '../../../../components/moreInformation';
import './_index.scss';
import { Tooltip } from '@material-ui/core';
import OoredooModel from '../ooredooModal';

const offerDetails = [
  { id: 0, content: 'فيديوهات تفسير كامل الدروس في كل المواد' },
  { id: 1, content: 'تمارين مرفقة بالإصلاح مطابقة للبرنامج الرسمي ' },
  { id: 2, content: '(Magazines + PDF) مجلات تلاخيص الدروس ' },
];

const OfferCard = ({ offer, offerCard, offerHandler, open, setOpen, price }) => {
  return (
    <div
      className={
        offer?.id === 291 ? 'offer-card-with-shadow offer-card-wrapper' : 'offer-card-wrapper'
      }
      key={offer?.id}
      style={{ background: offerCard?.bgColor }}
    >
      <div className="offer-card-level">
        <Tooltip
          title={<MoreInformation informations={offerCard?.divisionsLabels} />}
          placement="bottom-end"
          className="tooltip"
        >
          <div className="info">
            <span>i</span>
          </div>
        </Tooltip>

        <h2>{offerCard?.level}</h2>
      </div>
      <div className="offer-card-container" style={{ background: offerCard?.borderColor }}>
        <div className="offer-card-content">
          <div className="offer-icon">
            <img src={Silver} alt="silver-logo" />
          </div>
          <p className="offer-title">عرض سيلفر</p>
          <div className="offer-divisions">
            {offer?.id === 147 ? (
              <p>بكالوريا جميع الشُّعب</p>
            ) : (
              <>
                {offerCard?.divisions.map((division, key) => (
                  <p key={key}>
                    {key > 0 && key < offerCard?.divisions.length && <span> و </span>}
                    <span>{division}</span>
                  </p>
                ))}
                <p> {offerCard?.level.slice(2)}&nbsp;</p>
              </>
            )}
          </div>
          <div className="costs-details">
            <div className="costs">
              <span>
                <p> د / اليوم </p> <p>&nbsp;{offer?.dailyPrice}</p>
              </span>
            </div>
            <div className="costs">
              <span>
                <p> د / الأسبوع </p> <p>&nbsp;{offer?.weeklyPrice}</p>
              </span>
            </div>
          </div>

          <div className="offer-details">
            {offerDetails.map((detail) => (
              <div className="detail-offer">
                <p>{detail.content}</p> <img src={tickImg} />
              </div>
            ))}
          </div>
          <button className="subscribe-now-btn" onClick={offerHandler}>
            اشترك الآن
          </button>
        </div>
      </div>

      <OoredooModel open={open} setOpen={setOpen} price={price} />
    </div>
  );
};

export default OfferCard;
