import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import ifvisible from 'ifvisible.js';
import {useDispatch, useSelector} from 'react-redux';
import {getNewChapterContentsProgress, saveNewProgress} from "../../../redux/slices/newProgress";

const PaidPlayer = (props) => {
  const {courseProgress, newcourseProgress} = props;
  const { courses,recordings } = useSelector((state) => state.courses)
    const dispatch = useDispatch();
  const { permissions } = useSelector((state) => state.user);
  const {currentLesson, data, chapterId} = props;
  const otp = currentLesson && currentLesson.otp;
  const playbackInfo = currentLesson && currentLesson.playbackInfo;
  const contentId = data && data.id;
  const viewToken = null;
  const isCompleted = courseProgress && courseProgress.length > 0 && courseProgress.find((p) => p.content_id === contentId);
  const subjectId=courses?courses.subject.id:recordings.subject.id
  const   isFree=permissions?.freeHours==null?false:true;

  
  useEffect(() => {
    if (data && playbackInfo) {
      const video = new window.VdoPlayer({
        otp: otp,
        playbackInfo: playbackInfo,
        theme: '9ae8bbe8dd964ddc9bdb932cca1cb59a',
        container: document.querySelector('#embedBox'),
        plugins: [{name: 'keyboard', options: {preset: 'default'}}],
      });
      video.addEventListener('load', () => {
        const duration = video.duration;
        let time=50;
        let content=0;
        let completed = 0;
        let completedSaved = false;
        let oldTotalPlayed = 0;
        const saveInterval = setInterval(() => {
          if ((video.totalCovered * 100) / duration >= 80) {
            completed = 1;
          }
          if (completed && !completedSaved) {
            dispatch(
                saveNewProgress({
                  viewToken: Math.random().toString(36).substring(2) +
                      Date.now().toString(36),
                  contentId: contentId,
                  chapterId: parseInt(chapterId),
                  completed: completed==0?false:true,
                  totalWatched: video.totalCovered,
                  watchedTime:time,
                  subjectId:subjectId,
                  isFree:isFree
                }),
            );
            completedSaved = true;
            clearInterval(saveInterval);
              setTimeout(() => {
                  dispatch(getNewChapterContentsProgress(chapterId));
              }, 2000);
          }
          if (video.status === 1) {
            if (video.totalPlayed >= oldTotalPlayed + time) {
              oldTotalPlayed += 50;
                dispatch(
                    saveNewProgress({
                        viewToken: Math.random().toString(36).substring(2) +
                            Date.now().toString(36),
                        contentId: contentId,
                        chapterId: parseInt(chapterId),
                        completed: completed==0?false:true,
                        totalWatched: video.totalCovered,
                        watchedTime:time,
                        isFree: isFree,
                        subjectId:subjectId
                    }),
                );

            }
          }
        }, 1000);

        ifvisible.on('blur', () => {
          if (video.hasOwnProperty('status') && video.status === 1) {
            video.pause();
          }
        });
        ifvisible.on('wakeup', () => {
          if (video.hasOwnProperty('status') && video.status === 2) {
            video.play();
          }
        });
      });
    }
  }, [data, otp, playbackInfo]);
  
  
  return (
      <div>
        {data && (
            <div className="rounded">
              <div id="embedBox" className="embed-responsive-item"></div>
            </div>
        )}

      </div>
  );
};

PaidPlayer.propTypes = {
  data: PropTypes.object,
  otp: PropTypes.any,
  playbackInfo: PropTypes.any,
  update: PropTypes.bool,
};

export default PaidPlayer;
