import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getMessage } from '../../redux/slices/messages';
import * as dayjs from 'dayjs';
import parse from 'html-react-parser';

const Index = ({ messageId }) => {
  const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  const { informations } = useSelector((state) => state.user);
  const { message, isMessageLoading } = useSelector((state) => state.messages);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (informations?.id) {
      dispatch(getMessage(messageId, informations?.id));
    }
  }, [messageId]);

  if (isMessageLoading) {
    return <div>loading</div>
  }
  return (
    <div className="tab-item">
      <CardContent className="card-message">
        {message && (
          <div className="card-message-container">
            <div className="spaceTitle">
              <Typography
                variant="h5"
                component="h2"
                className="title-inbox"
                style={{ fontSize: 29 }}
              >
                {message.message.name}
              </Typography>
            </div>
            <Typography className="inbox-body" variant="body2"
              component="p" display="inline">
              {parse(message.message.body)}
            </Typography>
            <div className="details">
              <Typography
                className="infos"
                color="textSecondary"
                variant="body2"
                component="p"
                display="inline"
              >
                {dayjs(message.message.created_at).format('DD/MM/YYYY')}
              </Typography>
            </div>
          </div>
        )}
      </CardContent>
    </div>
  );
};

Index.propTypes = {
  message: PropTypes.object,
};

export default Index;
