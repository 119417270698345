import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CastIcon from '@material-ui/icons/Cast';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RefreshIcon from '@material-ui/icons/Refresh';
import { CardTitle, TodayEventsList, ActionLink, ActionsGroup } from '../../components';
import { getEvents } from '../../redux/slices/today_events';
import { useDispatch, useSelector } from 'react-redux';
import * as dayjs from 'dayjs';

const Index = () => {
  const { t } = useTranslation();
  const { today_events, last_request_at } = useSelector((state) => state.today_events);
  const { informations } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [todayEvents, setTodayEvents] = React.useState([]);
  React.useEffect(() => {
    if (!today_events && informations?.division_id) {
      dispatch(getEvents());
    }
    if (last_request_at && informations?.division_id) {
      if (!dayjs().isSame(last_request_at, 'day')) {
        dispatch(getEvents());
      }
    }
  }, [informations?.division_id]);
  React.useEffect(() => {
    if (today_events && informations?.division_id) {
      setTodayEvents(today_events || []);
    }
  }, [today_events]);
  return (
    <Card className="ta-card">
      <CardHeader
        title={<CardTitle title={t('seances en direct d aujourd hui')} className="orange" />}
        action={
          <ActionsGroup>
            <ActionLink
              icon={<RefreshIcon />}
              className="text-muted"
              onClickAction={() => dispatch(getEvents())}
            />
            <ActionLink
              label={t("toutes les dates")}
              icon={<ChevronRightIcon />}
              className="text-muted"
              url="/sessions"
            />
          </ActionsGroup>
        }
        avatar={<CastIcon color="primary" className="orange" />}
      />
      <CardContent>
        <TodayEventsList events={todayEvents} />
      </CardContent>
    </Card>
  );
};

Index.propTypes = {
  today_events: PropTypes.array,
  dispatch: PropTypes.func,
  todayEvents: PropTypes.array,
  setTodayEvents: PropTypes.func,
};

export default Index;
