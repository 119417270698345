import React from 'react';
import './_index.scss';
import Cart from '../Cart';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PageHeader = ({ paths, description, noCart }) => {
  const { t, i18n } = useTranslation();
  // const pathsList = paths.split('/');
  const pathsLength = paths?.length - 1;
  return (
    <div className="parrain-page-header">
      <div className="parrain-page-title-wrapper">
        <div className="parrain-page-title">
          {paths.map((item, index) => (
            <>
              {paths?.length > 1 && index < pathsLength && (
                <Link to={item?.link}>
                  <span className="parrain-page-root-path">{t(item.label)}/</span>
                </Link>
              )}
              {index === pathsLength && (
                <span className="parrain-page-current-path">{t(item.label)}</span>
              )}
            </>
          ))}
        </div>
        {description && <div className="parrain-page-description">{t(description)}</div>}
      </div>
      {!noCart && <Cart />}
    </div>
  );
};

export default PageHeader;
