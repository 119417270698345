import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import './_index.scss';
import Input from '../../components/Form/Input';
import { Form, Formik } from 'formik';
import { Alert, Box, CircularProgress, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { createResetPasswordCode } from '../../redux/slices/auth';
import { Link, useLocation } from 'react-router-dom';
import removePonctuation from '../../utilities/removePonctuation';
import AuthLayout from '../../layouts/AuthLayout';
import { setSearchParam } from '../../utilities/setSearchParam';

const CreateResetCode = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { success, authResult } = useSelector((state) => state.auth);
  const recaptchaRef = useRef();
  const [extraErrors, setExtraErrors] = useState();
  const [formErrors, setFormErrors] = useState();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const location = useLocation();
  const previousUrl = document.referrer;

  useEffect(() => {
    if (location.state) {
      window.history.href = `${previousUrl}?${location.state}`;
    }
  }, [location.state]);

  useEffect(() => {
    const nextPage = sessionStorage.getItem('nextPage');

    if (nextPage) {
      setSearchParam('nextPage', 'offres');
    }
  }, []);

  useEffect(() => {
    if (extraErrors || success) {
      setOpenSnackBar(true);
    }
  }, [extraErrors, success]);

  const initialValues = {
    credential: '',
  };

  const validationSchema = Yup.object({
    credential: Yup.string('Veuillez saisir votre adresse e-mail/numéro de téléphone')
      // .email("Enter a valid email")
      .required('Veuillez saisir votre adresse e-mail/numéro de téléphone')
      .test(
        'test-name',
        'Veuillez saisir un numéro de téléphone/une adresse e-mail valide',
        function (value) {
          const emailRegex = /.+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

          const phoneRegex = /^((\+|00)216)?([2459][0-9]{7})+$/;
          let isValidEmail = emailRegex.test(value);
          let isValidPhone = phoneRegex.test(value);
          if (!isValidEmail && !isValidPhone) {
            return false;
          }
          return true;
        }
      ),
  });

  const onSubmit = (values, submitProps) => {
    try {
      setIsSubmitting(true);

      setExtraErrors('');

      recaptchaRef.current
        .execute()

        .then((res) => {
          dispatch(
            createResetPasswordCode({
              'credential': values.credential,
              'g-recaptcha-response': res,
              link: location.state,
            })
          )
            .then((res) => {
              setIsSubmitting(false);

              if (res?.payload?.includes('Server')) {
                setExtraErrors("Une erreur s'est produite, veuillez réessayer ultérieurement");
              } else {
                submitProps.setErrors({ credential: removePonctuation(res?.payload) });
              }
            })
            .catch((error) => setExtraErrors(removePonctuation(error)));
        })
        .catch((error) => setExtraErrors(removePonctuation(error)));

      submitProps.setStatus({ success: true });
      submitProps.setSubmitting(false);
    } catch (err) {
      if (!err.message) {
        setExtraErrors('error');
      } else {
        setExtraErrors(removePonctuation(err.message));
      }

      submitProps.setStatus({ success: false });
      submitProps.setSubmitting(false);
    }
  };

  return (
    <AuthLayout className="confirm-code-page">
      <div className="reset-page-content confirm-code-content">
        <h1 className="content-title confirm-password-content">
          {t('Réinitialiser le mot de passe')}
        </h1>
        <div className="auth-form small-form">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              setFormErrors(formik.errors);
              return (
                <Form className="">
                  <Box
                    rowGap={3}
                    columnGap={2}
                    display="grid"
                    gridTemplateColumns={{
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(1, 1fr)',
                    }}
                  >
                    <Input
                      type="text"
                      className="reset-code-input"
                      label={t(
                        'Veuillez entrer votre email ou votre numéro de téléphone associé à votre compte'
                      )}
                      name="credential"
                      placeholder={t('Email ou numéro de téléphone')}
                      required
                      error={
                        formik.errors.credential && formik.touched.credential
                          ? t(formErrors.credential)
                          : null
                      }
                    />
                  </Box>

                  <button
                    type="submit"
                    className={
                      isSubmitting || !formik.isValid ? 'auth-btn disabled-auth-btn' : 'auth-btn'
                    }
                    disabled={isSubmitting || !formik.isValid}
                  >
                    {isSubmitting ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      t('Réinitialiser le mot de passe')
                    )}
                  </button>

                  <Link to={'/login'} className="auth-btn cancel-btn">
                    {t('Annuler')}
                  </Link>
                </Form>
              );
            }}
          </Formik>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            size="invisible"
          />
          <Snackbar
            open={openSnackBar}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            onClose={() => setOpenSnackBar(false)}
          >
            <Alert
              severity={success === false ? 'error' : 'success'}
              onClose={() => setOpenSnackBar(false)}
            >
              {authResult ? t(removePonctuation(authResult)) : t(removePonctuation(extraErrors))}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </AuthLayout>
  );
};

export default CreateResetCode;
