import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const ExamButton = (props) => {
  const { t } = useTranslation();
  const { text, width, float, height, fontSize } = props;
  return (
    <div className="examButton" style={{ width, float, height }}>
      <Typography className="examButtonText" style={{ fontSize }}>
        {text}
      </Typography>
    </div>
  );
};
ExamButton.propTypes = {
  text: PropTypes.string,
  width: PropTypes.any,
  float: PropTypes.any,
  height: PropTypes.any,
  fontSize: PropTypes.string,
};

export default ExamButton;
