import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const Index = ({ icon, label, className, onClickAction, disabled }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      className={`variant-btn${className ? ' ' + className : ''}`}
      startIcon={icon || null}
      onClick={onClickAction || null}
      disabled={disabled || false}
    >
      {label && label}
    </Button>
  );
};

Index.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  onClickAction: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Index;
