import React from 'react';
import PropTypes from 'prop-types';
// import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import constants from '../../../utilities/constants';
import { useSelector } from 'react-redux';
import { ContainerItems } from './ContainerItems';

const list = constants.SIDEBAR_LIST;

const Container = ({ handleDrawerToggle, mobileOpen }) => {
  const { i18n, t } = useTranslation();
  const { permissions } = useSelector((state) => state.user);
  const { informations } = useSelector((state) => state.user);
  const userPermissions = [];

  if (permissions) {
    if (permissions.forum === true) {
      userPermissions.push('HAS_FORUM');
    }
    if (permissions.exam === true) {
      userPermissions.push('HAS_EXAM');
    }
    if (permissions.influencer === true) {
      userPermissions.push('IS_INFLUENCER');
    }
    if (permissions.eligibleUserForGift === true) {
      userPermissions.push('IS_ELIGIBLE_FOR_GIFT');
    }
  }

  const isAllowed = (permissions, item) => {
    if (!item.permission) {
      return true;
    } else {
      return permissions.includes(item.permission_key);
    }
  };

  const updatedList = list.map((item) =>
    item.name === 'Parrain'
      ? {
          ...item,
          url: informations?.gift_code ? `/gift-code` : `/generate-gift-code`,
        }
      : item
  );
  return (
    <div className={i18n.language === 'ar' ? 'container rtl' : 'container'}>
      <ul>
        {updatedList &&
          updatedList.length > 0 &&
          updatedList.map((item, index) => {
            if (isAllowed(userPermissions, item)) {
              let opts = null;
              if (item.id === 8) {
                opts = {
                  ...opts,
                  label: `${(informations && informations.points) || '0'}${t('points')}`,
                  badge: false,
                };
              }

              return (
                <ContainerItems
                  key={index}
                  item={item}
                  options={opts}
                  handleDrawerToggle={handleDrawerToggle}
                  mobileOpen={mobileOpen}
                />
              );
            }
          })}
        {/* {list &&
          list.length > 0 &&
          Object.keys(permissions).length !== 0 &&
          list.map((item, index) => {
            switch (index) {
              case 6:
                if (permissions.forum) {
                  return <ContainerItems key={index} item={item} />;
                }
                break;
              case 4:
                if (permissions.exam) {
                  return <ContainerItems key={index} item={item} />;
                }
                break;
              default:
                return <ContainerItems key={index} item={item} />;
            }
          })} */}
      </ul>
    </div>
  );
};

Container.propTypes = {
  list: PropTypes.array,
};

export default Container;
