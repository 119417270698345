import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  allTransactions,
  getConfirmTransferPoints,
  resendTransferConfirmation,
} from '../../../redux/slices/points';
import { useSelector, useDispatch } from 'react-redux';
import { me } from '../../../redux/slices/user';
import { enqueueSnackbar } from '../../../redux/slices/notifier';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Dialog, DialogContent } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const ConfirmationTransferPoints = ({ id, open, handleClose, data, ...rest }) => {
  const { i18n, t } = useTranslation();
  const { confirmTransfer, confirmTransferError, resendConfirm, transferId } = useSelector(
    (state) => state.points
  );
  const dispatch = useDispatch();
  const [confirmToken, setConfirmToken] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);

  useEffect(() => {
    if (confirmTransfer && confirmTransferError === false) {
      dispatch(me());
    }
  }, [confirmTransfer, confirmTransferError]);
  const handleSubmit = () => {
    if (!confirmToken) {
      dispatch(
        enqueueSnackbar({
          message: t('Remplir les champs'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        })
      );
      return;
    }
    dispatch(getConfirmTransferPoints(confirmToken));
    dispatch(allTransactions());
    setOpenSnackBar(true);
    setConfirmToken('');
  };
  const resendCode = () => {
    dispatch(resendTransferConfirmation(data?.id || transferId));
    setOpenSnackBar(true);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleClose(id);
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ta-modal"
      >
        <DialogTitle id="alert-dialog-title">
          <span className="label"> {t('Confirm Points Transfer')}</span>
          <span className="close-btn" onClick={() => handleClose(id)}>
            {t('Fermer')}
          </span>
        </DialogTitle>
        <DialogContent className="ta-modal-content-scroll">
          {/*   <div className={i18n.language === 'ar' ? 'rtl' : 'basic'}>*/}
          <TextField
            autoFocus
            id="confirmToken"
            placeholder={t('Code de confirmation')}
            type="string"
            value={confirmToken}
            margin="dense"
            variant="outlined"
            className="input"
            fullWidth
            onChange={(e) => setConfirmToken(e.target.value)}
          />
          <div className="ta-btns-group">
            <Box mt={2} className="btn-transfer">
              <Button
                onClick={(event) => handleSubmit(event)}
                className="ta-btn primary btn-rounded btn-xl left"
              >
                {t('transfer')}
              </Button>
            </Box>
            <Box mt={2} className="link-transfer">
              <span className="confirm-transfer-link" onClick={resendCode} to="#">
                {t('Resend Code')}
              </span>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
      {/* {
        (resendConfirm) &&
          <Snackbar
          open={openSnackBar}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'Bottom',
            horizontal: 'center',
          }}
          onClose={() => setOpenSnackBar(false)}
          >
          <Alert
            severity={resendConfirm ? 'success' : 'warning'}
            onClose={() => setOpenSnackBar(false)}
          >
            {t(resendConfirm)}
          </Alert>
          </Snackbar>
      }*/}
    </>
  );
};

export default ConfirmationTransferPoints;
