import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ImThumbnail from '../../assets/img/icons/thumbnail.png';
import { sliceLongStrings } from '../../utilities/methods';
import * as dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const Index = ({ data }) => {
  const { t } = useTranslation();
  dayjs.extend(customParseFormat);
  const {
    chapter,
    subject,
    division,
    teacher,
    createdAt,
    subject_slug,
    chapter_slug,
    content_slug,
    chapter_type,
  } = data;
  return (
    <Link
      className="free-videos-item"
      to={chapter_type!=='recording' ?
        `/subjects/${subject_slug}/chapters/${chapter_slug}/lessons/${content_slug}/content`:
        `/subjects/${subject_slug}/chapters/${chapter_slug}/recordings/${content_slug}/content`
      }
    >
      <div className="thumbnail">
        <span className="title" title={chapter}>
          {sliceLongStrings(chapter, 34)}
        </span>
        <img src={ImThumbnail} alt="thumbnail" />
      </div>
      <div className="textes">
        <div className="subdiv">
        <span className="strong-division">{`${division}`}</span><span>|</span><span className="strong-subject">{`${subject}`}</span>
        </div>
        <span>{t("Par")} {teacher}</span>
        <span>{t("Enregistré le")} {dayjs(createdAt).format('DD-MM-YYYY')}</span>
        
      </div>
    </Link>
  );
};

Index.propTypes = {};

export default Index;
