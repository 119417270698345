import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ViewTitle from '../../components/ViewTitle';
import { VideoCard, VideoModal } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { getVideos } from '../../redux/slices/assistance';
import Skeleton from '@material-ui/lab/Skeleton';
import Modal from '../../components/Modal/Modal';

const Index = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { videos, isAssistantLoading } = useSelector((state) => state.assistance);
  const [src, setSrc] = React.useState('');
  const [open, openModal] = useState(false);

  useEffect(() => {
    dispatch(getVideos());
  }, [dispatch]);

  const openIframe = (v) => {
    openModal(true);
    const video = { ...v };
    const youtubeId = video.link.split('/').pop();
    setSrc(
      `https://www.youtube.com/embed/${youtubeId}?version=3&loop=1&playlist=${youtubeId}&rel=0?modestbranding=1&playsinline=1&iv_load_policy=3`
    );
  };
  const onCloseModal = () => {
    openModal(false);
    setSrc('');
  };

  return (
    <div>
      <ViewTitle title={t('Assistance')} />
      <div className="ta-row">
        {isAssistantLoading !== true ? (
          <div className="ta-column assistance-right-margin">
            {videos.length ? (
              videos.map((video) => {
                return (
                  <>
                    <div className="assistance" onClick={() => openIframe(video)}>
                      <VideoCard video={video} />
                    </div>
                  </>
                );
              })
            ) : (
              <div className="col">
                <div className="card m-4 Small shadow border-0 rounded-0">
                  <div className="card-body">
                    <p className="card-text text-center">{t('pas de videos')}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="ta-column assistance-right-margin">
            <div className="assistance">
              {videos &&
                videos.map((video) => <Skeleton variant="rect" width={320} height={250} />)}
            </div>
          </div>
        )}
      </div>
      <Modal
        onCloseModal={onCloseModal}
        component={VideoModal}
        data={src}
        openModal={open}
        modalWidth={'sm'}
        className="youtube-video-modal"
      />
    </div>
  );
};

export default Index;
