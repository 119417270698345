import React, { useState  } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import { Slide } from "@material-ui/core";
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { CardTitle, VariantButton, Dialog } from '../../components';
import imSecurity from '../../assets/img/icons/secure-data.svg';
import { useDispatch, useSelector } from 'react-redux';
import { editPassword } from '../../redux/slices/user';
import { enqueueSnackbar } from '../../redux/slices/notifier';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';


  const Index = ({isTest, admin}) => {
  const { t } = useTranslation();
  const [oldPassword, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [new_password_confirmation, setNewPasswordConfirmation] = useState('');

  const dispatch = useDispatch();

  
  const updateValidation = (oldPassword, newPassword, new_password_confirmation) => {
    if (oldPassword === '' || newPassword === '' || new_password_confirmation === '') {
      return { success: false, 'message': t('Remplir les champs') };
    }
    if (newPassword && !(/^(?=.*\d)(?=.*[A-Z])(?=.*[:=+*-_()&?.!;,@#$%])(?!.*(.)\1{2}).*[a-z]/m).test(newPassword)){
      return { success: false, 'message': t('The password should contain at least a capital letter, a small letter, a number and a special character')};
    }
    if (newPassword && !(/^(?=.*\d)(?=.*[A-Z])(?=.*[:=+*-_()&?.!;,@#$%€¥])(?!.*(.)\1{5}).*[a-z]/m).test(newPassword)){
      return { success: false, 'message': t('The password should contain at least a capital letter a small letter a number and a special character')};
    }
    if (newPassword.length > 16 || oldPassword.length > 16){
      return { success: false, 'message': t('The password value is too long It should have 16 characters or less') };
    }
    if (newPassword !== new_password_confirmation){
      return { success: false, 'message': t('Les nouveaux mot de passes ne sont pas identiques')};
    }
  }

  const handlePasswordSubmit = () => {
  const validation = updateValidation(oldPassword, newPassword, new_password_confirmation);
    if (validation) {
      dispatch(
        enqueueSnackbar({
          message: validation.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "warning",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
  else{
    const req = {
      oldPassword,
      newPassword,
      new_password_confirmation,
    };
    dispatch(editPassword(req));
    setPassword('');
    setNewPassword('');
    setNewPasswordConfirmation('');
    handleClose();
  }
  };

  

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card className="ta-card security-widget">
        <CardHeader title={<CardTitle title={t("securite et authentification")} />} />
        <CardContent>
          <div className="child-full">
            <img src={imSecurity} alt="security" />
            {/* <VariantButton label="Modifier votre Email" className="blue" /> */}
            <VariantButton
              label={t("Modifier le mot de passe")}
              className="red-outlined mt"
              onClickAction={handleClickOpen}
              disabled={isTest && !admin ? true : false}
            />
          </div>
        </CardContent>
      </Card>
      <Dialog
        open={open}
        handleClose={handleClose}
        title={t('Changer le mot de passe')}
        component={''}
        onSubmitAction={handlePasswordSubmit}
      >
        <div className="ta-form">
          <FormControl variant="outlined">
            <InputLabel htmlFor="password" className="fix-display ">
              {t("Mot de passe Actuel")}
            </InputLabel>
            <TextField
              id="oldPassword"
              type="password"
              autoComplete="current-password"
              variant="outlined"
              size="small"
              value={oldPassword}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel htmlFor="new_password" className="fix-display">
              {t("Nouveau Mot de passe")}
            </InputLabel>
            <TextField
              id="newPassword"
              type="password"
              autoComplete="current-password"
              variant="outlined"
              size="small"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel htmlFor="new_password_confirmation" className="fix-display">
              {t("Confirmez le mot de passe")}
            </InputLabel>
            <TextField
              id="new_password_confirmation"
              type="password"
              autoComplete="current-password"
              variant="outlined"
              size="small"
              value={new_password_confirmation}
              onChange={(e) => setNewPasswordConfirmation(e.target.value)}
            />
          </FormControl>
        </div>
      </Dialog>
    </>
  );
};

Index.propTypes = {
  handlePasswordSubmit: PropTypes.func,
  handleClickOpen: PropTypes.func,
  handleClose: PropTypes.func,
  setPassword: PropTypes.func,
  setNewPassword: PropTypes.func,
  setNewPasswordConfirmation: PropTypes.func,
  oldPassword: PropTypes.string,
  newPassword: PropTypes.string,
  new_password_confirmation: PropTypes.string,
  req: PropTypes.object,
  dispatch: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default Index;
