import React from 'react';
import './_index.scss';
import coinsImg from '../../assets/img/icons/coins.svg';
import { useTranslation } from 'react-i18next';
import formatAmount from '../../utilities/formatAmount';

const CoinsBalance = ({ points, giftCode, inputValue }) => {
  const { t } = useTranslation();
  return (
    <div
      className={inputValue ? 'search-show coins-balance' : 'wallet-widget coins-balance'}
      onClick={(e) => (window.location = giftCode ? '/gift-code' : '/generate-gift-code')}
    >
      <img alt="coins-icon" src={coinsImg} />
      <div>
        <span className="title">{t('Your vip balance')}</span>

        <span className="amount">{formatAmount(points, t)}</span>
      </div>
    </div>
  );
};

export default CoinsBalance;
