import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { switchUser } from '../../redux/slices/auth';
import Skeleton from '@material-ui/lab/Skeleton';

const SwitchUser = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(switchUser());
  }, [dispatch, isLoading]);

  if (isLoading) {
    return (
      <>
        <div className="full-page">
          <div className="ta-row">
            <br />
            <Skeleton variant="rect" width={'80%'} height={150} />
            <br />
            <Skeleton variant="rect" width={'50%'} height={120} />
            <Skeleton animation={false} height={150} width={'50%'} />
            <Skeleton variant="rect" width={'50%'} height={150} />
          </div>
        </div>
      </>
    );
  }
};

export default SwitchUser;
