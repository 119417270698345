import React from 'react';
import './_index.scss';

const OptionCard = ({ option }) => {
  return (
    <div className="option-card">
      <img src={option.icon} alt={option.title} />
      <h5>{option.title}</h5>
    </div>
  );
};

export default OptionCard;
