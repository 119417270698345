import { Box, Skeleton } from '@mui/material';
import React from 'react';
import './_skeletonCard.scss';
import './_index.scss';

const SkeletonCard = () => {
  return (
    <div className="offer-card-wrapper skeleton-card-wrapper">
      <Skeleton variant="circular" className="skeleton-card-icon" />
      <Box className="skeleton-card-description">
        <Skeleton />
        <Skeleton />
      </Box>
      <Box className="skeleton-card-box">
        <Skeleton variant="rounded" />
        <Skeleton variant="rounded" />
      </Box>
      <Box className="skeleton-card-details">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Skeleton variant="rounded" className="skeleton-card-button" />
    </div>
  );
};

export default SkeletonCard;
