import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import InputLabel from "@material-ui/core/InputLabel";
import  {formatMessages}  from '../../../utilities/translateHelper';
import {Dialog} from "../../index";
import FormControl from "@material-ui/core/FormControl";
import { addEmail } from '../../../redux/slices/sessions';


const EmailModal = ({ id, open, handleClose, data, ...rest  }) => {
  const { i18n,t } = useTranslation();
  const { codeLoading, emailError, isEmailError } = useSelector((state) => state.sessions);
  const dispatch = useDispatch();
  const [mail, setMail] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [message, setMessage] = useState();
  const [severity, setSeverity] = useState();
  const [validation, setValidaion] = useState(emailError);

  const mailValidation = (mail) =>  {
    if (!mail || /\s/g.test(mail) || !isNaN(mail)) {
      return { success: false, 'message': t('Please enter a valid email') };
    }
  }
  const handleSubmit = () => {
    const validation = mailValidation(mail);
    if (validation) {
      setMessage(validation.message);
      setSeverity('warning');
      setValidaion(true);
      setOpenSnackBar(true);
    }else{
      dispatch(addEmail(mail));
      setOpenSnackBar(true);
      setValidaion(false);
      setMessage('');
    }
  };
  return (
      <>
      <Dialog
          open={open}
          handleClose={(e, reason) => {
              if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                  handleClose(id);
              }
          }}
          title={t('s il vous plaît entrez votre email')}
          component={''}
          onSubmitAction={(event) => handleSubmit(event)}
          onSubmitDisabled={codeLoading}
      >
          <div className="ta-form">
                  <FormControl variant="outlined">
                      <InputLabel htmlFor="email" className="fix-display">
                          {t("Email")}
                      </InputLabel>
                      <TextField
                          id="email"
                          type="text"
                          variant="outlined"
                          size="small"
                          value={mail}
                          onChange={(e) => setMail(e.target.value)}
                      />
                  </FormControl>
          </div>
      </Dialog>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
       {i18n.language === 'ar' ? (
        <Alert
          severity={!validation && !isEmailError ? 'success' : 'warning'}
          onClose={() => setOpenSnackBar(false)}
        >
          {!validation && !isEmailError ? `${mail} ${t("Veuillez entrer le code reçu à")}` : validation ? message  : isEmailError ? t(formatMessages(emailError)) : null }      
        </Alert>
      ):(
        <Alert
          severity={!validation && !isEmailError ? 'success' : 'warning'}
          onClose={() => setOpenSnackBar(false)}
        >
          {!validation && !isEmailError ? `${t("Veuillez entrer le code reçu à")} ${mail}` : validation ? message  : isEmailError ? t(formatMessages(emailError)) : null }      
        </Alert>
      )}
      </Snackbar>
    </>
  );
};

export default EmailModal;