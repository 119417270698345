import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import arabicLangFlag from '../../../../assets/img/icons/arabic.svg';
import frenchLangFlag from '../../../../assets/img/icons/french.svg';
import englishLangFlag from '../../../../assets/img/icons/english.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './_index.scss';

const Language = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState('Français');
  return (
    <FormControl size="small" className="auth-page-languages-component">
      <Select
        disableUnderline
        className="auth-page-languages"
        labelId="language-label"
        IconComponent={KeyboardArrowDownIcon}
        value={
          i18n.language === 'fr'
            ? 'Français'
            : i18n.language === 'ar'
            ? 'العربية'
            : i18n.language === 'en-US'
            ? 'English'
            : language
        }
        onChange={(e) => setLanguage(e.target.value)}
      >
        <MenuItem
          disableGutters={true}
          value="العربية"
          className="auth-page-language"
          onClick={() => i18n.changeLanguage('ar')}
        >
          <span className="text">عربية </span>
          <img className="flag" alt="ar" src={arabicLangFlag} />
        </MenuItem>
        <MenuItem
          disableGutters={true}
          value="Français"
          className="auth-page-language"
          onClick={() => i18n.changeLanguage('fr')}
        >
          <span className="text">Français </span>
          <img className="flag" alt="fr" src={frenchLangFlag} />
        </MenuItem>
        <MenuItem
          disableGutters={true}
          value="English"
          className="auth-page-language"
          onClick={() => i18n.changeLanguage('en-US')}
        >
          <span className="text">English </span>
          <img className="flag" alt="en" src={englishLangFlag} />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default Language;
