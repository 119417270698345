import React from 'react';
import { useTranslation } from 'react-i18next';
import ViewTitle from '../../components/ViewTitle';
import { BookmarkList, ProfileWidget } from '../../components';

const Index = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ViewTitle title={t('Liste de favoris')} />
      <div className="ta-row wallet">
        <div className="ta-column right-margin">
          <div className="child-full">
            <BookmarkList />
          </div>
        </div>
        <div className="ta-column fixed right">
          <ProfileWidget mini />
        </div>
      </div>
    </div>
  );
};

export default Index;
