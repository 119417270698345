import React, {useEffect, useState} from 'react';
import {
  ButtonGroupComponent,
  ControlButton,
  VideoContainer,
  VideoInformation,
  VideoPath,
  VideoTitle,
} from '../..';
import Skeleton from '@material-ui/lab/Skeleton';
import {useDispatch, useSelector} from 'react-redux';
import {content} from '../../../redux/slices/courses';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import getDuration from '../../../utilities/getLessonsDuration';
import {getNewChapterContentsProgress} from "../../../redux/slices/newProgress";


const Index = (props) => {
  const dispatch = useDispatch();
  const {
    courses,
    isLoading,
    subjectSlug,
    chapterSlug,
    lessonSlug,
    lessonId,
  } = props;
  const lessons = courses && courses.lessons;
  const {currentLesson, courseError} = useSelector((state) => state.courses);
  const { newcourseProgress } = useSelector((state) => state.newProgress);
  const firstLesson = (courses.lessons || []).find(lesson => lesson.content.slug === lessonSlug);
  const coursesNumber =courses &&(courses.lessons || []).filter( (lesson) => lesson?.content?.type?.id === 2 || lesson?.content?.type?.id === 1).length;
  const magNumber =courses &&(courses.lessons || []).filter((lesson) => lesson?.content?.type?.id === 3).length;
  const chapterId = chapterSlug.split('-')[0];
  const [update, setUpdate] = useState(false);
  const duration = getDuration(lessons);

  useEffect(() => {
      dispatch(content(subjectSlug, chapterSlug, lessonSlug));
      setUpdate(false);
  }, [lessonSlug, update]);

  useEffect(() => {
    if(chapterId ){
      dispatch(getNewChapterContentsProgress(chapterId));
    }
  }, [dispatch]);

  return (
      <div className="video-view">
        {isLoading !== true ? (
            <div>
              <Grid className="container1" wrap="nowrap" spacing={2}>
                <Grid item className="div1">
                  <VideoTitle lesson={currentLesson}/>
                 
                  <VideoPath
                      subject={courses.subject}
                      lesson={courses.chapter}
                      subjectSlug={subjectSlug}
                  />
                  <VideoContainer
                      currentLesson={currentLesson}
                      courseError={courseError}
                      firstLesson={firstLesson}
                      chapterId={chapterId}
                      chapter={courses.chapter}
                      lessons={lessons}
                      subjectSlug={subjectSlug}
                      chapterSlug={chapterSlug}
                      lessonSlug={lessonSlug}
                      update={setUpdate}
                      newcourseProgress={newcourseProgress}
                  />
                  <ControlButton
                      data={currentLesson}
                      chapter={courses?.chapter}
                      lessons={lessons}
                      subjectSlug={subjectSlug}
                      chapterSlug={chapterSlug}
                      lessonSlug={lessonSlug}
                      update={setUpdate}
                  />
                </Grid>
                <Grid item className="div2">
                  <ButtonGroupComponent
                      firstLesson={firstLesson}
                      courseTabs={courses.tabs}
                      courses={courses}
                      subjectSlug={subjectSlug}
                      chapterSlug={chapterSlug}
                      lessonSlug={lessonSlug}
                      recordingTabs={courses?.recordingTabs}
                      newcourseProgress={newcourseProgress}
                  />
                </Grid>
              </Grid>
              <div>
                <VideoInformation
                    lesson={currentLesson}
                    coursesNumber={coursesNumber}
                    magNumber={magNumber}
                    duration={duration}
                />
              </div>
            </div>
        ) : (
            <div>
              <Skeleton variant="rect" width={'100%'} height={500}/>
            </div>
        )}
      </div>
  );
};

Index.propTypes = {
  courses: PropTypes.object,
  subjectSlug: PropTypes.any,
  chapterSlug: PropTypes.any,
  lessonSlug: PropTypes.any,
  lessonId: PropTypes.any,
  isLoading: PropTypes.bool,
};

export default Index;
