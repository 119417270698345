import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { CardTitle, ResponsiveTable } from '..';
import { editDivisionHistory } from '../../redux/slices/userHistory';
import { useDispatch, useSelector } from 'react-redux';

const Index = () => {
  const { t } = useTranslation();
  const { editDivisionList } = useSelector((state) => state.userHistory);
  const dispatch = useDispatch();
  const headers = [
    { accessor: 'classe actuelle', label: t('Classe actuelle') },
    { accessor: 'classe demandée', label: t('Classe demandée') },
    { accessor: 'raison', label: t('Raison') },
    { accessor: 'autre raison', label: t('Autre raison')},
    { accessor: 'statut', label: t('statut') },
  ];
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if(!editDivisionList?.length){
      dispatch(editDivisionHistory());
    }
  }, []);

  React.useEffect(() => {
    if (editDivisionList) {
      setData(editDivisionList || []);
    }
  }, [editDivisionList]);

  return (
    <Card className="ta-card">
      <CardHeader title={<CardTitle title={t("historique des demandes de changement de classe")} />} />
      <CardContent>
        <div className="child-full">
          <ResponsiveTable cols={headers} rows={data} />
        </div>
      </CardContent>
    </Card>
  );
};

export default Index;
