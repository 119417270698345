import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit';
import api from '../../services/api';

import { enqueueSnackbar } from './notifier';
import i18n from '../../services/i18n';

const initialState = {
  status: 'idle',
  parentRequests: [],
};

export const getAllParentRequests = createAsyncThunk(
  'parentRequests/getAllParentRequests',
  async () => {
    let data;
    try {
      const response = await api.get(`/request-parent-user-children`);
      data = await response.data;

      if (response.status === 200) {
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const UpdateParentRequests = createAsyncThunk(
  'parentRequests/UpdateParentRequests',
  async (details, thunkAPI) => {
    const { id, approved } = details;

    let data;
    try {
      const response = await api.put(`/request-parent-user-children/${id}`, { approved });
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(getAllParentRequests());
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: i18n.t(data?.message),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            },
          })
        );
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: i18n.t(err?.response?.data?.message),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        })
      );

      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: 'parentRequests',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getAllParentRequests.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllParentRequests.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.parentRequests = action.payload;
      })
      .addCase(getAllParentRequests.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(UpdateParentRequests.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(UpdateParentRequests.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(UpdateParentRequests.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});
// Reducer
export default slice.reducer;
