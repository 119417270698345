import React, { useEffect, useState } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { deleteDiscountCodes } from '../../redux/slices/points';
import { useDispatch } from 'react-redux';
import { deleteGiftCode } from '../../redux/slices/parrain';

const CouponItem = (props) => {
  const { id, couponCode, offerId, check, parrain, ...rest } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteDiscountCodes(id));
  };
  const handleDeleteGiftCode = () => {
    dispatch(deleteGiftCode(id));
  };
  return (
    <div>
      <div>
        <IconButton onClick={parrain ? handleDeleteGiftCode : handleDelete}>
          <ClearIcon style={{ verticalAlign: 'middle' }} />{' '}
        </IconButton>
        {i18n.language === 'ar' ? (
          <div>
            {t('est appliqué')}{' '}
            <span style={{ fontWeight: 'bold' }}>{parrain ? couponCode : couponCode.code}</span>{' '}
            {parrain ? t('Le code parrain') : t('Le code')}
          </div>
        ) : (
          <div>
            {parrain ? t('Le code parrain') : t('Le code')}{' '}
            <span style={{ fontWeight: 'bold' }}>{parrain ? couponCode : couponCode.code}</span>{' '}
            {t('est appliqué')}
          </div>
        )}
      </div>
    </div>
  );
};

export default CouponItem;
