import { useTranslation } from 'react-i18next';
import React, {useState } from 'react';
import PropTypes from 'prop-types';
import {makeStyles,} from '@material-ui/core';
import { Dialog, DialogContent } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PaimentOoreedoo from './PaimentOoreedoo';
import Im_success from '../../../assets/img/icons/success.svg';
import Im_echec from '../../../assets/img/icons/echec.svg';




const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: '10px',
    color: 'white',
    backgroundColor: '#3570a1',
    fontWeight: 'bold',
    fontSize: '16px',
    marginTop: '10px',
    minHeight:'40px'
  },
}));

const Index = (props) => {
  const { i18n, t } = useTranslation();
  const {
    open,
    setOpen,
    success
  } = props;
  const [showMessage, setShow] = useState(false);
  const [openPaiment, setOpenPaiment] = useState(false);
  const [paimentData, setPaimentData] = useState({ recurrence: '', offerDivisionDetail: '' });
  const [ooreedooPrice, setOoreedooPrice] = useState();
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    setShow(false);
    setOpenPaiment(false);
    setPaimentData({ recurrence: '', offerDivisionDetail: '' });
    setOoreedooPrice();
  };
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      titleStyle={{ textAlign: 'center' }}
      aria-describedby="alert-dialog-description"
      className="couponModal"
      fullWidth={true}
      maxWidth="lg"
    >
      <div className="modal-close-coupon" onClick={handleClose}>
        <IconButton onClick={handleClose}>
          <CloseIcon className="close-coupon" />
        </IconButton>
      </div>
      <DialogContent className={'content'}>
     <PaimentOoreedoo image={success?Im_success:Im_echec} 
     text={success?'Votre abonnement silver  a été effectué avec succès':'La transaction n’a pas pu aboutir, veuillez réessayer plus tard'}
     messageValidation={success? "Abonnement activé" : "Une erreur est survenue"}
     color={'#2ba7df'}
     buttonText={'ok'}
     buttonWidth={'160px'}
     onClick={handleClose}
     /> 
      </DialogContent>
    </Dialog>
  );
};

Index.propTypes = {
  onCloseModal: PropTypes.func,
};

export default Index;
