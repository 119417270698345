import React from 'react';
import PropTypes from 'prop-types';
import LockIcon from '@material-ui/icons/Lock';
import WindowDimensions from '../../utilities/windowDimenssions';

InputCard.propTypes = {};

function InputCard({ value, handleFunction, typeInput, placeholder, icon, name }) {
  const { width } = WindowDimensions();
  return (
    <div className="input-card" style={{width:width<750&&'100%'}}>
      {icon}
      <input
        className="input"
        name={name}
        type={typeInput}
        value={value}
        placeholder={placeholder}
        onChange={handleFunction}
      />
    </div>
  );
}

export default InputCard;
