import React from 'react';
import takiacademyLogo from '../../../../assets/img/icons/LOGO.svg';
import './_index.scss';

const TakiacademyBrand = () => {
  const homeURL = process.env.REACT_APP_LANDING_URL;

  return (
    <div className="takiacademy-logo" onClick={() => (window.location.href = homeURL)}>
      <img src={takiacademyLogo} />
      <div className="takiacademy-logo-details">
        <div className="takiacademy-title">
          <span>Taki</span> Academy
        </div>
        <div className="takiacademy-website">
          www
          <span>
            <span>.taki</span>academy<span>.</span>
          </span>
          com
        </div>
      </div>
    </div>
  );
};

export default TakiacademyBrand;
