import { ErrorMessage, Field, useField } from 'formik';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import TextError from '../TextError';
import React, { useEffect, useRef, useState } from 'react';
import './_index.scss';
import Calendar from '../../Calendar';
import { useTranslation } from 'react-i18next';

const DatePicker = ({
  label,
  name,
  required,
  className,
  placeHolder,
  errorMessage,
  calendarClassName,
  calendarHeader,
  error,
  open,
  setOpen,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const { i18n } = useTranslation();
  const language = i18n.language;
  const datePickerRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!datePickerRef?.current?.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [datePickerRef]);
  return (
    <div className="date-picker-wrapper" ref={datePickerRef}>
      {open && (
        <Calendar
          setOpen={setOpen}
          helpers={helpers}
          field={field}
          calendarHeader={calendarHeader}
          calendarClassName={calendarClassName}
        />
      )}
      <div className={`form-control date-picker ${className}`}>
        {label && (
          <>
            <label htmlFor={name} className={language === 'ar' && 'arabic-label'}>
              {required &&
                (language === 'ar' ? (
                  <>
                    <span className="required-field">*</span> <p>{label}</p>
                  </>
                ) : (
                  <>
                    <p>{label}</p> <span className="required-field">*</span>
                  </>
                ))}
            </label>
          </>
        )}
        <div className="field-wrapper">
          <div className={`field ${error && 'field-error-style'}`}>
            <Field name={name} placeHolder={placeHolder} {...rest} onClick={() => setOpen(!open)} />
          </div>
          {error && !open && error?.trim().length > 0 ? (
            <p className={language === 'ar' ? 'arabic-error  error' : 'error'}>
              {language === 'ar' ? (
                <>
                  {error} <ReportProblemIcon />
                </>
              ) : (
                <>
                  <ReportProblemIcon /> {error}
                </>
              )}
            </p>
          ) : (
            !open && <ErrorMessage component={TextError} name={name} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
