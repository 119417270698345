import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 8,
    minWidth: '58%',
  },
  colorPrimary: {
    backgroundColor: 'rgba(245, 245, 245, 0.6)',
  },
  bar: {
    borderRadius: 5,
    background: '#EAF672',
    opacity: 1,
  },
}))(LinearProgress);

export default BorderLinearProgress;
