import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const Index = ({ textButton }) => {
  return (
    <div className="div-button-add-point">
      <Button className="button-add-point">{textButton}</Button>
    </div>
  );
};

Index.propTypes = {
  textButton: PropTypes.string,
};

export default Index;
