import { createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

// Slice
const slice = createSlice({
  name: 'place',
  initialState: {
    institutes: null,
    countries: null,
    states: null,
    delegations: null,
  },
  reducers: {
    institutesSuccess: (state, action) => {
      state.institutes = action.payload.data.payload;
    },
    countriesSuccess: (state, action) => {
      state.countries = action.payload.data.payload;
    },
    statesSuccess: (state, action) => {
      state.states = action.payload.data.payload;
    },
    delegationsSuccess: (state, action) => {
      state.delegations = action.payload.data.payload;
    },
  },
});
export default slice.reducer;
// Actions
const { institutesSuccess, countriesSuccess, statesSuccess, delegationsSuccess } = slice.actions;

export const getInstitutes = ({ stateId }) => async (dispatch) => {
  try {
    const res = await api.get(`/state/${stateId}/institutes`);
    dispatch(institutesSuccess(res));
  } catch (e) {
    return console.error(e.message);
  }
};

export const getCountries = () => async (dispatch) => {
  try {
    const res = await api.get('/countries');
    dispatch(countriesSuccess(res));
    
  } catch (e) {
    return console.error(e.message);
  }
};


export const getStates = ({ countryId }) => async (dispatch) => {
  try {
    const res = await api.get(`/countries/${countryId}/states`);
    dispatch(statesSuccess(res));
  } catch (e) {
    return console.error(e.message);
  }
};


export const getDelegations = ({ stateId }) => async (dispatch) => {
  try {
    const res = await api.get(`/deleg/${stateId}/delegation`);
    dispatch(delegationsSuccess(res));
  } catch (e) {
    return console.error(e.message);
  }
};
