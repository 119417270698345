import React, { useEffect } from 'react';
import giftImg from '../../../../assets/img/icons/gift-box.svg';
import balanceImg from '../../../../assets/img/icons/balance.svg';
import './_index.scss';
import { openModal } from '../../../../redux/slices/modals';
import { useDispatch, useSelector } from 'react-redux';
import { me } from '../../../../redux/slices/user';
import { useTranslation } from 'react-i18next';
import useSettings from '../../../../hooks/useSettings';
import formatAmount from '../../../../utilities/formatAmount';

const BalanceCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { settings } = useSettings();

  const { informations } = useSelector((state) => state.user);
  const coins = informations?.gift_point || 0;
  return (
    <div className={settings.sidebar === 'full' ? 'balance-card-full-sidebar' : 'balance-card'}>
      <div className="balance-card-header">
        <div className="balance-card-title">
          <div className="balance-card-header-img">
            <img src={balanceImg} alt="balance-img" />
          </div>
          <div className="balance-card-header-title">
            {t('Total balance')} : {formatAmount(coins, t)}
          </div>
        </div>
        <p className="balance-card-description">
          {t(
            //
            "Vous pouvez convertir vos gains en cadeaux ou les utiliser pour souscrire à l'une de nos offres exclusives Profitez de la flexibilité et choisissez la récompense qui vous convient le mieux"
          )}
        </p>
      </div>
      <div className="balance-card-content">
        <div className="convert-coins-btn">
          <div
            className="convert-coins-btn-content"
            onClick={() => dispatch(openModal('convert-coins-modal'))}
          >
            <img src={giftImg} alt="gift-icon" />
            <p>{t('Convert jetons')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceCard;
