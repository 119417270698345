import React,{useState}from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { CardTitle, ResponsiveTable, Empty } from '../../components';
import { getPointsHistory } from '../../redux/slices/points';
import { useDispatch, useSelector } from 'react-redux';
import ImPoints from '../../assets/img/icons/discount.svg';

const Index = () => {
  const { t } = useTranslation();
  const { points_history } = useSelector((state) => state.points);
  const dispatch = useDispatch();
  const headers = [
    { accessor: 'code', label: t('Code') },
    { accessor: 'method', label: t('Mode de paiement') },
    { accessor: 'amount', label: t('Montant en dinar') },
    { accessor: 'description', label: t('Description') },
    { accessor: 'date', label: t('Date'), type: 'date' },
    { accessor: 'status', label: t('Statut') },
  ];
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (!points_history) {
      dispatch(getPointsHistory());
    }
  }, []);

  React.useEffect(() => {
    if (points_history) {
      setData(points_history || []);
    }
  }, [points_history]);

  return (
    <Card className="ta-card">
      <CardHeader title={<CardTitle title={t("historique des points")} />} />
      <CardContent>
        <div className="child-full">
          <ResponsiveTable
            cols={headers}
            rows={data}
            emptyComponent={<Empty label={t("pas de points")} icon={ImPoints} className="no-borders" />}
          />
        </div>
      </CardContent>
    </Card>
  );
};

Index.propTypes = {
  points_history: PropTypes.array,
  data: PropTypes.array,
  setData: PropTypes.func,
  dispatch: PropTypes.func,
  headers: PropTypes.array,
};

export default Index;
