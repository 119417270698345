import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ifvisible from 'ifvisible.js';
import YouTube from 'react-youtube';
import { useDispatch, useSelector } from 'react-redux';
import { getNewChapterContentsProgress, saveNewProgress } from '../../../redux/slices/newProgress';

const FreePlayer = (props) => {
  const { data, chapterId, newcourseProgress } = props;
  const { permissions } = useSelector((state) => state.user);
  const { courses } = useSelector((state) => state.courses);
  const dispatch = useDispatch();

  const removeFromUrl = (url) => {
    return url.substring(url.indexOf('=') + 1, url.length);
  };
  const formatUrl = (youtubeUrl) => {
    const lastIndex = youtubeUrl.lastIndexOf('/');
    const youtUrl = youtubeUrl.substring(lastIndex + 1, youtubeUrl.length);
    if (youtUrl.includes('watch')) {
      return removeFromUrl(youtUrl);
    }
    return youtUrl;
  };
  const urlYoutube = (url) => {
    return url?.includes('youtu') ? formatUrl(url) : url;
  };

  const handleVideoEnd = (event) => {
    event.target.stopVideo();
  };
  const url = data.content_videos[0]?.url;
  const contentId = data && data.id;
  const isCompleted =
    newcourseProgress &&
    newcourseProgress.length > 0 &&
    newcourseProgress.find((p) => p.content_id === contentId);
  //var subjectId and isFree for new progress
  const subjectId = courses && courses.subject.id;
  const isFree = permissions?.freeHours == null ? false : true;
  let viewToken = null;
  let player = null;
  let timer = null;
  let ytTotalPlayed = 0;
  let oldYtTotalPlayed = 0;
  let timerCycle = 1000;
  let contentCompleted = 0;
  let playerSpeed = null;
  const options = {
    height: '500px',
    width: '100%',
    playerVars: {
      rel: 0,
      showinfo: 0,
      ecver: 2,
    },
  };
  const onReady = (event) => {
    event.target.pauseVideo();
    player = event.target;
    ytTotalPlayed = 0;
    oldYtTotalPlayed = 0;
    timerCycle = 1000;
    contentCompleted = 0;

    event.target.addEventListener('onStateChange', (e) => {
      playerSpeed = player.getPlaybackRate();
      switch (e.data) {
        case 1:
          timer = setInterval(recordYtTime, timerCycle);
          break;
        case 2:
          clearInterval(timer);
          timerCycle = 1000 / playerSpeed;
          timerCycle = Math.round(
            timerCycle - ((player.getCurrentTime() % 1) * 1000) / playerSpeed
          );
          break;
        default:
          clearInterval(timer);
          break;
      }
    });
    event.target.addEventListener('onPlaybackRateChange', (e) => {
      playerSpeed = player.getPlaybackRate();
      timerCycle = 1000 / playerSpeed;
      timerCycle = Math.round(timerCycle - ((player.getCurrentTime() % 1) * 1000) / playerSpeed);

      switch (event.target.getPlayerState()) {
        case 1:
          clearInterval(timer);
          timer = setInterval(recordYtTime, timerCycle);
          break;
        default:
          break;
      }
    });
    ifvisible.on('blur', () => {
      if (event.target.getPlayerState() === 1) {
        event.target.pauseVideo();
      }
    });
    ifvisible.on('wakeup', () => {
      if (event.target.getPlayerState() === 2) {
        event.target.playVideo();
      }
    });
  };

  const recordYtTime = () => {
    try {
      let time = 50;
      ytTotalPlayed++;
      if (timerCycle !== 1000 / playerSpeed) {
        clearInterval(timer);
        timerCycle = 1000 / playerSpeed;
        timer = setInterval(recordYtTime, timerCycle);
      }
      if (ytTotalPlayed >= oldYtTotalPlayed + time && !isCompleted) {
        oldYtTotalPlayed += time;
        if ((ytTotalPlayed * 100) / player.getDuration() >= 80) {
          contentCompleted = 1;
        }
        dispatch(
          saveNewProgress({
            viewToken: Math.random().toString(36).substring(2) + Date.now().toString(36),
            contentId: contentId,
            chapterId: parseInt(chapterId),
            completed: contentCompleted == 0 ? false : true,
            totalWatched: ytTotalPlayed,
            watchedTime: time,
            isFree: isFree,
            subjectId: subjectId,
          })
        );
        setTimeout(() => {
          dispatch(getNewChapterContentsProgress(chapterId));
        }, 2000);
      }
    } catch (error) {}
  };

  return (
    <div>
      {data && (
        <div className="embed-responsive embed-responsive-16by9 rounded" style={{ height: 500 }}>
          <div id="embedBox" className="embed-responsive-item">
            <YouTube
              videoId={urlYoutube(url)}
              opts={options}
              onEnd={handleVideoEnd}
              onReady={(e) => onReady(e)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

FreePlayer.propTypes = {
  data: PropTypes.object,
};

export default FreePlayer;
