import { Box, Card, CardContent, Slide, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './_index.scss';
import PageHeader from '../components/PageHeader';
import { useLocation } from 'react-router-dom';
import { addGiftRequestItem } from '../../../redux/slices/parrain';
import { useDispatch, useSelector } from 'react-redux';
import PreviousButton from '../components/PreviousButton';
import { enqueueSnackbar } from '../../../redux/slices/notifier';
import useSettings from '../../../hooks/useSettings';
import formatAmount from '../../../utilities/formatAmount';
import NotFound from '../../NotFound';

const ProductDetails = () => {
  const { t, i18n } = useTranslation();
  const { informations, permissions } = useSelector((state) => state.user);
  const { addGiftRequestItemStatus } = useSelector((state) => state.parrain);
  const dispatch = useDispatch();
  const [selectedImg, setSelectedImg] = useState();
  const { language } = i18n;
  const location = useLocation();
  const gift = location.state;
  const { settings } = useSettings();
  useEffect(() => {
    if (
      permissions &&
      permissions?.eligibleUserForGift === true &&
      informations &&
      !informations.gift_code
    ) {
      window.location.href = '/generate-gift-code';
    }
  }, [informations, permissions]);
  return permissions?.eligibleUserForGift === true ? (
    <div
      className={
        settings.sidebar === 'full'
          ? language === 'ar'
            ? 'prrain-product-details-page-full-sidebar parrain-page-arabic'
            : 'prrain-product-details-page-full-sidebar'
          : 'prrain-product-details-page'
      }
    >
      <PageHeader
        paths={[
          {
            label: 'Parrain',
            link: informations?.gift_code ? `/gift-code` : `/generate-gift-code`,
          },
          {
            label: 'Product',
            link: `/parrain/products`,
          },
          { label: gift?.name },
        ]}
      />
      <PreviousButton />
      <Card className="ta-card prrain-product-details">
        <CardContent>
          <Box className="prrain-product-details-images">
            <Box className="prrain-product-details-selected-image">
              <img
                src={
                  selectedImg ||
                  gift?.gift_images?.find((el) => el.is_cover)?.file_url ||
                  gift?.gift_images[0].file_url
                }
              />
            </Box>
            <Box className="prrain-product-details-images-list">
              {gift?.gift_images?.map((el, index) => (
                <div
                  className="prrain-product-details-product-image"
                  key={index}
                  onClick={() => setSelectedImg(el?.file_url)}
                >
                  <img src={el?.file_url} />
                </div>
              ))}
            </Box>
          </Box>
          <Box className="prrain-product-details-text">
            <Typography className="product-name">{gift?.name}</Typography>
            {gift?.description && (
              <Typography className="product-description">{gift?.description}</Typography>
            )}
            <Typography className="product-amount">{formatAmount(gift?.amount, t)} </Typography>
            <button
              type="submit"
              onClick={() =>
                informations?.gift_code
                  ? dispatch(addGiftRequestItem(gift?.id))
                  : dispatch(
                      enqueueSnackbar({
                        message: t('You need to generate your gift code first'),
                        options: {
                          key: new Date().getTime() + Math.random(),
                          variant: 'warning',
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                          },
                          TransitionComponent: Slide,
                        },
                      })
                    )
              }
              disabled={addGiftRequestItemStatus === 'loading'}
              className={addGiftRequestItemStatus === 'loading' ? 'disabled-btn' : ''}
            >
              {t('Add to cart')}
            </button>
          </Box>
        </CardContent>
      </Card>
    </div>
  ) : (
    <NotFound />
  );
};

export default ProductDetails;
