import React, { useEffect, useRef, useState } from 'react';
import './_index.scss';

const CONSTRUCTION_YEAR = 2022;
const Years = ({ yearValue, setYearValue, setOpenYears, selectedDay }) => {
  const scrollRef = useRef();
  const [counter, setCounter] = useState({ start: 1900, end: 2099 });
  let currentYear = new Date().getFullYear();

  useEffect(() => {
    currentYear > CONSTRUCTION_YEAR &&
      setCounter((prev) => ({ start: ++prev.start, end: ++prev.end }));
  }, [currentYear]);

  useEffect(() => {
    scrollRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }, [yearValue]);

  useEffect(() => {
    setYearValue(+selectedDay?.split(' ')[2]);
  }, []);
  let years = [];
  for (let index = counter.start; index <= counter.end; index++) {
    years.push(index);
  }

  return (
    <div className="calendar-years">
      {years.map((year, index) => {
        return (
          <span
            ref={yearValue == year ? scrollRef : null}
            key={index}
            className={yearValue == year ? 'selected-year year' : 'year'}
            onClick={() => {
              setYearValue(year);
              setOpenYears(false);
            }}
          >
            {year}
          </span>
        );
      })}
    </div>
  );
};

export default Years;
