import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import DialogTitle from "@material-ui/core/DialogTitle";
import {Dialog, DialogContent} from "@material-ui/core";
import {startExam} from "../../../redux/slices/exams";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";


const ConfirmationTransferPoints = ({ id, open, handleClose, data, ...rest }) => {
    const { i18n, t } = useTranslation();
    const language = i18n.language;
  const dispatch = useDispatch();
  const [openSnackBar, setOpenSnackBar] = useState(false);
    const { startMessage, isStartExamLoading, isStarted } = useSelector((state) => state.exams);

    const handleCloseModal = () => {
        handleClose(id);
    };
    const handleStartExam =  () => {
        dispatch(startExam(data?.id));
        if(data?.link){
            window.open(data?.link, '_blank');
        }
        setOpenSnackBar(true);
    };
  return (
      <>
          <Dialog
              open={open}
              onClose={(e, reason) => {
                  if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                      handleClose(id);
                  }
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="ta-modal"
          >
              <DialogTitle id="alert-dialog-title">
                  <span className="label"> {t('Start Exam')}</span>
                  <span className="close-btn" onClick={() => handleClose(id)}>
                  {t("Fermer")}
                </span>
              </DialogTitle>
              <DialogContent className='confirm-parent-request ta-form'>
                  <div className={language === 'ar' ? 'confirm-text confirm-arabic-text' : 'confirm-text'}>
                      {t('Are you ready to start the exam now ?')}
                      <span className="parent-name">  </span> {t('The timer begins from the moment you downloaded the file')}
                  </div>
                  <div className="ta-btns-group">
                      <Button className="ta-btn btn-rounded btn-lg blue" onClick={() => handleCloseModal()}>
                          {t('Annuler')}
                      </Button>
                      <Button
                          className="ta-btn btn-rounded btn-lg parent-request-confirm-btn"
                          onClick={handleStartExam}
                      >
                          {t("Start")}
                      </Button>
                  </div>
          </DialogContent>
      </Dialog>
          {
              ( startMessage  && !isStartExamLoading) &&
              <Snackbar
                  open={openSnackBar}
                  autoHideDuration={3000}
                  anchorOrigin={{
                      vertical: 'Bottom',
                      horizontal: 'center',
                  }}
                  onClose={() => setOpenSnackBar(false)}
              >
                  <Alert severity={isStarted ? 'success': 'warning'} onClose={() => setOpenSnackBar(false)}>
                      { t(startMessage)}
                  </Alert>
              </Snackbar>
          }
    </>
  );
};

export default ConfirmationTransferPoints;
