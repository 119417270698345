import { Box, Card, CardContent } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './_index.scss';
import PageHeader from '../PageHeader';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NotFound from '../../../NotFound';

const NoCoins = ({ description, img }) => {
  const { t } = useTranslation();
  const { informations, permissions } = useSelector((state) => state.user);
  useEffect(() => {
    if (
      permissions &&
      permissions?.eligibleUserForGift === true &&
      informations &&
      !informations.gift_code
    ) {
      window.location.href = '/generate-gift-code';
    }
  }, [informations, permissions]);
  return permissions?.eligibleUserForGift === true ? (
    <Box className="no-coins-page">
      <PageHeader
        paths={[
          {
            label: 'Parrain',
            link: informations?.gift_code ? `/gift-code` : `/generate-gift-code`,
          },
          { label: 'Product' },
        ]}
        noCart
      />
      <Card className="no-coins-page-card">
        <CardContent className="no-coins-page-card-content">
          <div className="no-coins-page-img">
            <img src={img} alt="no-coins" />
          </div>
          <p>{t(description)}</p>
          <Link className="no-coins-page-back-home-btn" to="/gift-code">
            {t('Back home')}
          </Link>
        </CardContent>
      </Card>
    </Box>
  ) : (
    <NotFound />
  );
};

export default NoCoins;
