import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { CardTitle } from '../../../components';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageHeader from '../components/PageHeader';
import closeIcon from '../../../assets/img/icons/x.svg';

import './_index.scss';
import {
  deleteGiftRequestItem,
  getCurrentGiftRequests,
  giftRequestDelivery,
} from '../../../redux/slices/parrain';
import PreviousButton from '../components/PreviousButton';
import useSettings from '../../../hooks/useSettings';
import formatAmount from '../../../utilities/formatAmount';
import NotFound from '../../NotFound';
import { states } from '../../../utilities/constants';

const ConfirmOrder = () => {
  const { informations, permissions } = useSelector((state) => state.user);
  const { settings } = useSettings();

  const {
    currentGiftRequests,
    currentGiftID,
    currentGiftRequestsStatus,
    deleteGiftRequestItemStatus,
  } = useSelector((state) => state.parrain);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [id, setId] = useState(null);
  const { language } = i18n;

  useEffect(() => {
    if (
      permissions &&
      permissions?.eligibleUserForGift === true &&
      informations &&
      !informations.gift_code
    ) {
      window.location.href = '/generate-gift-code';
    }
  }, [informations, permissions]);

  const initialValues = {
    name: '',
    phone1: '',
    phone2: '',
    email: '',
    state: null,
    address: '',
    note: '',
    giftRequest: '',
  };

  useEffect(() => {
    if (currentGiftRequests?.length === 0 && currentGiftRequestsStatus === 'idle') {
      dispatch(getCurrentGiftRequests());
    }
  }, [currentGiftRequests]);

  const validationSchema = Yup.object({
    name: Yup.string()
      .required(t('Veuillez saisir votre nom'))
      .min(2, t('Le prénom est trop court Il doit contenir au moins 2 caractères')),
    phone1: Yup.string()
      .required(t('Veuillez saisir votre numéro de téléphone'))
      .test('test-name', t('Veuillez saisir un numéro de téléphone valide'), function (value) {
        const phoneRegex = /^[2459]\d{7}$/;
        let isValidPhone = phoneRegex.test(value);
        if (!isValidPhone) {
          return false;
        }
        return true;
      }),

    phone2: Yup.string()
      .optional()
      .test('test-name', t('Veuillez saisir un numéro de téléphone valide'), function (value) {
        const phoneRegex = /^[2459]\d{7}$/;
        if (value) {
          let isValidPhone = phoneRegex.test(value);
          if (!isValidPhone) {
            return false;
          }
        }
        return true;
      }),
    email: Yup.string().required(t('Veuillez saisir votre email')),
    state: Yup.string()
      .oneOf(
        states.map((state) => state.label),
        t('Veuillez choisir une gouvernorat valide')
      )
      .required(t('Veuillez choisir votre gouvernorat')),

    address: Yup.string().required(t('Veuillez saisir votre adresse')),
  });

  const deleteGiftRequestItemHandler = (itemId) => {
    if (itemId !== id) {
      dispatch(deleteGiftRequestItem(itemId));
    }
  };
  const onSubmit = (values, submitProps) => {
    try {
      dispatch(giftRequestDelivery({ ...values, giftRequest: currentGiftID }));
      submitProps.setStatus({ success: true });
      submitProps.setSubmitting(false);
    } catch (err) {
      submitProps.setStatus({ success: false });
      submitProps.setSubmitting(false);
    }
  };

  return permissions?.eligibleUserForGift === true ? (
    <div
      className={
        settings.sidebar === 'full'
          ? language === 'ar'
            ? 'parrain-confirm-order-full-sidebar parrain-page-arabic'
            : 'parrain-confirm-order-full-sidebar'
          : 'parrain-confirm-order'
      }
    >
      <PageHeader
        paths={[
          {
            label: 'Parrain',
            link: informations?.gift_code ? `/gift-code` : `/generate-gift-code`,
          },
          { label: 'User Information' },
        ]}
        noCart
      />
      <PreviousButton />
      <Card className="ta-card parrain-confirm-order-form-card">
        <CardContent>
          <div className="child-full">
            <div className="ta-form">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(formik) => {
                  return (
                    <Form className="">
                      <div className="parrain-confirm-order-form-content">
                        <div className="parrain-confirm-order-first-form">
                          <CardTitle title={t('User Information')} />
                          <Box
                            rowGap={3}
                            columnGap={2}
                            display="grid"
                            className="parrain-confirm-order-form delivery-information"
                            gridTemplateColumns={{
                              xs: 'repeat(1, 1fr)',
                              sm: 'repeat(1, 1fr)',
                            }}
                          >
                            <FormControl variant="outlined">
                              <InputLabel htmlFor="name" className="fix-display">
                                {t('Name')}
                              </InputLabel>
                              <Field
                                as={TextField}
                                id="name"
                                variant="outlined"
                                size="small"
                                name="name"
                              />
                              <div className="error">{<ErrorMessage name="name" />}</div>
                            </FormControl>
                            <Box
                              rowGap={3}
                              columnGap={2}
                              display="grid"
                              gridTemplateColumns={{
                                xs: 'repeat(1, 1fr)',
                                sm: 'repeat(2, 1fr)',
                              }}
                            >
                              <FormControl variant="outlined">
                                <InputLabel htmlFor="phone1" className="fix-display">
                                  {t('Phone Number')}
                                </InputLabel>
                                <Field
                                  as={TextField}
                                  id="phone1"
                                  variant="outlined"
                                  size="small"
                                  name="phone1"
                                />
                                <div className="error">
                                  <ErrorMessage name="phone1" />
                                </div>
                              </FormControl>
                              <FormControl variant="outlined">
                                <InputLabel htmlFor="phone2" className="fix-display">
                                  <span className="label-content">
                                    {t('Numéro de téléphone supplémentaire')}
                                  </span>
                                </InputLabel>
                                <Field
                                  as={TextField}
                                  id="phone2"
                                  variant="outlined"
                                  size="small"
                                  name="phone2"
                                />
                                <div className="error">
                                  <ErrorMessage name="phone2" />
                                </div>
                              </FormControl>
                              <FormControl variant="outlined">
                                <InputLabel htmlFor="email" className="fix-display">
                                  {t('Email')}
                                </InputLabel>
                                <Field
                                  as={TextField}
                                  id="email"
                                  variant="outlined"
                                  size="small"
                                  name="email"
                                />
                                <div className="error">
                                  <ErrorMessage name="email" />
                                </div>
                              </FormControl>

                              <FormControl>
                                <InputLabel htmlFor="state" className="fix-display">
                                  {t('Gouvernorat')}
                                </InputLabel>
                                <Autocomplete
                                  onChange={(_, value) =>
                                    formik.setFieldValue('state', value?.label)
                                  }
                                  onBlur={() => formik.setTouched({ ['state']: true })}
                                  getOptionSelected={(item, current) =>
                                    item.label === current.label
                                  }
                                  name="state"
                                  id="state"
                                  disablePortal
                                  options={states}
                                  autoHighlight
                                  noOptionsText={t('No options')}
                                  renderInput={(params) => (
                                    <Field
                                      as={TextField}
                                      {...params}
                                      variant="outlined"
                                      size="small"
                                      inputProps={{
                                        ...params.inputProps,
                                      }}
                                    />
                                  )}
                                />
                                <div className="error">
                                  <ErrorMessage name="state" />
                                </div>
                              </FormControl>
                            </Box>
                            <FormControl variant="outlined">
                              <InputLabel htmlFor="address" className="fix-display">
                                {t('Adress')}
                              </InputLabel>
                              <Field
                                as={TextField}
                                id="address"
                                variant="outlined"
                                size="small"
                                name="address"
                              />
                              <div className="error">
                                <ErrorMessage name="address" />
                              </div>
                            </FormControl>
                          </Box>
                        </div>
                        <CardTitle title={t('Add Comment')} className="note" />

                        <FormControl variant="outlined">
                          <Field
                            as={TextField}
                            id="note"
                            name="note"
                            variant="outlined"
                            placeholder={t('Additional Information')}
                            size="small"
                            multiline
                            rows={5}
                            maxRows={9}
                          />
                        </FormControl>
                      </div>
                      <div className="parrain-confirm-order-form-products">
                        <CardTitle title={t('Order Summary')} />
                        <Box
                          rowGap={3}
                          columnGap={2}
                          display="grid"
                          className="parrain-confirm-order-form parrain-confirm-order-products-form"
                          gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(1, 1fr)',
                          }}
                        >
                          <Box className="parrain-confirm-order-products-list-wrapper">
                            <div className="parrain-confirm-order-products-list">
                              <Box className="parrain-confirm-order-products-list-title">
                                <Typography>{t('1 order')}</Typography>
                                <Divider />
                              </Box>
                              <Box className="parrain-confirm-order-products">
                                {currentGiftRequests?.length > 0 ? (
                                  currentGiftRequests.map((el, index) => (
                                    <Card
                                      className="ta-card parrain-confirm-order-product"
                                      key={index}
                                    >
                                      <CardContent>
                                        <Box>
                                          <Box className="parrain-confirm-order-product-img">
                                            <img
                                              src={
                                                el?.gift?.gift_images?.find((el) => el?.is_cover)
                                                  ?.file_url || el?.gift?.gift_images?.[0]?.file_url
                                              }
                                              alt="product-img"
                                            />
                                          </Box>
                                          <Box className="parrain-confirm-order-product-details">
                                            <Typography className="parrain-confirm-order-product-name">
                                              {el?.gift?.name}
                                            </Typography>
                                            <Typography className="parrain-confirm-order-product-coins">
                                              {formatAmount(el?.gift?.amount, t)}
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <Box
                                          className={
                                            currentGiftRequestsStatus !== 'succeeded' &&
                                            deleteGiftRequestItemStatus === 'loading'
                                              ? 'parrain-confirm-order-product-close-btn disabled-btn'
                                              : 'parrain-confirm-order-product-close-btn'
                                          }
                                          onClick={() => {
                                            deleteGiftRequestItemHandler(el?.id);
                                            setId(el?.id);
                                          }}
                                        >
                                          <img src={closeIcon} alt="close-icon" />
                                        </Box>
                                      </CardContent>
                                    </Card>
                                  ))
                                ) : (
                                  <Typography>{t('No products found')}</Typography>
                                )}
                              </Box>
                            </div>
                          </Box>
                          <Box>
                            <div className="total-coins">{`${t('Total')}                        
                            ${formatAmount(
                              currentGiftRequests?.reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue?.gift?.amount,
                                0
                              ),
                              t
                            )}`}</div>
                            <button div className="parrain-confirm-order-form-btn" type="submit">
                              {t('Confirm Order')}
                            </button>
                          </Box>
                        </Box>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  ) : (
    <NotFound />
  );
};

export default ConfirmOrder;
