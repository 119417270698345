export const loginValidation = (data) => {
  if (!data.username) {
    return { success: false, 'message': 'Credential is missing' };
  }
  if (!data.password) {
    return { success: false, 'message': 'Password is missing' };
  }
};

export const registerValidation = (data, geoIp, optional_subjects) => {
  if (!data.name) {
    return { success: false, 'message': 'First Name is missing' };
  }
  if (!data.lastName) {
    return { success: false, 'message': 'Last Name is missing' };
  }
  if (data.name && !(/^[ ً ُ ٌ ِ ٍ ّء-يa-zA-Z]{2,20}(\s[ء-ي ً ُ ٌ ِ ٍ ّa-zA-Z](\s+){2,20})?$/.test(data.name.trim()))) {
    return { success: false, 'message': 'Please enter your real first name' };
  }
  if (data.lastName && !(/^[ ً ُ ٌ ِ ٍ ّء-يa-zA-Z]{2,20}(\s[ء-ي ً ُ ٌ ِ ٍ ّa-zA-Z](\s+){2,20})?$/.test(data.lastName.trim()))) {
    return { success: false, 'message': 'Please enter your real last name' };
  }
  if (geoIp.country ? geoIp.country === 'TN': geoIp === 'TN') {
    if (!data.phone || data.phone.length != 8 || isNaN(data.phone)) {
      return { success: false, 'message': 'Phone is missing' };
    }
  } else {
     if (!data.email || !data.email.indexOf('@')) {
      return { success: false, 'message': 'Email is missing' 
    };
   }
    
   }
  
  if (!data.birthDate) {
    return { success: false, 'message': 'Birth date is missing' };
  }
  if (!data.plainPassword?.first) {
    return { success: false, 'message': 'Password is missing' };
  }

  if (!(/^(?=.*\d)(?=.*[A-Z])(?=.*[:=+*-_()&?.!;,@#$%€¥])(?!.*(.)\1{5}).*[a-z]/m).test(data.plainPassword?.first)) {
    return { success: false, 'message': 'The password should contain at least a capital letter a small letter a number and a special character'};
  }
  if (data.plainPassword?.first.length > 16 || data.plainPassword?.first.length < 8){
    return { success: false, 'message': 'The password value should be between 8 and 16 characters' };
  }
  if (!data.plainPassword?.second || data.plainPassword?.first != data.plainPassword?.second) {
    return { success: false, 'message': 'Confirm Password is missing' };
  }
  if (!data.division) {
    return { success: false, 'message': 'Division is missing' };
  }
  if (optional_subjects && optional_subjects.length >0 && !data.optionalSubject) {
    return { success: false, 'message': 'Option is missing' };
  }
   if(!data.state && geoIp.country === 'TN' ) {
    return { success: false, 'message': 'State is missing' };
  }
  if(!data.country && geoIp.country !== 'TN' ) {
    return { success: false, 'message': 'Country is missing' };
  }
};
export const resetPasswordValidation = (data) =>  {
  if (!data.token || /\s/g.test(data.token || data.token.trim().length === 0)) {
    return { success: false, 'message': 'Code is missing' };
  }
  if (!data.credential) {
    return { success: false, 'message': 'credential is missing' };
  }
  if (!data.newPassword) {
    return { success: false, 'message': 'Password is missing' };
  }
  if (!(/^(?=.*\d)(?=.*[A-Z])(?=.*[:=+*-_()&?.!;,@#$%€¥])(?!.*(.)\1{5}).*[a-z]/m).test(data?.newPassword)) {
    return { success: false, 'message': 'The password should contain at least a capital letter a small letter a number and a special character' };
  }
  if (data.newPassword.length > 16 || data.newPassword.length < 8){
    return { success: false, 'message': 'The password value should be between 8 and 16 characters' };
  }
  if (!data.retypePassword) {
    return { success: false, 'message': 'Confirm Password is missing' };
  }
  if (data.retypePassword != data.newPassword) {
    return { success: false, 'message':  'Les nouveaux mot de passes ne sont pas identiques'};
  }
};

export const forceResetPasswordValidation = (data) =>  {
  if (!data.token || /\s/g.test(data.token || data.token.trim().length === 0)) {
    return { success: false, 'message': 'Code is missing' };
  }
  if (!data.newPassword) {
    return { success: false, 'message': 'Password is missing' };
  }
  if (!(/^(?=.*\d)(?=.*[A-Z])(?=.*[:=+*-_()&?.!;,@#$%€¥])(?!.*(.)\1{5}).*[a-z]/m).test(data?.newPassword)) {
    return { success: false, 'message': 'The password should contain at least a capital letter a small letter a number and a special character' };
  }
  if (data.newPassword.length > 16 || data.newPassword.length < 8){
    return { success: false, 'message': 'The password value should be between 8 and 16 characters' };
  }
  if (!data.retypePassword) {
    return { success: false, 'message': 'Confirm Password is missing' };
  }
  if (data.retypePassword != data.newPassword) {
    return { success: false, 'message':  'Les nouveaux mot de passes ne sont pas identiques'};
  }
};


export const digitalVergoValidation = (data) => {
 
  if (!data.phone) {
    return { success: false, 'message': 'Ajouter votre téléphone' };
  } 
  if (!data.division) {
    return { success: false, 'message': 'Ajouter votre classe' };
  }
};
