import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { getPopups, hidePopup } from '../../redux/slices/popups';
import parse from 'html-react-parser';

const styles = (theme) => ({
  root: {
    margin: 2,
    marginBottom: 2,
    paddingBottom: 2,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(3),
    color: theme.palette.grey[500],
  },
  hideButton: {
    backgroundColor: 'red',
  },
});
export const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function PopupsModal({ userId }) {
  const { popup } = useSelector((state) => state.popups);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (userId) {
      dispatch(getPopups(userId));
    }
  }, [userId]);
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleHide = () => {
    dispatch(hidePopup(popup._id, userId));
    setOpen(false);
  };
  if (popup) {
    return (
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          className="Popups"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {popup?.popup?.name}
          </DialogTitle>
          <DialogContent dividers>{parse(popup?.popup?.body)}</DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {t('Fermer')}
            </Button>
            <Button onClick={handleHide} className="closeButton">
              {t('Ne plus Montrer')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return <> </>;
  }
}
