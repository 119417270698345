import React from 'react';

const Index = ({ image, text, title }) => {
  return (
    <div className="no-data-illustration">
      <img src={image} alt={title} />
      <p>{text}</p>
    </div>
  );
};

export default Index;
