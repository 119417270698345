import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ViewTitle from '../../components/ViewTitle';
import { SecurityWidget, EditProfileWidget, PubsCards } from '../../components';
import { getPubsCards } from '../../redux/slices/offers';

const Index = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { informations } = useSelector((state) => state.user);
  const { permissions } = useSelector((state) => state.user);
  const { pubsCards, pubsCardsStatus } = useSelector((state) => state.offers);

  const profileCompleted = informations && informations.completed;
  const hasEditDivision = permissions && permissions?.hasEditDivision;
  const isTest = informations && informations.isTest;
  const admin = localStorage.getItem('admin_user_id');

  useEffect(() => {
    if (pubsCardsStatus === 'idle' && pubsCards?.length < 1) {
      dispatch(getPubsCards());
    }
  }, [pubsCards]);

  return (
    <div className="full-page">
      <ViewTitle
        title={profileCompleted === 1 ? t('Modifier mon profil') : t('Compléter vos données')}
      />
      <div className="ta-row">
        <div className="ta-column right-margin layout-profile">
          <div className="child-left">
            <EditProfileWidget
              hasEditDivision={hasEditDivision}
              profileCompleted={profileCompleted}
              admin={admin}
            />
          </div>
          <div className="child-right">
            <SecurityWidget isTest={isTest} admin={admin} />
          </div>
        </div>
        <div className="ta-column fixed right">
          <div style={{ width: '100%', marginTop: '60px' }}></div>
          {pubsCards
            ?.slice()
            ?.sort((a, b) => a.position - b.position)
            ?.map((card) => {
              return (
                <PubsCards
                  key={card?.id}
                  className={card?.type}
                  icon={card?.icon_url}
                  text={card?.text_image_url || card?.text}
                  isText={card?.text}
                  modal={card?.video_link != null}
                  path={card?.video_link || card?.url_link}
                  bg={card?.background_url}
                  visible={card?.visible}
                  iconPosition={card?.icon_position}
                  color={card?.color}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Index;
