import React, { useEffect, useState } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Im_Ooredoo from '../../../assets/img/ooredoo2.png';

const OoredooItem = (props) => {
  const { id, couponCode, offerId, check, icon, text,color,price,onClick, ...rest } = props;
  const { t, i18n } = useTranslation();
  const [offerCheck, setOfferCheck] = useState(false);
  const dispatch = useDispatch();

  
  return (
    <div className="Ooredoo-box">
      <div className="ooredoo-icon">
        <img className = "ooredooImg" src={Im_Ooredoo}/>
        <div className= {i18n.language === 'ar' ?'ooreedoo-price-rtl':'ooreedoo-price'}>{price}</div>
      </div>
    
      <div className='text-ooreedoo'>{text}</div>
      {/* <Button style={{ backgroundColor: color }} onClick={onClick} className='acheter-mini'>{t('Acheter')}</Button> */}
    </div>
  );
};

export default OoredooItem;
