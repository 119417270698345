import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import phoneIcon from '../../../../assets/img/icons/phone.svg';
import divisionIcon from '../../../../assets/img/icons/division.svg';
import takiacademyBrand from '../../../../assets/img/icons/BrandTakiacademy.svg';
import './_index.scss';
import {
  Backdrop,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Input from '../../components/Input';
import Select from '../../components/select';
import { Alert } from '@material-ui/lab';
import PlanCard from '../planCard';
import { checkoutDigitalVergo } from '../../../../redux/slices/auth';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OoredooModel = ({ open, setOpen, price }) => {
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const { divisions, success } = useSelector((state) => state.auth);
  const [extraErrors, setExtraErrors] = useState();
  const [formErrors, setFormErrors] = useState();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [checked, setchecked] = useState('');
  const [recurrence, setRecurrence] = useState('');

  const formRef = useRef();

  useEffect(() => {
    if (open === false && formRef.current) {
      formRef.current.resetForm();
      setchecked('');
    }
  }, [open]);

  useEffect(() => {
    if (extraErrors) {
      setOpenSnackBar(true);
    }
  }, [extraErrors]);
  const initialValues = {
    phone: '',
    division: null,
  };

  const validationSchema = Yup.object({
    phone: Yup.string()
      .required('Le numéro de téléphone est requis')
      .test('test-name', 'Veuillez saisir un numéro de téléphone valide', function (value) {
        const phoneRegex = /^((\+|00)216)?([2459][0-9]{7})+$/;
        let isValidPhone = phoneRegex.test(value);
        if (!isValidPhone) {
          return false;
        }
        return true;
      }),

    division: Yup.string().required('La division est requise'),
  });
  const onSubmit = async (values, submitProps) => {
    try {
      if (recurrence) {
        await dispatch(
          checkoutDigitalVergo({
            recurrence,
            ...values,
          })
        )
          .then((res) =>
            res?.payload?.includes('Le numéro existe déjà')
              ? submitProps.setErrors({ phone: res?.payload })
              : res?.payload?.includes('Server')
              ? setExtraErrors("Une erreur s'est produite, veuillez réessayer ultérieurement")
              : setExtraErrors(res?.payload)
          )
          .catch((error) => setExtraErrors(error));
      }

      submitProps.setStatus({ success: true });
      submitProps.setSubmitting(false);
    } catch (err) {
      if (!err.message) {
        setExtraErrors('error');
      } else {
        setExtraErrors(err.message);
      }

      submitProps.setStatus({ success: false });
      submitProps.setSubmitting(false);
    }
  };

  return (
    <di className="ooredoo-modal">
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        BackdropComponent={Backdrop}
        BackdropProps={{ style: { backgroundColor: 'rgba(0, 79, 139, 0.9)', opacity: '0.3' } }}
        className="ooredoo-modal-wrapper"
      >
        <IconButton onClick={handleClose} className="close-modal-btn">
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <div>
            <img src={takiacademyBrand} />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="ooredoo-form">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              innerRef={formRef}
            >
              {(formik) => {
                setFormErrors(formik.errors);
                return (
                  <Form className="">
                    <Box
                      rowGap={3}
                      columnGap={2}
                      display="grid"
                      className="form-grid"
                      gridTemplateColumns={{
                        xs: 'repeat(1, 1fr)',
                        sm: 'repeat(2, 1fr)',
                      }}
                    >
                      <Input
                        type="text"
                        label={'Numéro de téléphone'}
                        name="phone"
                        placeholder={'Votre numéro de téléphone ici'}
                        leftIcon={phoneIcon}
                        className="ooredoo-input-field"
                        required
                        error={
                          formik.errors.phone && formik.touched.phone ? formErrors.phone : null
                        }
                      />

                      <Select
                        options={divisions || []}
                        placeholder={'Choisissez votre classe'}
                        label={'Classe'}
                        name="division"
                        className="ooredoo-input-field"
                        error={
                          formik.errors.division && formik.touched.division
                            ? formErrors.division
                            : null
                        }
                        icon={divisionIcon}
                        checked={checked}
                        setchecked={setchecked}
                        required
                      />
                    </Box>
                    <div className="choose-offer-section">
                      <div className="choose-offer-section-title">
                        <span> Choisissez votre plan</span>
                        <hr></hr>
                      </div>
                      <div className="choose-offer-section-cards">
                        <PlanCard
                          price={price?.dailyPrice}
                          period="1"
                          setRecurrence={setRecurrence}
                        />
                        <PlanCard
                          price={price?.weeklyPrice}
                          period="7"
                          setRecurrence={setRecurrence}
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <Snackbar
              open={openSnackBar}
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              onClose={() => setOpenSnackBar(false)}
            >
              <Alert
                severity={success === false ? 'error' : 'success'}
                onClose={() => setOpenSnackBar(false)}
              >
                {extraErrors}
              </Alert>
            </Snackbar>
          </div>
        </DialogContent>
      </Dialog>
    </di>
  );
};
export default OoredooModel;
