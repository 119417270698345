import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import addPointsIcon from '../../assets/img/icons/addPointsIcon.png';
import Modal from '../../components/Modal/Modal';
import { FormTransferDialog, TransferPointsModal} from '..';

const Index = () => {
  const { t } = useTranslation();
  const [open, openModal] = useState(false);
  const openTransferModal = () => {
    openModal(true);
  };
  const onCloseModal = () => {
    openModal(false);
  };
  return (
    <>
    
      <div className="point-transfer" onClick={openTransferModal}>
         <img alt="addPointsIcon" src={addPointsIcon} className="addPointsIcon" />
        <Typography className="transferText">{t('Ajouter des points')}</Typography>
      </div>
      <Modal
        onCloseModal={onCloseModal}
        component={FormTransferDialog}
        openModal={open}
        title={t('Ajouter des points')}
        modalWidth={'xs'}
      />
    </>
    
  );
};

Index.propTypes = {
  open: PropTypes.bool,
  openModal: PropTypes.func,
  openTransferModal: PropTypes.func,
  onCloseModal: PropTypes.func,
};

export default Index;
