import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import './_index.scss';
import {
  Backdrop,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  IconButton,
} from '@material-ui/core';

import welcomeBg from '../../assets/img/welcome-img.svg';
import welcomeImg from '../../assets/img/welcome.svg';
import { useHistory } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WelcomeModel = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
    sessionStorage.removeItem('clientType');
  };
  const history = useHistory();

  const onClickHandler = () => {
    sessionStorage.removeItem('clientType');
    history.push('/offres');
  };
  return (
    <div className="welcome-modal">
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        BackdropComponent={Backdrop}
        BackdropProps={{ style: { backgroundColor: '#0087EBE5' } }}
        className="welcome-modal-wrapper"
      >
        <div className="welcome-modal-header">
          <IconButton onClick={handleClose} className="close-modal-btn">
            <span>X</span>
          </IconButton>
          <DialogTitle>
            <h1 className="welcome-modal-title"> مرحبًا بك في منصة تقي أكاديمي!</h1>
            <img src={welcomeBg} alt="welcome-bg" className="welcome-bg" />
            <img src={welcomeImg} alt="laptop-img" className="welcome-img" />
          </DialogTitle>
        </div>

        <DialogContent>
          <span className="welcome-modal-description">
            نحن ممتنون لانضمامك إلى منصتنا التعليمية عبر الإنترنت. نرحب بك بحرارة في مجتمع التعلم
            الرقمي.
            <br />
            هنا ستجد مجموعة متنوعة من الدروس والموارد التعليمية التي ستساعدك في تحقيق أهدافك
            التعليمية. نتطلع إلى مشاركتك ونجاحك في رحلتك التعليمية معنا.
            <br />
            إذا كان لديك أي استفسارات أو تحتاج إلى مساعدة، فلا تتردد في الاتصال بفريق الدعم الفني
            لدينا. نحن هنا لمساعدتك في كل خطوة على طول الطريق.
          </span>
          <button className="welcome-modal-btn" onClick={() => onClickHandler()}>
            ابدأ رحلتك
          </button>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default WelcomeModel;
