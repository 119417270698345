import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { CardTitle, ActionLink } from '../../components';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useSelector, useDispatch } from 'react-redux';
import { getPosts } from '../../redux/slices/forum';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import ForumActionLink from '../ForumActionLink';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const Index = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const { posts } = useSelector((state) => state.forum);
  const { permissions, informations } = useSelector((state) => state.user);
   const hasSilver = informations && informations.offers && informations.offers.filter((offer) => offer.groupeId == 33).length > 0 ? true :false;
  const forumUrl = process.env.FORUM_URL;

  const submit = () => {
    if (permissions && permissions.forum) {
      window.open('https://forum.takiacademy.com');
    }
    setOpenSnackBar(true);
  };

  useEffect(() => {
    if( informations?.division_id){
    dispatch(getPosts());
  }
  }, [dispatch,informations?.division_id]);

  return (
    <Card className="ta-card">
      <CardHeader
        title={<CardTitle title={t("Le forum")} className="underlined forum-title" />}
        action={
          <ForumActionLink
            label={t("voir tout")}
            icon={<ChevronRightIcon />}
            className="text-muted"
            forum={permissions && permissions.forum}
            hasSilver={hasSilver}
          />
        }
      />
      <CardContent className="list_post" onClick={submit}>
        {posts &&
          Object.values(posts).map((post) => (
            <div>
              <Typography className="post-title">{post.title}</Typography>
              <Typography className="poster">Par {post.last_poster_username}</Typography>
              {/* <Typography className="post-content">
             {post.excerpt.replace('&hellip;', '...')}
              </Typography> */}
              <Divider variant="middle" />
            </div>
          ))}
        <Snackbar
          open={openSnackBar}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert severity="warning" onClose={() => setOpenSnackBar(false)}>
          {hasSilver == false ? t('Vous ne pouvez pas accèder au forum') : t('Pour profiter du forum veuillez souscrire à Silver+ ou Gold pour toute l année')}
          </Alert>
        </Snackbar>
      </CardContent>
    </Card>
  );
};

Index.propTypes = {
  posts: PropTypes.object,
};

export default Index;
