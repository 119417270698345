import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {DialogTitle} from '../Popups/ModalPopus';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {useDispatch} from 'react-redux';
import {
  getRatingSessions,
  hideRating,
  submitRating,
} from '../../redux/slices/sessions';
import sad from '../../assets/img/sadEmo.svg';
import happy from '../../assets/img/happyEmo.svg';
import love from '../../assets/img/loveEmo.svg';
import like from '../../assets/img/likeEmo.svg';
import cry from '../../assets/img/cryEmo.svg';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {convertSecondDuration} from '../../utilities/timeMethods';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  hideButton: {
    backgroundColor: 'red',
  },
});
export default function Index({reateable}) {
  const dispatch = useDispatch();
  const {webinar_session_attendee_rateable,content_event}=reateable;
  const [addComment,setAddComment]=useState(false);
  const [selectedEmoji,setSelectedEmoji]=useState(null);
  const [commentValue,setCommentValue]=useState(null)
  const [open, setOpen] = React.useState(true);
  const [snackBarResult, setSnackBarResult] = React.useState('');
  const [snackBarStatus, setSnackBarStatus] = React.useState(false);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleHide = () => {
    dispatch(hideRating({'attendanceIds':reateable.webinar_session_attendee_rateable._id}));
  };
  const handleSubmit=async ()=>{
    if(selectedEmoji) {
      await dispatch(submitRating({
        'attendanceIds': reateable.webinar_session_attendee_rateable._id,
        'rate': selectedEmoji,
        'observation':commentValue
      }));
      setSnackBarResult('تم تقييم الحصة بنجاح ! ');
      setSnackBarStatus(true)
      setOpenSnackBar(true)
      return setOpen(false)
    }
    setSnackBarResult('من فضلك قم بإبداء رأيك ! ');
    setSnackBarStatus(false)
    setOpenSnackBar(true)
  }
  return (
      <div>
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}
                       className="rating-card-title">
            تقييم الحصص المباشرة
          </DialogTitle>
          <DialogContent dividers>
            <div className="rating-text" dir="rtl">
              سعيا منّا لتقديم الأفضل لتلاميذنا، نرجو منك إبداء رأيك بخصوص هذه
              الحصّة
              المباشرة :
            </div>
            <div className="session-informations" >
              <div className="session-item">
                {dayjs.tz(content_event.start_date,'YYYY/MM/DD HH:mm','Africa/Tunis').format('YYYY/MM/DD HH:mm') }<div className="session-info-title"> : التّاريخ والتّوقيت</div>
              </div>
              <div className="session-item">
                {content_event.content.name } <div className="session-info-title"> : عنوان الحصّة </div>
              </div>
              <div className="session-item">
                {convertSecondDuration(webinar_session_attendee_rateable.attendanceTimeInSeconds) } <div className="session-info-title"> : مدّة حضورك في الحصّة </div>
              </div> 
            </div>
            <div className='rating-session-text' dir='rtl'>ما رأيك في هذه الحصّة بعد حضورها؟</div>
            <div className="emoji">
              <img src={love} id={5}  title="أعجبتني الحصة جدا" className={selectedEmoji==='5' && 'selected-emoji'} onClick={(event)=>setSelectedEmoji(event.target.id)}/>
              <img src={happy} id={4} title="أعجبتني الحصة " className={selectedEmoji==='4' && 'selected-emoji'}onClick={(event)=>setSelectedEmoji(event.target.id)} />
              <img src={like} id={3} title="حصة عادية " className={selectedEmoji==='3' && 'selected-emoji'} onClick={(event)=>setSelectedEmoji(event.target.id)} />
              <img src={sad} id={2} title="لم تعجبني الحصة" className={selectedEmoji==='2' && 'selected-emoji'} onClick={(event)=>setSelectedEmoji(event.target.id)}/>
              <img src={cry} id={1} title="حصة سيئة للغاية" className={selectedEmoji==='1' && 'selected-emoji'} onClick={(event)=>setSelectedEmoji(event.target.id)}/>
            </div>
            <div className="add-comment-button" onClick={()=>setAddComment(!addComment)}>
              كتابة ملاحظة
            </div>
            {addComment &&
            <div className="comment">
                <textarea placeholder="الملاحظات ...." dir="rtl"
                          className="comment-text-area" onChange={(event)=>setCommentValue(event.target.value)}/>
            </div>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleHide} color="primary">
              لا أرغب في التّقييم
            </Button>
            <Button onClick={handleSubmit} className="closeButton" style={{fontWeight:'bold'}}>
              تسجيل التّقييم
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={()=>setOpenSnackBar(false)} severity={snackBarStatus?"success":'warning'}>
            {snackBarResult}
          </Alert>
        </Snackbar>
      </div>
  );
}
