import React from 'react';
import PropTypes from 'prop-types';

const Empty = ({ label, icon, className }) => {
  return (
    <div className={`empty${className ? ' ' + className : ''}`}>
      <img src={icon} alt="icon" />
      <span>{label}</span>
    </div>
  );
};

Empty.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.any,
};

export default Empty;
