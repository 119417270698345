import i18n from '../services/i18n';

//function for translation to hundle backend messages that contains ". \ ' : ," 
export const formatMessages = (msg) => {
  if(msg){
    if(msg.includes("less")){
     return birthDateLessErrorMsg(msg);
    }else if(msg.includes("greater")){ 
    return birthDateGreaterErrorMsg(msg);
    }else{
      return msg?.replace(/[.':,[\]]/g, "").trim();
     }
    }
  };

  export const formatMessagesWithValue = (msg) => {
    const regex = /"*"/g;
    const valueToRemove = msg?.match(regex);
    if(msg){
       return msg?.replace(valueToRemove, "").trim();
    }  
  };

  export const formatMsgBackend = (msg) => {
    if (msg) {
     if (msg.search(/"*"/g) === -1) {
       return   formatMessages(msg);
       }else {
      return formatMessagesWithValue(msg);
    }
   }
  };

  export const extractHoursAndMinutes = (duration) =>{
    const result = [];
    // 0 Heures 5 Min
    if (duration?.length === 14) {
      const nbHour = duration?.substring(0,1);
      const hours = duration?.substring(2,8);
      const nbMinutes = duration?.substring(8,11);
      const minutes = duration?.substring(11,14);

  return (
    result.concat([nbHour], [hours], [nbMinutes], [minutes])
    );
 }  
    else if (duration?.length === 15) {
      // 1 Heures 13 Min
        if (/\s/.test(duration.charAt(1))) {
          const nbHour = duration?.substring(0,1);
          const hours = duration?.substring(2,8);
          const nbMinutes = duration?.substring(8,12);
          const minutes = duration?.substring(12,15);
      
      return (
        result.concat([nbHour], [hours], [nbMinutes], [minutes])
        );

    }// 13 Heures 1 Min
        else if (/\s/.test(duration.charAt(2))) {
          const nbHour = duration?.substring(0,2);
          const hours = duration?.substring(3,9);
          const nbMinutes = duration?.substring(10,11);
          const minutes = duration?.substring(12,15);
      
      return (
        result.concat([nbHour], [hours], [nbMinutes], [minutes])
        );
     }
   } 
   else if (duration?.length === 16) {
      // 10 Heures 13 Min
       if (/\s/.test(duration.charAt(2))) {
            const nbHour = duration?.substring(0,2);
            const hours = duration?.substring(3,9);
            const nbMinutes = duration?.substring(10,12);
            const minutes = duration?.substring(13,16);
        return (
          result.concat([nbHour], [hours], [nbMinutes], [minutes])
        );
     } // 160 Heures 9 Min
        else if (/\s/.test(duration.charAt(3))) {
            const nbHour = duration?.substring(0,3);
            const hours = duration?.substring(4,10);
            const nbMinutes = duration?.substring(11,12);
            const minutes = duration?.substring(13,16);

        return (
          result.concat([nbHour], [hours], [nbMinutes], [minutes])
        );
     }
  }
}


// Translate calender week date
   export  const extractMonthFromWeek = (weekDate) =>{
    const result = [];
      const date1 = weekDate?.substring(0,3);
      const month1 = weekDate?.substring(3,6);
      const date2 = weekDate?.substring(9,12);
      const month2 = weekDate?.substring(12,15);
      const year = weekDate?.substring(16,20);

        return (result.concat([date1], [month1], [date2], [month2], [year]));
    };

// Translate event day
 export const extractEventDay = (day) =>{
    const result = [];
      if(day.length == 10){
      const date1 = day?.substring(0,2);
      const month = day?.substring(2,5);
      const year = day?.substring(6,10);
 
          return (result.concat([date1], [month], [year]))      
    }else  {
      const date1 = day?.substring(0,3);
      const month = day?.substring(3,6);
      const year = day?.substring(6,11);
      
          return (result.concat([date1], [month], [year]))
        }
 };

// Translate calender day
  export const extractMonthFromDay = (day) =>{
    const result = [];
      if(day.length == 5){
          const date1 = day?.substring(0,1);
          const month = day?.substring(2,5);

      return (result.concat([date1], [month]))

      }else if (day.length == 6){
        const date1 = day?.substring(0,2);
        const month = day?.substring(3,7);
        return (result.concat([date1], [month]))
    }
      else if(day.length == 7) {
        const date1 = day?.substring(0,2);
        const month = day?.substring(4,7);
        return (result.concat([date1], [month]))
    }
    };
 // translate message : "Birthdate value should be less than May 8 2017"
const birthDateLessErrorMsg = (msg) => {
    const msg2 = msg?.replace(/[.':,[\]]/g, "");
    const str1 = msg2.substring(0,40);
    const str2 = msg2.substring(41,52);
    if(i18n.language === 'ar'){
    return str2 + ' ' + i18n.t(str1);
    }else{
      return i18n.t(str1) + ' ' + str2;
    }
  }
  const birthDateGreaterErrorMsg = (msg) => {
    const msg2 = msg?.replace(/[.':,[\]]/g, "");
    const str1 = msg2.substring(0,43);
    const str2 = msg2.substring(44,56);
    if(i18n.language === 'ar'){
      return str2 + ' ' + i18n.t(str1);
    }else{
        return i18n.t(str1) + ' ' + str2;
    }
};
