import React from 'react'
import { Dialog, DialogContent } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';

import { ReactComponent as OptimiumIcon } from '../../assets/img/icons/optimium.svg';
import { ReactComponent as CheckIcon } from '../../assets/img/icons/fi_check.svg';
import { ReactComponent as CloseIcon } from '../../assets/img/icons/closeIcon.svg';

const Index = (props) => {
    const { open, setOpen, setBuyOptimum, setConfirmation } = props;
    const { t, i18n } = useTranslation();
    const handleClose = () => {
        setOpen(false);
        setShow(false);
    };
    const handleBuyOptimum = () => {
        setBuyOptimum(true)
        setOpen(false);

    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            titleStyle={{ textAlign: 'center' }}
            aria-describedby="alert-dialog-description"
            className="optimium-modal"
            fullWidth={true}
            maxWidth="sm"
        >
            <div className='header'  >
                <OptimiumIcon />
                <IconButton onClick={handleClose} className="close-optimium">
                    <CloseIcon />
                </IconButton>                <span style={{ direction: i18n?.language === "ar" ? "rtl" : "ltr" }}> {t("Optimum offer is the right offer for you")}</span>
            </div>
            <div className='description'>
                {t("If you would like to subscribe to more than one subject")}            </div>
            <span className='subtitle' style={{ direction: i18n?.language === "ar" ? "rtl" : "ltr" }}>   {t("The offer includes")} </span>
            <div className='options-list' style={{
                direction: i18n?.language === "ar" ? "rtl" : "ltr",
                alignItems: i18n?.language === "ar" ? "flexStart" : "baseline"
            }}>
                <div className='item'><CheckIcon style={{ width: i18n?.language === "ar" ? "unset" : "30px" }} />  {t("Weekly live classes in all subjects according to the division")} </div>
                <div className='item'><CheckIcon />  {t("Live class recordings for the past and current year")}</div>
                <div className='item'> <CheckIcon />   {t("Forum - forum to interact with professors and ask questions")}</div>
                <div className='item'> <CheckIcon />  {t("Free Silver Offer with all its features")} </div>

            </div>
            <div className='buy-btn-container'>            <button className='buy-btn' onClick={handleBuyOptimum}>Acheter</button>
            </div>
        </Dialog>
    )
}

export default Index