import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './_index.scss';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cartImg from '../../../../assets/img/icons/cart.svg';
import { getCurrentGiftRequests } from '../../../../redux/slices/parrain';

const Cart = () => {
  const { currentGiftRequests, currentGiftRequestsStatus } = useSelector((state) => state.parrain);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (currentGiftRequests?.length === 0 && currentGiftRequestsStatus === 'idle') {
      dispatch(getCurrentGiftRequests());
    }
  }, [currentGiftRequests]);
  return (
    <Link to={currentGiftRequests?.length >= 1 ? '/parrain/confirm-order' : '/parrain/empty-cart'}>
      <div className="parrain-page-cart">
        <img className="parrain-page-cart-icon" src={cartImg} alt="cart-img" />
        <div className="parrain-page-cart-text">{t('Votre Panier')}</div>
        <div className="parrain-page-cart-items-number">{currentGiftRequests?.length}</div>
      </div>
    </Link>
  );
};

export default Cart;
