import { createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

// Slice
const slice = createSlice({
  name: 'assistance',
  initialState: {
    videos: [],
    isAssistantLoading: false,
    error: false,
  },
  reducers: {
    startLoading: (state) => {
      state.isAssistantLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isAssistantLoading = false;
    },
    assistanceSuccess: (state, action) => {
      state.videos = action.payload;
      state.isAssistantLoading = false;
    },
  },
});
export default slice.reducer;
// Actions
const { assistanceSuccess, startLoading, hasError } = slice.actions;

export const getVideos = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.get(`/video-links`).then((response) => dispatch(assistanceSuccess(response.data?.payload)));
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};
