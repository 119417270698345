import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import warningIcon from '../../assets/img/icons/warning.svg';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import BorderLinearProgress from './LinearProgress';
import { Link } from 'react-router-dom';
import I18NextHttpBackend from 'i18next-http-backend';

const Index = (props) => {
  const { i18n,t } = useTranslation();
  const { groupeName, restDays, expirationDate, isSlice, sliceIndex } = props.warning;
  const progressValue = (10 - restDays) * 10;

var today = new Date();
//let currentTime = today.getHours() + ":" + today.getMinutes() ;
let expirationTime = expirationDate.substring(11,13);
let hourDifference
if (expirationTime === '00'){
  hourDifference = 24 - today.getHours();
}else if(expirationTime >  today.getHours() ){
  hourDifference = expirationTime - today.getHours();
}else if (expirationTime <  today.getHours() ){
  hourDifference = (24 - today.getHours()) + parseInt(expirationTime) ;
}else{
  hourDifference = expirationTime - today.getHours();
}


  return (
    <Card className="ta-card warning-card">
      <CardContent className="card-container">
        <img src={warningIcon} width={110} alt="" />
        <div className="warning-description">
          <div className="warning-header">
            <div className="offer-name"> {groupeName}</div>
            <Link className="show-more" to = "/offres" >
              {' '}
              {t("voir plus")}
            <ChevronRightIcon className="show-more-icon" />
            </Link>
          </div>
          <div className="warning-details">
            {restDays === 0 ? (
            <div className="rest-period">
              {t("votre")} {isSlice ? t("offre") : t("abonnement actuel")} {t("prend fin dans")} 
                   {hourDifference == 0? `24 ${t("h")}` :hourDifference == 2 && i18n.language === 'ar' ? t("DeuxHeures"): (hourDifference !== 1  && hourDifference <= 10) ? `${hourDifference} ${t("h")}` :` ${hourDifference} ${t("uneHeure")}`}  {' '}
              {`  (${expirationDate} )`}
            </div>) : (
            <div className="rest-period">
            {t("votre")} {isSlice ? t("offre") : t("abonnement actuel")} {t("prend fin dans")} 
                  {restDays == 2 && i18n.language === 'ar' ? t("DeuxJours") : (restDays !== 1 && restDays !== 0 && restDays <= 10) ? `${restDays} ${t("jours")}` :` ${t("UnJour")}` } {' '}
            {`  (${expirationDate} )`}
          </div>
            )}
            <BorderLinearProgress value={progressValue} variant="determinate" />
          </div>
          <div className="warning-actions">
            <Link className="upgrade-button" to='/offres'>
              {' '}
              {t("upgrade plan")}{' '}
            </Link>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

Index.propTypes = {

};

export default Index;
