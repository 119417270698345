import { createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';
import {closeModal} from "./modals";

const slice = createSlice({
  name: 'subjects',
  initialState: {
    exams: [],
    subjects: [],
    isLoading: false,
    error: false,
    exam: [],
    startedAt: null,
    dueDate: null,
    alertMessage: null,
    startMessage: '',
    isStarted: false,
    isStartExamLoading: false,
    limitationMessage: '',
    isExamStarted: false,
    isFileLoading: false,
    isFileDeleting: false,
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    startFileLoading: (state) => {
      state.isFileLoading = true;
    },
    startDeleteFile: (state) => {
      state.isFileDeleting = true;
    },
    startExamLoading: (state) => {
      state.isStartExamLoading = true;
      state.startMessage = '';
    },
    hasError: (state, action) => {
      state.error = true;
      state.alertMessage = action.payload?.response?.data?.message;
      state.isLoading = false;
    },
    examsSuccess: (state, action) => {
      state.exams = action.payload.payload.contents;
      state.subjects = action.payload.payload.subjects;
      state.isLoading = false;
    },
    examSuccess: (state, action) => {
      state.exam = action.payload.data.payload;
      state.isLoading = false;
      state.isFileDeleting = false;
      state.error = false;
      state.alertMessage = "Success";
    },
    examDeletingSuccess: (state, action) => {
      state.isFileDeleting = true;
      state.alertMessage = action.payload.message;
    },
    examLimitationSuccess: (state, action) => {
      state.startedAt = action.payload.data?.payload?.contentExamStudentTimelimitation?.started_at;
      state.dueDate = action.payload.data?.payload?.contentExamStudentTimelimitation?.due_date;
      state.isExamStarted = action.payload.data?.payload?.isExamStarted
      state.isLoading = false;
      state.error = false;
      state.alertMessage = "Success";
    },
    examLimitationError: (state, action) => {
      state.isLoading = false;
      state.limitationMessage = action.payload?.response?.data?.message;
      state.isExamStarted = action.payload?.response?.data?.payload?.isExamStarted
    },

    startExamSuccess :  (state, action) => {
      state.startMessage = action.payload?.data?.message;
      state.startedAt = action.payload?.data?.payload?.started_at;
      state.dueDate = action?.payload?.data?.payload?.due_date;
      state.isStartExamLoading = false;
      state.isExamStarted = true;
      state.isStarted = true;
    },

    startExamError :  (state, action) => {
      state.startMessage = action.payload?.response?.data?.message;
      state.isStartExamLoading = false;
      state.isExamStarted = false;
      state.isStarted = false;
    },
    uploadFilesSuccess: (state, action) => {
      state.alertMessage = action.payload.data.message;
      state.error = false;
      state.isFileLoading = false;
    },
  },
});
export const {
  startLoading,
  hasError,
  examsSuccess,
  examSuccess,
  examLimitationSuccess,
  examLimitationError,
  startExamSuccess,
  startExamError,
  startExamLoading,
  startFileLoading,
  uploadFilesSuccess,
  startDeleteFile,
  examDeletingSuccess
} = slice.actions;
export default slice.reducer;
export const getExams = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.get('/student/content-exam').then((response) => dispatch(examsSuccess(response.data)));
  } catch (e) {
    return dispatch(hasError(e));
  }
};
export const getExam = (id) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api
      .get(`/student/content-exam/${id}`)
      .then((response) => dispatch(examSuccess(response)));
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};
export const getExamTimeLimitation = (id) => async (dispatch) => {
  dispatch(startExamLoading());
  try {
    await api
        .get(`/student/content-exam-student-time-limitation/${id}`)
        .then((response) => dispatch(examLimitationSuccess(response)));
  } catch (e) {
    return dispatch(examLimitationError(e));
  }
};
export const startExam = (contentExam) => async (dispatch) => {
  dispatch(startExamLoading());
  try {
    await api
        .post(`/student/content-exam-student-time-limitation`, { contentExam })
        .then((response) => dispatch(startExamSuccess(response)));
    dispatch(closeModal('confirm-start-exam-modal'));
  } catch (e) {
    return dispatch(startExamError(e));
  }
};
export const uploadFiles = (examId, data) => async (dispatch) => {
  dispatch(startFileLoading());
  try {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const formData = new FormData();
    data.map((file, index) => {
      formData.append(`contentFiles[${index}][file]`, file);
    });
    await api.post(`student/content-exam/${examId}`, formData, config).then((response) => {
      dispatch(uploadFilesSuccess(response));
      dispatch(getExam(examId));
    });
  } catch (e) {
    return dispatch(hasError(e));
  }
};

export const removeStudentHomework = (contentId, fileId) => async (dispatch) => {
  dispatch(startDeleteFile());
  try {
    await api.delete(`student/content-exam/${contentId}/${fileId}`).then((response) => {
      dispatch(examDeletingSuccess(response.data));
      dispatch(getExam(contentId));
    });
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};
