import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Index = ({ label, icon, className, url, onClickAction }) => {
  return (
    <Link
      to={url ? url : '#'}
      className={`action-link${className ? ' ' + className : ''}`}
      onClick={onClickAction || null}
    >
      <span>{label}</span>
      {icon && icon}
    </Link>
  );
};

Index.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.any,
  className: PropTypes.string,
  url: PropTypes.string,
};

export default Index;
