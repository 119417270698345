import React, { useState, useEffect, useRef } from 'react';
import openImg from '../../../assets/img/icons/arrow-dropdown.svg';
import TextError from '../TextError';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import './_index.scss';
import { ErrorMessage, Field } from 'formik';
import { useTranslation } from 'react-i18next';

const Select = ({
  name,
  label,
  placeholder,
  required,
  options,
  error,
  disabled,
  className,
  icon,
}) => {
  const [open, setOpen] = useState(false);
  const [checked, setchecked] = useState('');

  const { i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    setOpen(false);
  }, [checked]);

  const refSelect = useRef();
  const refOpen = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !refSelect?.current?.contains(event.target) &&
        !refOpen?.current?.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [refSelect, refOpen]);

  return (
    <div className={`select-wrapper ${className}`}>
      {label && (
        <>
          <label htmlFor={name} className={language === 'ar' && 'arabic-label'}>
            {required &&
              (language === 'ar' ? (
                <>
                  <span className="required-field">*</span> <p>{label}</p>
                </>
              ) : (
                <>
                  <p>{label}</p> <span className="required-field">*</span>
                </>
              ))}
          </label>
        </>
      )}

      <div
        className={`select ${error && 'field-error-style'} ${disabled && 'disabled-select'}`}
        onClick={() => (!disabled ? setOpen(!open) : null)}
      >
        {language !== 'ar' ? (
          <>
            <div className="select-placeholder">
              {icon && <img src={icon} alt="icon" />}
              <span className="selected-option-value">{checked || placeholder}</span>
            </div>
            <img
              ref={refOpen}
              src={openImg}
              alt="open-icon"
              className={!disabled && open ? 'active select-img' : 'select-img'}
              onClick={() => (!disabled ? setOpen(!open) : null)}
            />
          </>
        ) : (
          <>
            <img
              ref={refOpen}
              src={openImg}
              alt="open-icon"
              className={!disabled && open ? 'active select-img' : 'select-img'}
              onClick={() => (!disabled ? setOpen(!open) : null)}
            />
            <div className="select-placeholder" onClick={() => (!disabled ? setOpen(!open) : null)}>
              {icon && <img src={icon} alt="icon" />}
              <span className="selected-option-value">{checked || placeholder}</span>
            </div>
          </>
        )}
      </div>
      {error && error?.trim().length > 0 ? (
        <p className={language === 'ar' ? 'arabic-error  error' : 'error'}>
          {language === 'ar' ? (
            <>
              {error} <ReportProblemIcon />
            </>
          ) : (
            <>
              <ReportProblemIcon /> {error}
            </>
          )}
        </p>
      ) : (
        <ErrorMessage component={TextError} name={name} />
      )}
      <div
        ref={refSelect}
        className={
          !disabled && open && options.length < 1
            ? 'open-select select-options no-options-list'
            : !disabled && open
            ? 'open-select select-options'
            : 'closed-select select-options'
        }
      >
        <span className="select-label">{label}</span>

        {options.length > 0 ? (
          <div>
            <Field id={name} name={name}>
              {({ field }) => {
                return options.map((option, key) => {
                  field.value == option.id && setchecked(option.name);

                  return (
                    <div
                      className="radio-btn"
                      key={option.id}
                      onClick={() =>
                        document.querySelector('#' + 'a' + option.name.replace(/\s+/g, '')).click()
                      }
                    >
                      <label
                        className={` ${
                          field.value == option.id
                            ? 'checked-btn custom-radio-btn'
                            : 'custom-radio-btn'
                        } `}
                      >
                        <input
                          type="radio"
                          label={label}
                          id={'a' + option.name.replace(/\s+/g, '')}
                          {...field}
                          value={+option.id}
                          checked={field.value == option.id}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="option-name" htmlFor={option.name}>
                        {option.name}
                      </label>
                    </div>
                  );
                });
              }}
            </Field>
            {error && error?.trim().length > 0 ? (
              <p className={language === 'ar' ? 'arabic-error  error' : 'error'}>
                {language === 'ar' ? (
                  <>
                    {error} <ReportProblemIcon />
                  </>
                ) : (
                  <>
                    <ReportProblemIcon /> {error}
                  </>
                )}
              </p>
            ) : (
              <ErrorMessage component={TextError} name={name} />
            )}
          </div>
        ) : (
          <span className="no-options">No options</span>
        )}
      </div>
    </div>
  );
};

export default Select;
