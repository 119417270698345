import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Chip from '@material-ui/core/Chip';
import { useTranslation } from 'react-i18next';

export const ContainerItems = ({ item, options, handleDrawerToggle, mobileOpen }) => {
  const { t } = useTranslation();
  const [isRelated, setIsRelated] = useState(false);

  const currentUrl = window.location.pathname;

  useEffect(() => {
    setIsRelated(item?.related_urls?.includes(currentUrl));
  }, [currentUrl]);

  function renderContent() {
    return (
      <>
        <div className="title">
          <img alt={item.name} src={item.icon} />
          <span>{t(item.name)}</span>
        </div>
        {options && (
          <Chip
            color={options.badge ? 'secondary' : 'default'}
            size="small"
            label={options.label}
          />
        )}
      </>
    );
  }

  return (
    <li>
      {item.link ? (
        <a href={item.url} target="_blank">
          {renderContent()}
        </a>
      ) : (
        <NavLink
          className={isRelated ? 'active' : ''}
          to={item.url}
          exact={true}
          onClick={() => {
            if (mobileOpen === true) {
              handleDrawerToggle();
            }
          }}
        >
          {renderContent()}
        </NavLink>
      )}
    </li>
  );
};
