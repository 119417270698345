import React from 'react';
import { useTranslation } from 'react-i18next';

const Index = () => {
  const { t } = useTranslation();
  return (
    <div className="loader-notification">
      <div className="spinner"></div>
      <span>{t('Loading, please wait')}</span>
    </div>
  );
};

export default Index;
