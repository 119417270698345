import React, { useEffect, useState } from 'react';
import medalImg from '../../assets/img/icons/Silver Medal.svg';
import couponImg from '../../assets/img/icons/coupon.svg';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parrainImg from '../../assets/img/parrain.png';
import { generateGiftCode } from '../../redux/slices/parrain';
import { me } from '../../redux/slices/user';
import Cart from './components/Cart';
import { Box } from '@material-ui/core';
import ParrainInfoCard from './components/ParrainInfoCard';
import ProductsList from './components/ProductsList';
import { CircularProgress } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import { useWindowSize } from '../../hooks/useWindowSize';
import NotFound from '../NotFound';

const Parrain = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const { settings } = useSettings();

  const dispatch = useDispatch();

  const { informations, permissions } = useSelector((state) => state.user);
  const { generateGiftCodeStatus } = useSelector((state) => state.parrain);

  const windowSize = useWindowSize();

  const alignCards =
    windowSize <= 1480 || (settings.sidebar === 'full' && windowSize <= 1630 && windowSize >= 600);

  useEffect(() => {
    if (!informations) {
      dispatch(me());
    }
  }, [informations, permissions]);

  const generateCodeHandler = () => {
    if (!informations?.gift_code) {
      dispatch(generateGiftCode());
    }
  };

  useEffect(() => {
    if (permissions?.eligibleUserForGift === true && informations?.gift_code) {
      window.location.href = '/gift-code';
    }
  }, [informations, permissions]);

  return permissions?.eligibleUserForGift === true ? (
    <div
      className={`${alignCards && 'parrain-page-full-sidebar'} ${
        language === 'ar' ? 'parrain-page parrain-page-arabic' : 'parrain-page'
      }`}
    >
      <Cart />

      <Box
        className={
          settings.sidebar === 'full' ? 'parrain-page-content-full-sidebar' : 'parrain-page-content'
        }
      >
        <Box className="parrain-page-left-cards">
          <Card className="parrain-page-parrain-card">
            <CardContent className="parrain-page-parrain-card-content">
              <Box className="parrain-page-parrain-left-card">
                <div className="parrain-page-parrain-card-header">
                  <div className="parrain-page-parrain-card-icon">
                    <img src={medalImg} alt="medal-img" />
                  </div>
                  <div className="parrain-page-parrain-card-title">{t('Parrainage')}</div>
                </div>
                <Box className="parrain-page-parrain-left-card-content">
                  <div className="parrain-page-left-card-img">
                    <img src={parrainImg} alt="parrain-img" />
                  </div>
                  <div className="parrain-page-left-card-text">
                    {t(
                      "Participez au parrainage et invitez vos amis à nous rejoindre pour bénéficier de la meilleure éducation en ligne avec TakiAcademy tout en gagnant des points de parrainage Non seulement vous pouvez améliorer votre niveau scolaire avec l'accompagnement de TakiAcademy mais vous pouvez également convertir vos points pour obtenir des cadeaux précieux"
                    )}
                  </div>
                </Box>
              </Box>
              <Box className="parrain-page-parrain-right-card">
                <div className="parrain-page-parrain-card-content">
                  <div className="parrain-page-parrain-card-content-icon">
                    <img src={couponImg} alt="coupon-img" />
                  </div>
                  <div className="parrain-page-parrain-card-content-text">
                    {t(
                      "Présentez à vos camarades étudiants l'opportunité de bénéficier d'une réduction sur l'offre de leur choix en utilisant votre code de parrainage lorsqu' ils s'inscrivent à notre programme de parrainage"
                    )}
                  </div>
                  <div className="parrain-page-parrain-card-content-generate-code-btn">
                    <button onClick={() => generateCodeHandler()}>
                      {generateGiftCodeStatus === 'loading' ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        t('Generate')
                      )}
                    </button>
                  </div>
                </div>
              </Box>
            </CardContent>
          </Card>

          {!alignCards && <ProductsList seeMore={informations?.gift_code} />}

          {alignCards && (
            <Box className="parraing-gift-code-page-products-and-info-card">
              <ProductsList seeMore={informations?.gift_code} />
              <ParrainInfoCard />
            </Box>
          )}
        </Box>
        {!alignCards && <ParrainInfoCard />}
      </Box>
    </div>
  ) : (
    <NotFound />
  );
};

export default Parrain;
