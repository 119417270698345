import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { verifyEmail } from '../../../redux/slices/sessions';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {Dialog} from "../../index";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const EmailModal = ({id, open, handleClose, data, ...rest}) => {
  const { i18n,t } = useTranslation();
  const { code, codeError } = useSelector((state) => state.sessions);
  const dispatch = useDispatch();
  const [value, setCode] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const successMessage = code && t(code?.message);
  const successCode = code && code?.code;

  const handleSubmit = () => {
    dispatch(verifyEmail(value, data?.email));
    setOpenSnackBar(true);
  };
  return (
      <>
        <Dialog
            open={open}
            handleClose={(e, reason) => {
              if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                handleClose(id);
              }
            }}
            title={t('Entrez le code reçu par email')}
            component={''}
            onSubmitAction={(event) => handleSubmit(event)}
        >
          <div className="ta-form">
            <FormControl variant="outlined">
              <InputLabel htmlFor="code" className="fix-display">
                {t("Entrez votre code")}
              </InputLabel>
              <TextField
                  id="email"
                  type="string"
                  variant="outlined"
                  size="small"
                  value={value}
                  onChange={(e) => setCode(e.target.value)}
              />
            </FormControl>
          </div>
        </Dialog>
      {
        successMessage || codeError &&
          <Snackbar
          open={openSnackBar}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'Bottom',
            horizontal: 'center',
          }}
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert
            severity={successCode === 200 ? 'success' : 'warning'}
            onClose={() => setOpenSnackBar(false)}
          >
            {successMessage ? t(successMessage) : t(codeError)}
          </Alert>
        </Snackbar>
     
      }
    </>
  );
};

export default EmailModal;
