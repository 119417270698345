import React, { useEffect, useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import arrowIcon from '../../../../assets/img/icons/white-arrow.svg';

import 'keen-slider/keen-slider.min.css';
import ProductCard from '../ProductCard';
import './_index.scss';
import useSettings from '../../../../hooks/useSettings';
import {
  parrainSliderBeakpointsFullSidebar,
  parrainSliderBreakpoints,
  giftCodeSliderBeakpointsFullSidebar,
  giftCodeSliderBreakpoints,
} from '../../../../utilities/constants';
import { useTranslation } from 'react-i18next';

const sliderComponent = (time) => [
  (slider) => {
    let timeout;
    let mouseOver = false;
    function clearNextTimeout() {
      clearTimeout(timeout);
    }
    function nextTimeout() {
      clearTimeout(timeout);
      if (mouseOver) return;
      timeout = setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        slider?.next();
      }, time || 4000);
    }
    slider.on('created', () => {
      slider.container.addEventListener('mouseover', () => {
        mouseOver = true;
        clearNextTimeout();
      });
      slider.container.addEventListener('mouseout', () => {
        mouseOver = false;
        nextTimeout();
      });
      nextTimeout();
    });
    slider.on('dragStarted', clearNextTimeout);
    slider.on('animationEnded', nextTimeout);
    slider.on('updated', nextTimeout);
  },
];

function ProductsSlider({ data }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [breakpoints, setBreakpoints] = useState(false);
  const [fullSidebarBreakPoints, setFullSidebarBreakPoints] = useState(false);

  const { settings } = useSettings();
  const { t, i18n } = useTranslation();

  const { pathname } = window.location;

  useEffect(() => {
    if (pathname?.includes('generate-gift-code')) {
      setBreakpoints(parrainSliderBreakpoints);
      setFullSidebarBreakPoints(parrainSliderBeakpointsFullSidebar);
    } else {
      setBreakpoints(giftCodeSliderBreakpoints);
      setFullSidebarBreakPoints(giftCodeSliderBeakpointsFullSidebar);
    }
  }, [pathname]);

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },

      loop: true,

      slides: {
        perView: 3.6,
      },
      breakpoints: settings.sidebar === 'full' ? fullSidebarBreakPoints : breakpoints,
      spacing: 5,
      created() {
        setLoaded(true);
      },
    },
    sliderComponent(4000)
  );

  return (
    <div
      className={
        settings.sidebar === 'full'
          ? 'products-slider products-slider-full-sidebar'
          : 'products-slider'
      }
      data-aos="fade-up"
    >
      {data?.length > 0 && (
        <>
          {loaded && instanceRef.current && (
            <Arrow
              left
              onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
              lang={i18n.language}
            />
          )}
          <div className="products-slider-list-container">
            <div
              className={
                settings.sidebar === 'full'
                  ? 'navigation-wrapper-full-sidebar'
                  : 'navigation-wrapper'
              }
            >
              <div ref={sliderRef} className="keen-slider">
                {data?.map((product, index) => (
                  <div key={product?.id} className={`keen-slider__slide number-slide${index}`}>
                    <ProductCard
                      name={product?.name}
                      coins={product?.amount}
                      img={
                        product?.gift_images?.find((el) => el?.is_cover)?.file_url ||
                        product?.gift_images?.[0]?.file_url
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          {loaded && instanceRef.current && (
            <Arrow
              onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
              lang={i18n.language}
            />
          )}
        </>
      )}
    </div>
  );
}

export default ProductsSlider;

function Arrow({ left, onClick, lang }) {
  return (
    <div
      onClick={onClick}
      className={`${
        lang === 'ar' ? (left ? '' : 'rotate-arrow') : left ? 'rotate-arrow' : ''
      } arrow-image`}
    >
      <img src={arrowIcon} alt="arrow" />
    </div>
  );
}
