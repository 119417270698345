import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Pdf from '../../../assets/img/icons/pdf.svg';
import roundDownload from '../../../assets/img/roundDownloadBtn.svg';
import preview from '../../../assets/img/eye.svg';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import PdfPreview from "../../PdfPreview";

const AttachmentsSession = ({ file }) => {
  const { t } = useTranslation();
    const [pdfPreview, setPdfPreview] = useState(false);
    const { name, url } = file;

    const handlePreviewPdf = () => {
      setPdfPreview(!pdfPreview)
    }

  return (
      <>
    <div className="attachment">
      <div className="attachmentTitle">
        <img src={Pdf} />
        <p className="attachmentName"> {name.length > 15 ? name.substr(0, 15) + '...' : name} </p>
      </div>
        <div className="attachmentButton downloadBtn">
        <img src={roundDownload} className="downloadIcon" alt="icon" />
          <a href={url} target="_blank" rel="noopener noreferrer">
              <p className="buttonText">{t("Télécharger")}</p>
          </a>
        </div>
        <div className="attachmentButton previewBtn" onClick={handlePreviewPdf}>
        <img src={preview} className="downloadIcon" alt="icon"/>
        </div>
    </div>
        <PdfPreview url={url} open={pdfPreview} setOpen={setPdfPreview} title={name} />
      </>
  );
};
export default AttachmentsSession;
