import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';
import { enqueueSnackbar } from './notifier';
import i18n from '../../services/i18n';
import { me } from './user';

const initialState = {
  status: 'idle',
  allGiftsStatus: 'idle',
  generatedGiftsStatus: 'idle',
  generatedGiftsHistoryStatus: 'idle',
  generatedGiftsHistory: [],
  getGiftByIdStatus: 'idle',
  addGiftCodeStatus: 'idle',
  deleteGiftCodeStatus: 'idle',
  generateGiftCodeStatus: 'idle',
  purchaseHistoryStatus: 'idle',
  currentGiftRequestsStatus: 'idle',
  deleteGiftRequestItemStatus: 'idle',
  conversionRateStatus: 'idle',
  addGiftRequestItemStatus: 'idle',
  addGiftCodeSuccess: null,
  addGiftCodeError: null,
  deleteGiftCodeSuccess: null,
  deleteGiftCodeError: null,
  gift: {},
  giftCode: '',
  allGifts: [],
  generatedGifts: [],
  currentGiftRequests: [],
  currentGiftID: null,
  conversionRate: null,
  allGiftsTotal: null,
  purchaseHistory: [],
};

export const generateGiftCode = createAsyncThunk(
  'parrain/generateGiftCode',
  async (_, thunkAPI) => {
    let data;
    try {
      const response = await api.get(`/student/generate-gift-code`);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: i18n.t(data?.message),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            },
          })
        );
        window.location.href = '/gift-code';
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: i18n.t(err?.response?.data?.message),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        })
      );

      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const getAllGifts = createAsyncThunk('parrain/getAllGifts', async ({ page, perPage }) => {
  let data;
  try {
    const response = await api.get(
      `/student/gifts?page=${page}&orderBy=id&sortedBy=desc&perPage=${perPage}`
    );
    data = await response.data;
    if (response.status === 200) {
      return { data: data.payload, meta: data.meta };
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const getGiftById = createAsyncThunk('parrain/getGiftById', async (giftId) => {
  let data;
  try {
    const response = await api.get(`/student/gifts/${giftId}`);
    data = await response.data;
    if (response.status === 200) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const getGeneratedGift = createAsyncThunk('parrain/getGeneratedGift', async () => {
  let data;
  try {
    const response = await api.get(`/student/generated-gifts`);

    data = await response.data;
    if (response.status === 200) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});
export const getGeneratedGiftsHistory = createAsyncThunk(
  'parrain/getGeneratedGiftsHistory',
  async () => {
    let data;
    try {
      const response = await api.get(`/student/history/generated-gifts`);

      data = await response.data;
      if (response.status === 200) {
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const giftRequestDelivery = createAsyncThunk(
  'parrain/giftRequestDelivery',
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await api.post(`/student/gift-requests-delivery`, body);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: i18n.t(data?.message),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            },
          })
        );
        window.location.href = '/gift-code';
        return data.data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: i18n.t(err?.response?.data?.message),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        })
      );

      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getCurrentGiftRequests = createAsyncThunk(
  'parrain/getCurrentGiftRequests',
  async () => {
    let data;
    const currentUrl = window.location.pathname;
    try {
      const response = await api.get(`/student/gift-requests/current`);
      data = await response.data;
      if (response.status === 200) {
        if (
          currentUrl === '/parrain/confirm-order' &&
          data?.payload?.gift_request_items?.length === 0
        ) {
          window.location.href = '/parrain/empty-cart';
        }
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      if (currentUrl === '/parrain/confirm-order' && err?.response?.data?.code == '404') {
        window.location.href = '/parrain/empty-cart';
      }
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const addGiftRequestItem = createAsyncThunk(
  'parrain/addGiftRequestItem',
  async (gift, thunkAPI) => {
    let data;
    try {
      const response = await api.post(`/student/gift-request-items`, { gift });
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(getCurrentGiftRequests());
        thunkAPI.dispatch(me());
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: i18n.t(data?.message),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            },
          })
        );
        return data.data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: i18n.t(err?.response?.data?.message),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        })
      );

      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deleteGiftRequestItem = createAsyncThunk(
  'parrain/deleteGiftRequestItem',
  async (giftRequestItemId, thunkAPI) => {
    let data;
    try {
      const response = await api.delete(`/student/gift-request-items/${giftRequestItemId}`);
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(getCurrentGiftRequests());
        thunkAPI.dispatch(me());

        thunkAPI.dispatch(
          enqueueSnackbar({
            message: i18n.t(data?.message),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            },
          })
        );
        return data.data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: i18n.t(err?.response?.data?.message),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        })
      );

      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getConversionRateGiftPoint = createAsyncThunk(
  'parrain/getConversionRateGiftPoint',
  async (I) => {
    let data;
    try {
      const response = await api.get(`/student/gift-requests/conversion-rate-gift-point`);
      data = await response.data;
      if (response.status === 200) {
        return data.message;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const convertGiftPoints = createAsyncThunk(
  'parrain/convertGiftPoints',
  async (giftPoint, thunkAPI) => {
    let data;
    try {
      const response = await api.post(`/student/gift-requests/convert-gift-point`, {
        giftPoint,
      });
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(me());
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: i18n.t(data?.message),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            },
          })
        );
        return data.data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: i18n.t(err?.response?.data?.message),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        })
      );

      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const addGiftCode = createAsyncThunk('parrain/addGiftCode', async (code, thunkAPI) => {
  let data;
  try {
    const response = await api.post(`/student/generated-gifts`, { code });
    data = await response.data;

    if (response.status === 200) {
      thunkAPI.dispatch(getGeneratedGift());

      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return err?.response?.data?.message;
  }
});
export const deleteGiftCode = createAsyncThunk('parrain/deleteGiftCode', async (code, thunkAPI) => {
  let data;
  try {
    const response = await api.delete(`/student/generated-gifts/${code}`);
    data = await response.data;
    if (response.status === 200) {
      thunkAPI.dispatch(getGeneratedGift());

      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return err?.response?.data?.message;
  }
});

export const getPurchaseHistory = createAsyncThunk('parrain/getPurchaseHistory', async () => {
  let data;
  try {
    const response = await api.get(`/student/gift-requests`);
    data = await response.data;
    if (response.status === 200) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: 'parrain',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(giftRequestDelivery.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(giftRequestDelivery.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(giftRequestDelivery.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(generateGiftCode.pending, (state) => {
        state.generateGiftCodeStatus = 'loading';
      })
      .addCase(generateGiftCode.fulfilled, (state, action) => {
        state.generateGiftCodeStatus = 'succeeded';
        state.giftCode = action.payload.gift_code;
      })
      .addCase(generateGiftCode.rejected, (state, action) => {
        state.generateGiftCodeStatus = 'failed';
      })

      .addCase(getGeneratedGift.pending, (state) => {
        state.generatedGiftsStatus = 'loading';
      })
      .addCase(getGeneratedGift.fulfilled, (state, action) => {
        state.generatedGiftsStatus = 'succeeded';
        state.generatedGifts = action.payload;
      })
      .addCase(getGeneratedGift.rejected, (state, action) => {
        state.generatedGiftsStatus = 'failed';
        state.generatedGifts = [];
      })
      .addCase(getGeneratedGiftsHistory.pending, (state) => {
        state.generatedGiftsHistoryStatus = 'loading';
      })
      .addCase(getGeneratedGiftsHistory.fulfilled, (state, action) => {
        state.generatedGiftsHistoryStatus = 'succeeded';
        state.generatedGiftsHistory = action.payload.reverse();
      })
      .addCase(getGeneratedGiftsHistory.rejected, (state, action) => {
        state.generatedGiftsHistoryStatus = 'failed';
      })
      .addCase(getAllGifts.pending, (state) => {
        state.allGiftsStatus = 'loading';
      })
      .addCase(getAllGifts.fulfilled, (state, action) => {
        state.allGiftsStatus = 'succeeded';
        state.allGifts = action.payload.data;
        state.allGiftsTotal = action.payload.meta.total;
      })
      .addCase(getAllGifts.rejected, (state, action) => {
        state.allGiftsStatus = 'failed';
      })
      .addCase(getPurchaseHistory.pending, (state) => {
        state.purchaseHistoryStatus = 'loading';
      })
      .addCase(getPurchaseHistory.fulfilled, (state, action) => {
        state.purchaseHistoryStatus = 'succeeded';

        state.purchaseHistory = action.payload.reverse();
      })
      .addCase(getPurchaseHistory.rejected, (state, action) => {
        state.purchaseHistoryStatus = 'failed';
      })
      .addCase(getGiftById.pending, (state) => {
        state.getGiftByIdStatus = 'loading';
      })
      .addCase(getGiftById.fulfilled, (state, action) => {
        state.getGiftByIdStatus = 'succeeded';
        state.gift = action.payload;
      })
      .addCase(getGiftById.rejected, (state, action) => {
        state.getGiftByIdStatus = 'failed';
      })

      .addCase(addGiftRequestItem.pending, (state) => {
        state.addGiftRequestItemStatus = 'loading';
      })
      .addCase(addGiftRequestItem.fulfilled, (state, action) => {
        state.addGiftRequestItemStatus = 'succeeded';
      })
      .addCase(addGiftRequestItem.rejected, (state, action) => {
        state.addGiftRequestItemStatus = 'failed';
      })
      .addCase(getCurrentGiftRequests.pending, (state) => {
        state.currentGiftRequestsStatus = 'loading';
      })
      .addCase(getCurrentGiftRequests.fulfilled, (state, action) => {
        state.currentGiftRequestsStatus = 'succeeded';
        state.currentGiftRequests = action.payload.gift_request_items;
        state.currentGiftID = action.payload?.id;
      })
      .addCase(getCurrentGiftRequests.rejected, (state, action) => {
        state.currentGiftRequestsStatus = 'failed';
      })
      .addCase(deleteGiftRequestItem.pending, (state) => {
        state.deleteGiftRequestItemStatus = 'loading';
      })
      .addCase(deleteGiftRequestItem.fulfilled, (state, action) => {
        state.deleteGiftRequestItemStatus = 'succeeded';
      })
      .addCase(deleteGiftRequestItem.rejected, (state, action) => {
        state.deleteGiftRequestItemStatus = 'failed';
      })
      .addCase(getConversionRateGiftPoint.pending, (state) => {
        state.conversionRateStatus = 'loading';
      })
      .addCase(getConversionRateGiftPoint.fulfilled, (state, action) => {
        state.conversionRateStatus = 'succeeded';
        state.conversionRate = action.payload;
      })
      .addCase(getConversionRateGiftPoint.rejected, (state, action) => {
        state.conversionRateStatus = 'failed';
      })
      .addCase(convertGiftPoints.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(convertGiftPoints.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(convertGiftPoints.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(addGiftCode.pending, (state) => {
        state.addGiftCodeStatus = 'loading';
        state.addGiftCodeError = '';
      })
      .addCase(addGiftCode.fulfilled, (state, action) => {
        state.addGiftCodeStatus = 'succeeded';
        state.addGiftCodeSuccess = '';

        if (action?.payload?.message) {
          state.addGiftCodeSuccess = action?.payload?.message;
          state.addGiftCodeError = '';
        } else {
          state.addGiftCodeError = action?.payload;
          state.addGiftCodeSuccess = '';
        }
      })
      .addCase(addGiftCode.rejected, (state, action) => {
        state.addGiftCodeStatus = 'failed';
        state.addGiftCodeError = '';
        state.addGiftCodeSuccess = '';
      })
      .addCase(deleteGiftCode.pending, (state) => {
        state.deleteGiftCodeStatus = 'loading';
      })
      .addCase(deleteGiftCode.fulfilled, (state, action) => {
        state.deleteGiftCodeStatus = 'succeeded';
        state.deleteGiftCodeSuccess = action?.payload?.data?.message;
      })
      .addCase(deleteGiftCode.rejected, (state, action) => {
        state.deleteGiftCodeStatus = 'failed';
        state.deleteGiftCodeError = action?.payload;
      });
  },
});
// Reducer
export default slice.reducer;
