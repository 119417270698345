import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {transferPoints} from '../../../redux/slices/points';
import { useDispatch } from 'react-redux';
import {enqueueSnackbar} from '../../../redux/slices/notifier';
import jwt_decode from "jwt-decode";
import {Dialog, DialogContent} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";

const TransferPoints = ({ id, open, handleClose, data, ...rest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
    const [toUser, setUserId] = useState('');
  const [points, setPoints] = useState();
  const [openSnackBar, setOpenSnackBar] = useState(false);
   const token = localStorage.getItem('token');
    const decoded = jwt_decode(token);
    const admin = decoded.hasOwnProperty('adminId') ? decoded?.adminId : null


  const handleSubmit = () => {
    if (!toUser  || !points) {
    dispatch(
        enqueueSnackbar({
          message: t('Remplir les champs'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        }),
    );
    return;
  }
  if (points < 10 || points > 5000) {
    dispatch(
        enqueueSnackbar({
          message: t('Veuillez entrer un montant entre 10 et 5000'),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
          },
        }),
    );
    return;
  }
    dispatch(transferPoints(toUser, points, admin));
      setOpenSnackBar(true);
      setUserId('');
      setPoints('');
  };
  return (
    <>
        <Dialog
            open={open}
            onClose={(e, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    handleClose(id);
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="ta-modal add-video-modal"
        >
            <DialogTitle id="alert-dialog-title">
                <span className="label"> {t('Transférer des points')}</span>
                <span className="close-btn" onClick={() => handleClose(id)}>
                  {t("Fermer")}
                </span>
            </DialogTitle>
            <DialogContent className="ta-modal-content-scroll">
                <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
                {/*   <div className={i18n.language === 'ar' ? 'rtl' : 'basic'}>*/}
                  <TextField
                      id="userId"
                      autoFocus
                      placeholder={t("identifiant du bénéficiaire")}
                      fullWidth
                      size="small"
                      type="string"
                      value={toUser}
                      margin="dense"
                      variant="outlined"
                      className="input"
                      onChange={(e) => setUserId(e.target.value)}
                  />
                  <TextField
                      autoFocus
                      margin="dense"
                      fullWidth
                      size="small"
                      id="point"
                      value={points}
                      placeholder={t("montant")}
                      type="number"
                      variant="outlined"
                      className="input"
                      onChange={(e) => setPoints(e.target.value)}
                  />
                <Box mt={2} className="ta-btns-group rtl">
                      <Button
                          onClick={(event) => handleSubmit(event)}
                          className="ta-btn primary btn-rounded btn-xl"
                      >
                          {t("Transférer")}
                      </Button>
                  </Box>
                </form>
          </DialogContent>
      </Dialog>
    </>

  );
};

export default TransferPoints;
