import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
const QcmFrame = (props) => {
  const { data } = props;
  const { informations } = useSelector((state) => state.user);
  const username = informations?.name + ' ' + informations?.last_name;
  const childIframeRef = useRef(null);
  function handleWeQuizIFrameLoad() {
    const message = 'let me pass please';
    const targetOrigin = process.env.REACT_APP_QCM_URL;
    childIframeRef.current.contentWindow.postMessage(message, targetOrigin);
  }
  return (
      <div>
        {data && (
            <div
                className="interact_responsive_padding"
                style={{
                  width: '100%',
                  padding: '68.8% 0 0 0',
                  position: 'relative',
                  marginBottom: '5px',
                }}
            >
              <div
                  className="interact_responsive_wrapper"
                  style={{
                    height: '100%',
                    left: '0',
                    position: 'absolute',
                    top: '0',
                    width: '100%',
                  }}
              >
                <iframe
                    width={800}
                    height={800}
                    ref={childIframeRef}
                    allowTransparency="true"
                    frameBorder={0}
                    onLoad={handleWeQuizIFrameLoad}
                    id={`interactApp${data.content_qcm_id}`}
                    width="100%"
                    height="100%"
                    style={{ border: 'none', maxWidth: '100%', margin: '0' }}
                    allowTransparency="true"
                    frameBorder="0"
                    src={`${process.env.REACT_APP_QCM_URL}/embedded/quiz/${data.content_qcm_id}?token=${process.env.REACT_APP_QCM_API_TOKEN}&&email=${informations?.phone}&&username=${username}`}
                />
              </div>
            </div>
        )}
      </div>
  );
};
QcmFrame.propTypes = {
  data: PropTypes.object,
};
export default QcmFrame;