import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BlueButton from '../../BlueButton';
import Typography from '@material-ui/core/Typography';
import AttachmentsSession from './AttachmentsSession';

const HessatyModal = (props) => {
  const { i18n,t } = useTranslation();
  const {
    name,
    subject,
    startDate,
    description,
    teacher,
    levels,
    event,
    files,
    chapterHessaty,
    comingContentHessaty,
    depassedContentHessaty,
      componentProps
  } = props.componentProps;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={i18n.language === 'ar' ? 'sessionModal rtl' : 'sessionModal'}>
      <BlueButton text={subject.name} width={subject.name.length*7>=60?subject.name.length*7:70} height={'100%'} fontSize={10} />

      <div className="hessaty-description">
        {t("Ce module")} <strong className="description-text"> {event?.traineeship?.name}</strong>
      </div>
      <div className="hessaty-event-details">
        <div className="hessaty-description">
          {t("Contient")} <strong className="description-text"> {event?.traineeship?.totalSeances} </strong>
          {t("Séance en directe")}
        </div>
        <div className="hessaty-price">
          {t("Prix")} :{' '}
          <Typography variant="h8" className="price">
            {`${event?.traineeship?.price} ${t('TND')}`}
          </Typography>
        </div>
      </div>
      <div className="hessaty-description" style={{ fontWeight: 'bold' }}>
        {t("Séances Restantes")} : <strong className="description-text"> {event?.comingContentHessaty}</strong>
      </div>
      <div className="hessaty-description" style={{ fontWeight: 'bold', marginBottom: 5 }}>
        {t("Séances Depassées")} :{' '}
        <strong className="description-text depassed-event"> {event?.depassedContentHessaty}</strong>
      </div>
      <div className="modalLine"></div>
      <div className="sessionInfo">
        <Typography variant="h8" className="infoSpan">
          {t(" Instructeur")} 
          <Typography variant="h8" className="infoValue">
            {teacher}
          </Typography>
        </Typography>
        <Typography variant="h8" className="infoSpan">
          {t(" Niveaux")} 
          <Typography variant="h8" className="levels">
            {t(levels)}
          </Typography>
        </Typography>
      </div>
      <Typography variant="h8" className="infoSpan">
        {t(" heure")} 
        <Typography variant="h8" className="infoValue">
          {event.start_time}
        </Typography>
      </Typography>
      {/* <div className="sessionDocuments">
        {files.length === true && (
          <Typography variant="h7" className="sessionDescription">
            Travail a faire :
          </Typography>
        )}
        {files.map((file) => {
          return <AttachmentsSession file={file} />;
        })}
      </div> */}
    </div>
  );
};

export default HessatyModal;
