import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// Slice
const slice = createSlice({
  name: 'popups',
  initialState: {
    popups: [],
    isPopupsLoading: false,
    error: false,
  },
  reducers: {
    startLoading: (state) => {
      state.isPopupsLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isPopupsLoading = false;
    },
    popupsSuccess: (state, action) => {
      state.popups = action.payload;
      state.isPopupsLoading = false;
      state.popup = state.popups[state.popups.length - 1];
    },
  },
});
export default slice.reducer;
// Actions
const { popupsSuccess, startLoading, hasError } = slice.actions;
window.NOTIF_URL = process.env.REACT_APP_NOTIF_URL;

export const getPopups = (user_id) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await axios
      .get(`${window.NOTIF_URL}/getPopups?user_id=${user_id}`)
      .then((response) => dispatch(popupsSuccess(Array.isArray(response.data)? response.data : [])));
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};
export const hidePopup = (popup_id, user_id) => (dispatch) =>
  axios
    .get(`${window.NOTIF_URL}/popup/hide`, {
      params: {
        popup_id,
        user_id,
      },
    })
    .then(() => {})
    .catch((error) => {
      throw error;
    });
