import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ExamDownloadButton from './ExamDownloadButton';
import FileDropeZone from '../../components/FileDropeZone';
import BlueButton from '../../components/BlueButton';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getExam,
  getExamTimeLimitation,
  removeStudentHomework,
  uploadFiles,
} from '../../redux/slices/exams';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import WindowDimensions from '../../utilities/windowDimenssions';
import * as dayjs from 'dayjs';
import Im_Clock from '../../assets/img/clock.png';

const Index = () => {
  const { i18n, t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const {
    exam,
    isLoading,
    isFileDeleting,
    alertMessage,
    startedAt,
    dueDate,
    isExamStarted,
    isFileLoading,
    error,
  } = useSelector((state) => state.exams);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const { width } = WindowDimensions();
  const currentDate = dayjs();
  const [countdownTimer, setCountdownTimer] = useState('00:00:00');
  const [isCountEnd, setIsCountEnd] = useState(false);
  const [dueDateUser, setDueDate] = useState(null);

  useEffect(() => {
    dispatch(getExam(id));
    dispatch(getExamTimeLimitation(id));
    if (isExamStarted) {
      setDueDate(dueDate);
    }
  }, [id, dispatch]);
  useEffect(() => {
    if (isExamStarted) {
      setDueDate(dueDate);
    }
  }, [isExamStarted, dueDate]);

  const formatDiff = (distance) => {
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    let pad = function (n) {
      return n < 10 ? '0' + n : n;
    };

    return [days, pad(hours), pad(minutes), pad(seconds)].join(':');
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      if (dueDateUser != null) {
        setCountdownTimer(formatDiff(dayjs(dueDateUser).diff(dayjs())));
        if (dayjs(dueDateUser).diff(dayjs()) <= 1000) {
          clearInterval(timerId);
          setCountdownTimer('00:00:00');
          setIsCountEnd(true);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, [dueDateUser]);

  const handleSubmitFiles = async () => {
    if (!isFileLoading) {
      await dispatch(uploadFiles(id, files));
      setOpenSnackBar(true);
      setFiles([]);
    }
  };
  const handleDelete = async (contentId, fileId) => {
    await dispatch(removeStudentHomework(contentId, fileId));
    setOpenSnackBar(true);
  };
  if (isLoading && !isFileDeleting) {
    return (
      <div>
        <Skeleton variant="rect" height={500} />
      </div>
    );
  }
  return (
    <Card className={i18n.language === 'ar' ? 'ExamDetailsContainer rtl' : 'ExamDetailsContainer'}>
      <CardContent className="cardContent">
        <div className="cardHeader">
          <Typography style={{ color: '#2ba7df' }}> {t('Détails de l&apos;examen')} </Typography>
        </div>
        <div className="card">
          <Card className="customCard">
            {exam?.contentExam ? (
              <CardContent style={{ width: width - 100 }}>
                <div className="bloc-header">
                  <div className="col-left">
                    <BlueButton
                      text={exam.contentExam?.subject_exams[0]?.subject?.name}
                      width={100}
                      fontSize={12}
                    />
                    <Typography className="examTitle">{exam.contentExam?.content?.name}</Typography>
                    <Typography className="teacherName">
                      {t('Par')} :{' '}
                      {exam.contentExam?.content?.instructor?.name +
                        ' ' +
                        exam.contentExam?.content?.instructor?.last_name}
                    </Typography>
                  </div>
                  {isExamStarted && exam?.contentExam?.duration && dayjs(dueDate) > currentDate && (
                    <div className="col-right">
                      <span className="modal-btn">
                        <img alt="imClock" src={Im_Clock} />
                        <div>
                          <span className="secondary-text">
                            {isCountEnd ? t('Temps écoulé') : t('Temps restant')}
                          </span>
                          <span className="primary-text">{countdownTimer}</span>
                        </div>
                      </span>
                    </div>
                  )}
                </div>
                <Typography className="examDate">
                  {t('Start date')} :{' '}
                  {startedAt
                    ? dayjs(startedAt).format('DD/MM/YYYY HH:mm')
                    : !startedAt && exam?.expired
                    ? dayjs(exam.contentExam?.appearance_date).format('DD/MM/YYYY HH:mm')
                    : t('Exam not started yet')}
                </Typography>
                <Typography className="examDate">
                  {t('Date de fin')} :{' '}
                  {dueDate
                    ? dayjs(dueDate).format('DD/MM/YYYY HH:mm')
                    : dayjs(exam.contentExam?.due_date).format('DD/MM/YYYY HH:mm')}
                </Typography>
                <Typography className="text">{t('Examen')}</Typography>
                {exam.fileExam &&
                  exam.fileExam.map((homework, index) => {
                    return (
                      <ExamDownloadButton
                        text={homework?.file?.name}
                        link={homework?.file?.url}
                        key={homework.id}
                        isExpired={exam?.expired}
                        isPopup={true}
                        dueDate={dayjs(exam.contentExam?.due_date)}
                      />
                    );
                  })}
                {isExamStarted && dayjs(dueDate) > currentDate && (
                  <div>
                    <Typography className="text">{t('Travail à faire')}</Typography>
                    <FileDropeZone setFiles={setFiles} />
                  </div>
                )}
                {files.length !== 0 && (
                  <div className="submitFiles" onClick={handleSubmitFiles}>
                    <BlueButton
                      text={t('soumettre')}
                      width="25%"
                      float="center"
                      isLoading={isFileLoading}
                      loadingText={t('loading')}
                    />
                  </div>
                )}
                {exam.fileHomework && exam.fileHomework.length !== 0 && (
                  <>
                    <Typography className="text">{t('Mon Travail')} </Typography>
                    {exam.fileHomework.map((studentHomework) => {
                      return (
                        <ExamDownloadButton
                          homework={!exam?.expired}
                          onClick={() => handleDelete(id, studentHomework.id)}
                          text={studentHomework?.file?.name}
                          link={studentHomework?.file?.url}
                          key={studentHomework?.file?.id}
                        />
                      );
                    })}
                  </>
                )}
                {exam.fileHomeWorkCorrection && exam.fileHomeWorkCorrection.length !== 0 && (
                  <>
                    <Typography className="text">{t('Correction')} </Typography>
                    {exam.fileHomeWorkCorrection.map((studentSolution, index) => {
                      return (
                        <ExamDownloadButton
                          text={studentSolution?.file?.name}
                          link={studentSolution?.file?.url}
                          key={index}
                        />
                      );
                    })}
                  </>
                )}
                {exam.fileExamCorrection &&
                  exam.fileExamCorrection.length !== 0 &&
                  exam?.expired && (
                    <div>
                      <Typography className="text">{t('Correction générale')}</Typography>
                      {exam.fileExamCorrection.map((solution, index) => {
                        return (
                          <ExamDownloadButton
                            text={solution?.file?.name}
                            link={solution.file?.url}
                            key={index}
                          />
                        );
                      })}
                    </div>
                  )}
              </CardContent>
            ) : (
              <></>
            )}
          </Card>
          <Snackbar
            open={openSnackBar}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: 'Bottom',
              horizontal: 'center',
            }}
            onClose={() => setOpenSnackBar(false)}
          >
            <Alert severity={error ? 'warning' : 'success'} onClose={() => setOpenSnackBar(false)}>
              {t(alertMessage)}
            </Alert>
          </Snackbar>
        </div>
      </CardContent>
    </Card>
  );
};

export default Index;
