import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const ForumActionLink = ({ label, icon, className, url, onClickAction, forum, hasSilver }) => {
  const { t } = useTranslation();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [open, openModal] = React.useState(false);
  const openAccessModal = () => {
    setOpenSnackBar(true);
  };
  const onCloseModal = () => {
    openModal(false);
  };
  return (
    <div>
      {forum ? (
        <a
          href="https://forum.takiacademy.com"
          target="_blank"
          className={`action-link${className ? ' ' + className : ''}`}
        >
          <span>{label}</span>
          {icon && icon}
        </a>
      ) : (
        <Link
          to={url ? url : '#'}
          className={`action-link${className ? ' ' + className : ''}`}
          onClick={openAccessModal}
        >
          <span>{label}</span>
          {icon && icon}
        </Link>
      )}
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity="warning" onClose={() => setOpenSnackBar(false)}>
          {hasSilver == false ? t('Vous ne pouvez pas accèder au forum') : t('Pour profiter du forum veuillez souscrire à Silver+ ou Gold pour toute l année')}
        </Alert>
      </Snackbar>
    </div>
  );
};

ForumActionLink.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.any,
  className: PropTypes.string,
  url: PropTypes.string,
};

export default ForumActionLink;
