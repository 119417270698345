import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/slices/auth';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Avatar from '@material-ui/core/Avatar';
import { ActionLink } from '../../components';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkOutlined';
import FlagOutlinedIcon from '@material-ui/icons/Flag';

import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import { DivisionsReformat } from '../../utilities/methods';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ReactHtmlParser from 'html-react-parser';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  dropdown: {
    marginLeft: '8px',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
}));

const Index = ({ name, lastName, division, avatar }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { hasParent, hasParentRequest } = useSelector((state) => state.user);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.dropdown + ' menu-dropdown'}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Avatar alt={name} src={avatar} id="img-preview" />
        <div className={'texts ' + classes.sectionDesktop}>
          <span className="title">{lastName + ' ' + name}</span>
          <span
            className="subtitle"
            dangerouslySetInnerHTML={{
              __html: `${DivisionsReformat(division)}`,
            }}
          ></span>
        </div>
        <div className="icon-holder">
          <KeyboardArrowDownIcon />
        </div>
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              marginTop: '12px',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={handleClose} component={Link} to="/mon-profil">
                    <ActionLink
                      label={t('Mon profil')}
                      icon={<AccountBoxIcon />}
                      className="text-muted full-width"
                      url="/mon-profil"
                    />
                  </MenuItem>
                  <MenuItem onClick={handleClose} component={Link} to="/liste-de-favoris">
                    <ActionLink
                      label={t('Liste de favoris')}
                      icon={<BookmarkOutlinedIcon />}
                      className="text-muted full-width"
                      url="/liste-de-favoris"
                    />
                  </MenuItem>
                  {hasParent && (
                    <MenuItem
                      onClick={handleClose}
                      component={Link}
                      to="/liste-des-demandes-parentales"
                    >
                      <ActionLink
                        label={t('Demandes parentales')}
                        icon={<ScheduleSendIcon />}
                        className={
                          hasParentRequest
                            ? 'text-muted full-width parent-request'
                            : 'text-muted full-width'
                        }
                        url="/liste-des-demandes-parentales"
                      />
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleClose} component={Link} to="/reports">
                    <ActionLink
                        label={t('Mes rapports')}
                        icon={<FlagOutlinedIcon />}
                        className="text-muted full-width"
                        url="/reports"
                    />
                  </MenuItem>
                  <MenuItem onClick={handleClose} component={Link} to="/historique">
                    <ActionLink
                      label={t('Historique')}
                      icon={<HistoryOutlinedIcon />}
                      className="text-muted full-width"
                      url="/historique"
                    />
                  </MenuItem>
                  <MenuItem onClick={() => dispatch(logout())} component={Link} to="#">
                    <ActionLink
                      label={t('Déconnecter')}
                      icon={<ExitToAppIcon />}
                      className="text-muted full-width"
                      url="#"
                    />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

Index.propTypes = {
  name: PropTypes.string,
  division: PropTypes.string,
  avatar: PropTypes.string,
  dispatch: PropTypes.func,
  classes: PropTypes.any,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  anchorRef: PropTypes.any,
  handleToggle: PropTypes.func,
  handleClose: PropTypes.func,
  handleListKeyDown: PropTypes.func,
  prevOpen: PropTypes.any,
};

export default Index;
