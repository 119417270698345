import React, { useState, useEffect, useRef } from 'react';
import openImg from '../../../../assets/img/icons/arrow.svg';
import TextError from '../TextError';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import './_index.scss';
import { ErrorMessage, Field } from 'formik';

const Select = ({
  name,
  label,
  placeholder,
  required,
  options,
  error,
  disabled,
  className,
  icon,
  checked,
  setchecked,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [checked]);

  const refSelect = useRef();
  const refOpen = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !refSelect?.current?.contains(event.target) &&
        !refOpen?.current?.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [refSelect, refOpen]);

  return (
    <div className="ooredoo-select-field">
      {label && (
        <>
          <label htmlFor={name}>
            {required && (
              <>
                <p>{label}</p> <span className="required-field">*</span>
              </>
            )}
          </label>
        </>
      )}

      <div
        className={`select ${error && 'field-error-style'} ${disabled && 'disabled-select'}`}
        onClick={() => (!disabled ? setOpen(!open) : null)}
      >
        <>
          <div className="select-placeholder" onClick={() => (!disabled ? setOpen(!open) : null)}>
            {icon && <img src={icon} alt="icon" />}
            <span className="selected-option-value">{checked || placeholder}</span>
          </div>
          <img
            ref={refOpen}
            src={openImg}
            alt="open-icon"
            className={!disabled && open ? 'active select-img' : 'select-img'}
            onClick={() => (!disabled ? setOpen(!open) : null)}
          />
        </>
      </div>
      {error ? (
        <p className={'error'}>
          <ReportProblemIcon /> {error}
        </p>
      ) : (
        <ErrorMessage component={TextError} name={name} />
      )}
      <div
        ref={refSelect}
        className={
          !disabled && open && options.length < 1
            ? 'open-select select-options no-options-list'
            : !disabled && open
            ? 'open-select select-options'
            : 'closed-select select-options'
        }
      >
        {options.length > 0 ? (
          <div>
            <Field id={name} name={name}>
              {({ field, form }) => {
                const handleOptionClick = (option) => {
                  form.setFieldValue(name, option.id);
                  setchecked(option.name);
                };

                return options.map((option, key) => {
                  const isChecked = field.value === option.id;
                  const inputId = 'a' + option.name.replace(/\s+/g, '');

                  return (
                    <div
                      className="radio-btn"
                      key={option.id}
                      onClick={() => handleOptionClick(option)}
                    >
                      <label
                        className={`${
                          isChecked ? 'checked-btn custom-radio-btn' : 'custom-radio-btn'
                        }`}
                      >
                        <input
                          type="radio"
                          label={label}
                          id={inputId}
                          {...field}
                          value={+option.id}
                          checked={isChecked}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="option-name" htmlFor={inputId}>
                        {option.name}
                      </label>
                    </div>
                  );
                });
              }}
            </Field>
          </div>
        ) : (
          <span className="no-options">No options</span>
        )}
      </div>
    </div>
  );
};

export default Select;
