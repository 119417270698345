import React, { useState } from 'react';
import { ViewTitle } from '../../components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getAllParentRequests } from '../../redux/slices/parentRequests';
import ParentRequestsResponsiveTable from '../../components/ResponsiveTable/ParentRequestsResponsiveTable';

const ParentRequests = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const { parentRequests } = useSelector((state) => state.parentRequests);

  React.useEffect(() => {
    dispatch(getAllParentRequests());
  }, []);

  React.useEffect(() => {
    if (parentRequests) {
      const parentRequestsData = parentRequests?.map((el) => ({
        ...el?.parent_user,
        parentID: el?.id,
        approved: el?.approved,
      }));

      setData(parentRequestsData || []);
    }
  }, [parentRequests]);

  const headers = [
    { accessor: 'name', label: t('Prénom') },
    { accessor: 'last_name', label: t('Nom') },
    { accessor: 'phone', label: t('Numéro de téléphone') },
    { accessor: 'email', label: t('Email') },
    { accessor: 'approved', label: t('Operation Status') },
  ];

  return (
    <div>
      <ViewTitle title={t('Liste des demandes parentales')} />
      <div className="ta-row wallet">
        <div className="ta-column right-margin">
          <div className="child-full">
            <ParentRequestsResponsiveTable cols={headers} rows={data || []} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentRequests;
