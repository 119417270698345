import { createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';
import { ord } from '../../utilities/methods';

// Slice
const slice = createSlice({
  name: 'subjects',
  initialState: {
    subjects: null,
    optional_subjects: null,
    isLoading: false,
    isOptionalSubjectLoading: false,
    error: false,
    subjects_progress: null,
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    SubjectSuccess: (state, action) => {
      const filtered_data = action.payload.payload
        .filter((item) => item.visible === true)
        .sort((a, b) => (parseInt(a.position) > parseInt(b.position) ? 1 : -1));
      const options = [
        { label: 'id', accessor: 'id' },
        { label: 'o_id', accessor: 'o_id' },
        { label: 'label', accessor: 'name' },
        { label: 'slug', accessor: 'slug' },
        { label: 'img', accessor: 'thumbnail' },
        { label: 'progress', accessor: 'progress' },
        { label: 'color', accessor: 'color' },
        { label: 'active', accessor: 'visible' },
        { label: 'position', accessor: 'position' },
        { label: 'subjectVideos', accessor: 'subjectVideos' },
      ];
      state.subjects = ord(filtered_data, options);
      state.isLoading = false;
    },
    SubjectsSuccess: (state, action) => {
      const filtered_data = action.payload?.data?.payload
        .filter((item) => item.visible === true)
        .sort((a, b) => (parseInt(a.position) > parseInt(b.position) ? 1 : -1))
        .slice(0, 5);
      const options = [
        { label: 'id', accessor: 'id' },
        { label: 'o_id', accessor: 'o_id' },
        { label: 'label', accessor: 'name' },
        { label: 'slug', accessor: 'slug' },
        { label: 'img', accessor: 'thumbnail' },
        { label: 'progress', accessor: 'progress' },
        { label: 'color', accessor: 'color' },
        { label: 'subjectVideos', accessor: 'subjectVideos' },
      ];
      state.subjects_progress = ord(filtered_data, options);
    },
    startOptionLoading: (state) => {
      state.isOptionalSubjectLoading = true;
    },
    OptionalSubjectSuccess: (state, action) => {
      state.optional_subjects = action.payload.payload;
      state.isOptionalSubjectLoading = false;
    },
  },
});
export default slice.reducer;
// Actions
const {
  SubjectSuccess,
  startLoading,
  hasError,
  SubjectsSuccess,
  startOptionLoading,
  OptionalSubjectSuccess,
} = slice.actions;

export const getSubjects = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.get('/student/subjects').then((response) => dispatch(SubjectSuccess(response.data)));
  } catch (e) {
    if (e?.response?.status === 401) {
      const adminID = localStorage.getItem('admin_user_id');
      if (adminID && adminID.includes('?redirectTo=RegisterPage')) {
        window.location = 'https://education-autrement.takiacademy.com/register';
        localStorage.removeItem('admin_user_id');
      } else {
        localStorage.clear();
        window.location = '/login';
      }
    }
    return dispatch(hasError(e.message));
  }
};

export const getOptionalSubjects = (division_id) => async (dispatch) => {
  dispatch(startOptionLoading());
  await api
    .get(`/optional-subjects/${division_id}`)
    .then((response) => {
      dispatch(OptionalSubjectSuccess(response.data));
    })
    .catch((e) => {
      if (e.response) {
        let message = '';
        let key;
        if (e.response.data.errors) {
          for (key in e.response.data.errors) {
            message = e.response.data.errors[key][0];
          }
        } else {
          message = e.response.data.message;
        }
        return dispatch(hasError(message));
      }
    });
};

export const getSubjectsWithProgress = () => async (dispatch) => {
  try {
    const res = await api.get('/student/subjects');
    dispatch(SubjectsSuccess(res));
  } catch (e) {
    return console.error(e.message);
  }
};
