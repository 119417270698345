import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import download from '../../assets/img/icons/download.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { openModal } from '../../redux/slices/modals';
import dayjs from 'dayjs';

const ExamDownloadButton = (props) => {
  const { homework, onClick, text, link, isPopup, dueDate } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isExamStarted } = useSelector((state) => state.exams);
  const currentDate = dayjs();

  const handleConfirmStartExam = () => {
    if (isPopup && !isExamStarted) {
      dispatch(openModal('confirm-start-exam-modal', { link, id }));
    } else {
      window.open(link, '_blank');
    }
  };
  return (
    <>
      <div className="examDownloadButton">
        <div className="buttonTitle">
          <Typography>{text}</Typography>
          {homework && <DeleteOutlineIcon onClick={onClick} />}
        </div>
        <div>
          {isExamStarted || dueDate < currentDate ? (
            <a href={link} rel="noreferrer" target="_blank" className="buttonLink">
              <img src={download} className="icon" alt="icon" />
              <div className="buttonText">{t('Télécharger')}</div>
            </a>
          ) : (
            <div onClick={handleConfirmStartExam} className="buttonLink">
              <img src={download} className="icon" alt="icon" />
              <div className="buttonText">{t('Télécharger')}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ExamDownloadButton;
