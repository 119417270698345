import { createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

//import Discourse from 'discourse-js';
const userApiKey = '42cd6c184563de1be914e63ebe9e16f79d6b958002d9ea0d23e0699d338c6268';
const apiUsername = 'ammar-samah';
const baseUrl = 'https://forum.takiacademy.com' || 'http://localhost:3000';
// Slice
const slice = createSlice({
  name: 'forum',
  initialState: {
    posts: [],
    isPostLoading: false,
    error: false,
  },
  reducers: {
    startLoading: (state) => {
      state.isPostLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isPostLoading = false;
    },
    PostSuccess: (state, action) => {
      state.posts = action.payload;
      state.isPostLoading = false;
    },
  },
});
export default slice.reducer;
// Actions
const { PostSuccess, startLoading, hasError } = slice.actions;

export const getPosts = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api.get(`/latest-posts`).then((response) => dispatch(PostSuccess(response.data?.payload?.topics)));
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};

// const discourse = new Discourse();
// discourse.config({ userApiKey, apiUsername, baseUrl })

// export const getPosts = () => async (dispatch) => {

// discourse.topics
//   .getTopic({
//     id : 10,
//     print: true
//   })
//   .then((res) => dispatch(PostSuccess(res)))
//   .catch(err => console.log(err));

// };
