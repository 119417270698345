import React, { useEffect } from 'react';
import { months } from '../../../../utilities/constants';
import './_index.scss';
import { useTranslation } from 'react-i18next';

const Months = ({ monthValue, setMonthValue, setOpenMonths, selectedDay }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    setMonthValue(selectedDay?.split(' ')[1]);
  }, []);

  return (
    <div className="calendar-months">
      {months.map((month, index) => (
        <span
          key={index}
          className={monthValue === month.value ? 'selected-month month' : 'month'}
          onClick={() => {
            setMonthValue(month?.value);
            setOpenMonths(false);
          }}
        >
          {t(month?.label)}
        </span>
      ))}
    </div>
  );
};

export default Months;
