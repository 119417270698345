import { createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

// Slice
const slice = createSlice({
  name: 'chapters',
  initialState: {
    chaptersSubject: null,
    chapters: null,
    chapterSections: null,
    isChaptersLoading: false,
    error: false,
  },
  reducers: {
    startLoading: (state) => {
      state.isChaptersLoading = true;
      state.chapters = [];
      state.chapterSections = [];
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isChaptersLoading = false;
    },
    ChapterSuccess: (state, action) => {

      state.chaptersSubject = action.payload.subject;
      state.chapters = action.payload.chapters;
      state.chapterSections = action.payload?.chapterSections
      state.isChaptersLoading = false;
    },
  },
});
export default slice.reducer;
// Actions
const { ChapterSuccess, startLoading, hasError } = slice.actions;

export const getChapters = (subject_slug) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api
      .get(`/student/subjects/${subject_slug}/chapters`)
      .then((response) => dispatch(ChapterSuccess(response.data.payload)));
  } catch (e) {
    if(e?.response?.status === 401){
      localStorage.clear();
       window.location = '/login';
    }
    return dispatch(hasError(e.message));
  }
};
