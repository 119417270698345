import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import { useHistory } from 'react-router-dom';
import pendingImg from '../../assets/img/icons/loading.svg';
import approuvedImg from '../../assets/img/icons/approuved.svg';
import rejectedImg from '../../assets/img/icons/cancel.svg';
import eyeImg from '../../assets/img/icons/blue-eye.svg';
import historyImg from '../../assets/img/icons/history.png';

import { Empty } from '../../components';

import './_purchaseHistoryTable.scss';

const PurchaseHistoryTable = ({ cols, rows, emptyComponent, className }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const getPurchaseHistoryByIdHandler = (item) => {
    history.push({
      pathname: `/parrain/purchase-history/${item?.id}`,
      state: {
        note: item?.note,
        gift_request_items: item?.gift_request_items,
        gift_request_deliveries: item?.gift_request_deliveries,
      },
    });
  };

  const purchaseHistoryRespoContEl = useRef(null);
  const purchaseHistoryTableContEl = useRef(null);
  const purchaseHistoryPaginContEl = useRef(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [tableContHeight, setTableContHeight] = useState(0);
  const [paginContHeight, setPaginContHeight] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const updateTableDemonsions = (taH, PaH) => {
    purchaseHistoryRespoContEl.current.style.height = `${taH + PaH - 16}px`;
    purchaseHistoryPaginContEl.current.style.marginTop = `${taH}px`;
  };

  useEffect(() => {
    setTableContHeight(purchaseHistoryTableContEl.current.clientHeight);
    setPaginContHeight(purchaseHistoryPaginContEl.current.clientHeight);
    updateTableDemonsions(tableContHeight, paginContHeight);
  });

  return (
    <div className={`table-responsive ${className}`} ref={purchaseHistoryRespoContEl}>
      <TableContainer
        className="table-container"
        ref={purchaseHistoryTableContEl}
        component={Paper}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {cols &&
                cols.length > 0 &&
                cols.map((col, index) => {
                  return <TableCell key={index}>{col.label}</TableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.length > 0 ? (
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {cols &&
                      cols.length > 0 &&
                      cols.map((col, index) => {
                        return (
                          <TableCell key={index}>
                            {index === 4 ? (
                              <div className={row?.status}>
                                <img
                                  src={
                                    row?.status === 'approuved'
                                      ? approuvedImg
                                      : row?.status === 'rejected'
                                      ? rejectedImg
                                      : pendingImg
                                  }
                                  alt={row?.status}
                                />
                                <p className="purchase-history-status"> {t(row[col.accessor])}</p>
                              </div>
                            ) : className === 'purchase-history' && index === 5 ? (
                              <div
                                className="purchase-history-action"
                                onClick={() => getPurchaseHistoryByIdHandler(row)}
                              >
                                <img src={eyeImg} alt="eye-img" />
                              </div>
                            ) : (
                              <div>{t(row[col.accessor])}</div>
                            )}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell colSpan={(cols && cols.length) || 1} align={'center'}>
                  {
                    (emptyComponent = (
                      <Empty
                        label={t('pas d historique')}
                        icon={historyImg}
                        className="no-borders"
                      />
                    ))
                  }
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        ref={purchaseHistoryPaginContEl}
        // rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows?.length || []}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t('Rows per page')}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')}  ${count}`}
      />
    </div>
  );
};

PurchaseHistoryTable.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
};

export default PurchaseHistoryTable;
