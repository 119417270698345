import React from 'react';
import { BasicModalFields } from '../..';
import DialogContent from '@material-ui/core/DialogContent';

const Index = ({type}) => {
  return (
    <div className="transfer">
      <DialogContent>
        <BasicModalFields type={'3'} />
      </DialogContent>
    </div>
  );
};

export default Index;
