import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { CardContent } from '@material-ui/core';

const Index = (props) => {
  const { video } = props;
  const youtubeId = video.link.split('/').pop();

  return (
    <div className="videoCard" >
      {video && (
        <div >
          <Card style={{marginLeft:"40px"}} className="root">
            <CardActionArea>
              <CardMedia className="assistance-card-img">
                <script src="capture-video-frame.js"></script>
                <img
                  src={`https://img.youtube.com/vi/${video.link.split('/').pop()}/mqdefault.jpg`}
                  className="card-img-top"
                  alt="..."
                />
              </CardMedia>
              <CardContent className="assistance-card-title">
                <Typography gutterBottom variant="h5" component="h2" className="assistance-title">
                  {video.title}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
      )}
    </div>
  );
};

Index.propTypes = {
  video: PropTypes.object,
};

export default Index;
