import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// Slice
const slice = createSlice({
  name: 'messages',
  initialState: {
    messages: [],
    isMessagesLoading: false,
    error: false,
    message: null,
    isMessageLoading: false,
    messageError: false,
  },
  reducers: {
    startLoading: (state) => {
      state.isMessagesLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isMessagesLoading = false;
    },
    messagesSuccess: (state, action) => {
      state.messages = action.payload;
      state.isMessagesLoading = false;
    },
    startMessageLoading: (state) => {
      state.isMessageLoading = true;
    },
    hasMessageError: (state, action) => {
      state.messageError = action.payload;
      state.isMessageLoading = false;
    },
    messageSuccess: (state, action) => {
      state.message = action.payload.messages;
      state.isMessageLoading = false;
    },
  },
});
export default slice.reducer;
// Actions
const {
  messagesSuccess,
  startLoading,
  hasError,
  messageSuccess,
  hasMessageError,
  startMessageLoading,
} = slice.actions;
window.NOTIF_URL = process.env.REACT_APP_NOTIF_URL;

export const getMessages = (user_id) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await axios
      .get(`${window.NOTIF_URL}/getMessages?user_id=${user_id}`)
      .then((response) => dispatch(messagesSuccess(Array.isArray(response.data)? response.data : [])));
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};

export const getMessage = (message_id, user_id) => async (dispatch) => {
  dispatch(startMessageLoading());
  try {
    await axios
      .get(`${window.NOTIF_URL}/message?user_id=${user_id}&&message_id=${message_id}`)
      .then((response) => {
      dispatch(messageSuccess(response.data));
      dispatch(getMessages(user_id));
    });
  } catch (e) {
    return dispatch(hasMessageError(e.message));
  }
};
