import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { SettingsProvider } from './contexts/SettingsContext';
import i18n from './services/i18n';
import store from './redux/store';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import TagManager from 'react-gtm-module';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_BUGSNAG_ENV,
  enabledReleaseStages: ['production', 'staging', 'local', 'development', 'test'],
});
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG,
};
TagManager.initialize(tagManagerArgs);

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <SettingsProvider>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </SettingsProvider>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
//serviceWorker.unregister();
