import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import api from '../../services/api';

// Slice
const slice = createSlice({
  name: 'progress',
  initialState: {
    subjectsProgress: 0,
    subjectProgress: 0,
    chaptersProgress: 0,
    chapterProgress: 0,
    courseProgress: 0,
    isSubjectsProgressLoading: false,
    isSubjectProgressLoading: false,
    isChaptersProgressLoading: false,
    isChapterProgressLoading: false,
    isCourseProgressLoading: false,
    error: false,
    freeDurationPercentage: 0,
  },
  reducers: {
    startSubjectsLoading: (state) => {
      state.isSubjectsProgressLoading = true;
    },
    startSubjectLoading: (state) => {
      state.isSubjectProgressLoading = true;
    },
    startChaptersLoading: (state) => {
      state.isChaptersProgressLoading = true;
    },
    startChapterLoading: (state) => {
      state.isChapterProgressLoading = true;
    },
    startCourseLoading: (state) => {
      state.isCourseProgressLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isSubjectsProgressLoading = false;
    },
    SubjectsProgressSuccess: (state, action) => {
      state.subjectsProgress = action.payload.data;
      state.isSubjectsProgressLoading = false;
    },
    SubjectProgressSuccess: (state, action) => {
      state.subjectProgress = action.payload.data;
      state.isSubjectProgressLoading = false;
    },
    chaptersProgressSuccess: (state, action) => {
      state.chaptersProgress = action.payload.data;
      state.isChaptersProgressLoading = false;
    },
    ChapterProgressSuccess: (state, action) => {
      state.chapterProgress = action.payload;
      state.isChapterProgressLoading = false;
    },
    CourseProgressSuccess: (state, action) => {
      state.courseProgress = action.payload.data;
      state.isCourseProgressLoading = false;
    },
    SavedProgress: (state, action) => {
      state.progressExpired = action.payload.data.expired;
    },
    FreeDurationPercentage: (state, action) => {
      state.freeDurationPercentage = action.payload.data.percentage;
    },
  },
});

//const analyticsUrl = process.env.REACT_APP_ANALYTICS_URL;
const analyticsUrl = process.env.REACT_APP_ANALYTICS_URL;

export default slice.reducer;
// Actions
const {
  SubjectsProgressSuccess,
  SubjectProgressSuccess,
  chaptersProgressSuccess,
  ChapterProgressSuccess,
  startSubjectsLoading,
  startSubjectLoading,
  startChaptersLoading,
  startChapterLoading,
  startCourseLoading,
  CourseProgressSuccess,
  hasError,
  SavedProgress,
  FreeDurationPercentage,
} = slice.actions;

export const saveProgress =
  ({ viewToken, content, chapter, subject, completed, totalWatched, watchedTime, isFree }) =>
  async (dispatch) => {
    try {
      //   debugger
      await api
        .post(`${analyticsUrl}/progress`, {
          view_token: viewToken,
          content,
          chapter,
          subject,
          completed,
          total_watched: totalWatched,
          watchedTime,
          isFree,
        })
        .then((response) => {
          if (response.data.expired) {
            dispatch(SavedProgress(response));
          }
        })
        .catch((e) => {
          //return console.error(e.message)
          //for double session
          if (e?.response?.status === 403) {
            const adminID = localStorage.getItem('admin_user_id');
            if (adminID && adminID.includes('?redirectTo=RegisterPage')) {
              window.location = 'https://education-autrement.takiacademy.com/register';
              localStorage.removeItem('admin_user_id');
            } else {
             // localStorage.clear();
             // window.location = '/login';
            }
          }
        });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const getSubjectsProgress = () => async (dispatch) => {
  dispatch(startSubjectsLoading());
  try {
    await api
      .get(`${analyticsUrl}/progress/subjects`)
      .then((response) => dispatch(SubjectsProgressSuccess(response.data)));
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};

export const getSubjectProgress = (subject_id) => async (dispatch) => {
  dispatch(startSubjectLoading());
  try {
    await api
      .get(`${analyticsUrl}/progress/subjects/${subject_id}`)
      .then((response) => dispatch(SubjectProgressSuccess(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSubjectChaptersProgress = (subject_id) => async (dispatch) => {
  dispatch(startChaptersLoading());
  try {
    await api
      .get(`${analyticsUrl}/progress/subjects/${subject_id}/chapters`)
      .then((response) => dispatch(chaptersProgressSuccess(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const getChapterProgress = (chapter_id) => async (dispatch) => {
  dispatch(startChapterLoading());
  try {
    await api
      .get(`${analyticsUrl}/progress/chapters/${chapter_id}`)
      .then((response) => dispatch(ChapterProgressSuccess(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const getChapterContentsProgress = (chapter_id, clearCache) => async (dispatch) => {
  dispatch(startCourseLoading());
  try {
    await api
      .get(
        `${analyticsUrl}/progress/chapters/${chapter_id}/contents${
          clearCache ? '?clearCache=1' : ''
        }`
      )
      .then((response) => dispatch(CourseProgressSuccess(response.data)));
    await api
      .get(`${analyticsUrl}/progress/chapters/${chapter_id}/contents`)
      .then((response) => dispatch(CourseProgressSuccess(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const getUserFreeDuration = (totalDuration) => async (dispatch) => {
  try {
    await api
      .get(`${analyticsUrl}/free-offer-duration/used-duration?duration=${totalDuration}`)
      .then((response) => dispatch(FreeDurationPercentage(response)));
  } catch (e) {
    return console.error(e.message);
  }
};
