import React, { useEffect, useState } from 'react';
import QcmFrame from '../players/QcmFrame';
import MagPlayer from '../players/MagPlayer';
import FreePlayer from '../players/FreePlayer';
import PaidPlayer from '../players/PaidPlayer';
import { LockedVideo, MessageModal } from '../..';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from '../../Modal/Modal';
import axios from 'axios';

const Index = (props) => {
  const {newcourseProgress } = props;
  const { newprogressExpired } = useSelector((state) => state.newProgress);
  const [open, openModal] = useState(false);
  const onCloseModal = () => {
    openModal(false);
    window.location = '/offres';
  };
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_QCM_API_URL}/login?token=${localStorage.getItem('token')}`)
      .then((response) => {
        localStorage.setItem('tokenQuiz', response.data.data.token);
        localStorage.setItem('UserQuizId', response.data.data._id);
      })
      .catch((error) => error);
  }, []);
  useEffect(() => {
    openModal(newprogressExpired);
  }, [newprogressExpired]);
  const { currentLesson, chapterId, courseError, chapter } = props;
  const data = currentLesson && currentLesson.content;
  const type = currentLesson && currentLesson.content?.type?.name.toUpperCase();
  const locked = currentLesson && currentLesson.locked;
  return (
    <div className="video-container">
      {locked === true || courseError !== false ? (
        <LockedVideo />
      ) : type == 'YOUTUBE VIDEO' ? (
        <FreePlayer
          data={data}
          chapterId={chapterId}
          chapter={chapter}
          newcourseProgress={newcourseProgress}
        />
      ) : type == 'CRYPTED VIDEO' || type == 'RECORDING' ? (
        <PaidPlayer
          data={data}
          currentLesson={currentLesson}
          chapterId={chapterId}
          chapter={chapter}
          newcourseProgress={newcourseProgress}
        />
      ) : type == 'MAGAZINE' ? (
        <MagPlayer data={currentLesson} />
      ) : type == 'QCM' ? (
        <QcmFrame data={data} />
      ) : (
        <LockedVideo />
      )}
      {newprogressExpired && (
        <Modal
          onCloseModal={onCloseModal}
          component={MessageModal}
          openModal={open}
          title={'انتهاء فترة الفيديوهات المجانية'}
          modalWidth={'sm'}
          data={
            'لقد انهيت كل الساعات المجانية , يجب عليك الاشتراك في أحد العروض لتتمكن من مشاهدة الفيديوات مرة اخرى  '
          }
          modalActionName="close"
        />
      )}
    </div>
  );
};

Index.propTypes = {
  currentLesson: PropTypes.object,
  firstLesson: PropTypes.object,
  update: PropTypes.bool,
};

export default Index;
