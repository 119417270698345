import React from 'react';
import PropTypes from 'prop-types';

const Index = ({ logoService, nameService, color }) => {
  return (
    <div className="text-center">
      <div className="context service-card">
        <div className={color}>
          <div className="logo-service">
            <img alt="logo-service" src={logoService} />
          </div>
        </div>
      </div>
      <div className="name-service">
        <p>{nameService}</p>
      </div>
    </div>
  );
};

Index.propTypes = {
  logoService: PropTypes.string,
  nameService: PropTypes.string,
  color: PropTypes.string,
};

export default Index;
