import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Im_Logo from '../../assets/img/logo.svg';
import Ramadhan_Logo from '../../assets/img/ramadhan.gif';
import Flag_Logo from '../../assets/img/onlyPalestine.gif';
import TA_Logo from '../../assets/img/icons/TA-logo.svg';

const Index = ({ handleDrawerToggle, mobileOpen }) => {
  const { t, i18n } = useTranslation();
  return (
    <Link
      to="/"
      className={i18n.language === 'ar' ? 'logo rtl' : 'logo'}
      onClick={() => {
        if (mobileOpen !== true) {
          return;
        }
        handleDrawerToggle();
      }}
    >
      <img alt="logo" src={TA_Logo} />
      {/*<span>{t('TakiAcademy')}</span>*/}
      {/*<img alt="logo" src={Flag_Logo} />*/}
      {/* <img alt="logo" src={Ramadhan_Logo} /> */}
    </Link>
  );
};

export default Index;
