import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ProductCard from '../ProductCard';
import './_index.scss';
import { getAllGifts } from '../../../../redux/slices/parrain';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import useSettings from '../../../../hooks/useSettings';
import ProductsSlider from '../ProductsSlider';
import { useWindowSize } from '../../../../hooks/useWindowSize';

const ProductsList = ({ seeMore }) => {
  const dispatch = useDispatch();
  const { allGifts, allGiftsStatus } = useSelector((state) => state.parrain);
  const { t, i18n } = useTranslation();
  const { settings } = useSettings();
  const windowSize = useWindowSize();

  useEffect(() => {
    if (allGifts?.length === 0 && allGiftsStatus === 'idle') {
      dispatch(getAllGifts({ page: 1, perPage: 5 }));
    }
  }, [allGifts]);
  const showSlider = windowSize <= 1761 || (settings.sidebar === 'full' && windowSize >= 600);

  return (
    <div className="parrain-page-products-list">
      <div
        className={
          settings.sidebar === 'full'
            ? 'parrain-page-products-list-card-header-full-sidebar'
            : 'parrain-page-products-list-card-header'
        }
      >
        <div
          className={
            seeMore
              ? 'parrain-page-products-list-card-title hide-see-more-btn'
              : 'parrain-page-products-list-card-title'
          }
        >
          {t('The available gifts')}
        </div>
        {seeMore && (
          <Link className="blue-btn" to={'/parrain/products'}>
            {t('See more')}
          </Link>
        )}
      </div>

      {allGifts?.length > 0 ? (
        !showSlider ? (
          <div>
            {allGifts?.slice(0, 5)?.map((product) => (
              <ProductCard
                name={product?.name}
                coins={product?.amount}
                img={
                  product?.gift_images?.find((el) => el?.is_cover)?.file_url ||
                  product?.gift_images?.[0]?.file_url
                }
                key={product?.id}
              />
            ))}
          </div>
        ) : (
          <ProductsSlider data={allGifts} />
        )
      ) : (
        <Typography>{t('No products found')}</Typography>
      )}
    </div>
  );
};

export default ProductsList;
