import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MenuItem from '@material-ui/core/MenuItem';
import ExamCard from './ExamCard';
import { useDispatch, useSelector } from 'react-redux';
import { getExams } from '../../redux/slices/exams';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import WindowDimensions from '../../utilities/windowDimenssions';
const useStyles = makeStyles((theme) => ({
  filterButton: {
    padding: 0,
  },
}));
const Index = () => {
  const { i18n,t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {width} = WindowDimensions();
  const [examFiltered, setExamFiltered] = useState([]);
  const { exams, subjects, isLoading } = useSelector((state) => state.exams);

  const { permissions } = useSelector((state) => state.user);
  useEffect(() => {
   // if(permissions && permissions.exam){
      dispatch(getExams());
   // }
  }, [dispatch]);
  useEffect(() => {
    setExamFiltered(exams);
  }, [exams]);
  const [filterText, setFilterText] = useState("TOUS");
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilter = (e, subject) => {
    if (subject?.id) {
      const newExam = exams.filter((exam) => {
        return exam?.subject_exams[0]?.subject.id === subject.id;
      });
      setExamFiltered(newExam);
      setFilterText(subject.name);
      setAnchorEl(null);
    }
    setAnchorEl(null);
  };

  const handleGetAllExams = (exams) => {
    setExamFiltered(exams);
    setFilterText(t("TOUS"));
  };

  if (isLoading) {
    return (
      <div>
        <br />
        <Skeleton variant="rect" height={500} />
      </div>
    );
  }
  return (
      <Card style={{maxWidth:width-50}}>
        <CardContent className="exam-card-content">
          <div className= { i18n.language === 'ar' ? 'card-header rtl' : 'card-header'} >
            <Typography style={{ color: '#2ba7df', fontSize: 19 }}>{t("Examens")}</Typography>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              className={classes.filterButton}
            >
              <Typography className="filterText">{t(filterText)}</Typography>
              <KeyboardArrowDownIcon />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleFilter}
            >
              <MenuItem onClick={() => handleGetAllExams(exams)}>{t("TOUS")}</MenuItem>
              {subjects &&
                subjects.map((subject) => {
                  return (
                    <MenuItem onClick={(event) => handleFilter(event, subject)} key={subject.id}>
                      {subject.name}
                    </MenuItem>
                  );
                })}
            </Menu>
          </div>
          <div className="cards">
            {examFiltered &&
              examFiltered.map((exam) => {
                return <ExamCard exam={exam} />;
              })}
          </div>
        </CardContent>
      </Card>
  );
};

export default Index;
