import { Box, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import closeImg from '../../../../assets/img/icons/Close.svg';
import './_index.scss';
import ConvertCoinsCard from './components/ConvertCoinsCard';
import { ConvertCoinsCards } from '../../../../utilities/constants';
import { useSelector } from 'react-redux';

const ConvertCoinsModal = ({ id, open, handleClose, data, ...rest }) => {
  const { i18n, t } = useTranslation();
  const { informations } = useSelector((state) => state.user);

  return (
    <>
      <Dialog
        open={open}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleClose(id);
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ta-modal convert-coins-modal"
      >
        <DialogTitle id="alert-dialog-title">
          <span className="close-btn" onClick={() => handleClose(id)}>
            <img src={closeImg} alt="close-icon" />
          </span>
        </DialogTitle>

        <DialogContent className="ta-modal-content-scroll">
          <p className="convert-coins-modal-description">
            {t(
              'Profite du service de parrainage et choisis parmi les récompenses ou les offres proposées sur la plateforme ce qui te convient grâce à ton solde de points et de jetons'
            )}
          </p>

          <Box mt={2} className="convert-coins-cards">
            {ConvertCoinsCards?.map((item, index) => (
              <ConvertCoinsCard
                img={item.img}
                title={item.title}
                description={item.description}
                key={index}
                link={
                  index === 1
                    ? informations?.gift_point > 0
                      ? item.link
                      : item?.noCoins
                    : item.link
                }
              />
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConvertCoinsModal;
