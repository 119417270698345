import React, { useEffect, useState } from 'react';
import ooredooLogo from '../../assets/img/icons/ORDS.png';
import playImg from '../../assets/img/icons/play.svg';
import './_index.scss';
import OfferCard from './components/offerCard';
import { Helmet } from 'react-helmet';
import YouTube from 'react-youtube';
import OptionCard from './components/optionCard';
import { useDispatch, useSelector } from 'react-redux';
import { getDivisionsByLevel, getSilverOffer } from '../../redux/slices/auth';
import { sortArrayByProperty } from '../../utilities/sortArrayByProperty';
import TakiacademyBrand from './components/takiacademyBrand';
import {
  optionsCards,
  offerCards,
  levelValues,
  ooredooPageSliderBreakpoints,
} from '../../utilities/constants';
import silverIcon from '../../assets/img/icons/Silver.png';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import SkeletonCard from './components/offerCard/SkeletonCard';

const studentLogin = process.env.REACT_APP_LOGIN_TAKIACADEMY_STUDENT_URL;
const studentRegister = process.env.REACT_APP_REGISTER_TAKIACADEMY_STUDENT_URL;

const Ooredoo = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [dotsNumber, setDotsNumber] = useState();

  useEffect(() => {
    window.location.href = 'https://www.takiacademy.com/offers/سيلفر-و-سيلفر-plus';
  }, []);

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slides: { perView: 3.8, spacing: 10 },
    breakpoints: ooredooPageSliderBreakpoints,
    rtl: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  const [sliderRefSkeleton, instanceRefSkeleton] = useKeenSlider({
    initial: 0,
    slides: { perView: 3.8, spacing: 10 },
    breakpoints: ooredooPageSliderBreakpoints,
    rtl: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const { silverOffers, silverOffersLoading } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState({ dailyPrice: '', weeklyPrice: '' });

  const dispatch = useDispatch();

  useEffect(() => {
    const perView = instanceRef?.current?.options?.slides?.perView;
    const offersLength = silverOffers?.length || 4;
    const pagesNumber = offersLength / perView;

    perView &&
      setDotsNumber(
        pagesNumber > 1.4 && pagesNumber < 3 ? Math.ceil(pagesNumber) + 1 : Math.ceil(pagesNumber)
      );
  }, [instanceRef?.current, instanceRef?.current?.options?.slides?.perView, silverOffers?.length]);

  const handleOfferLevel = (level, dailyPrice, weeklyPrice) => {
    setPrice({ dailyPrice, weeklyPrice });
    setOpen(true);
    dispatch(getDivisionsByLevel(level));
  };

  useEffect(() => {
    dispatch(getSilverOffer());
  }, []);
  const sortedOffers = silverOffers && sortArrayByProperty(silverOffers, levelValues, 'level');

  const handleClick = () => {
    setIsVideoVisible(!isVideoVisible);
  };
  const onReady = (event) => {
    if (isVideoVisible) {
      event.target.playVideo();
    }
  };

  return (
    <>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-630ND4CH29"
        strategy="afterInteractive"
      />
      <script id="google-analytics" strategy="afterInteractive">
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-630ND4CH29'); `}
      </script>
      <Helmet>
        <link rel="shortcut icon" href="/favicons/logo.ico" />

        <link rel="canonical" href="https://app.takiacademy.com/offers/ooredoo" />
        <meta name="keywords" content="عرض silver , ooredoo" />
        <title>طور مستواك الدراسي باستعمال رقم أوريدو | Takiacademy</title>
        <meta
          name="description"
          content="تقي أكاديمي, تمكّنكم من الاشتراك في عرض سيلفر باليوم أو بالأسبوع, يكفي يكون عندك رقم 𝗢𝗼𝗿𝗲𝗱𝗼𝗼  باش تتمتّع بالعرض وتقرى قدّ ما تحب ووقت ما تحب "
        />
        <meta proprety="og:title" content="طور مستواك الدراسي باستعمال رقم أوريدو | Takiacademy" />
        <meta
          proprety="og:description"
          content="تقي أكاديمي, تمكّنكم من الاشتراك في عرض سيلفر باليوم أو بالأسبوع, يكفي يكون عندك رقم 𝗢𝗼𝗿𝗲𝗱𝗼𝗼  باش تتمتّع بالعرض وتقرى قدّ ما تحب ووقت ما تحب "
        />
        <meta proprety="og:image" content={silverIcon} />

        <meta name="robots" content="index,follow" />
      </Helmet>
      <div className="ooredoo-page">
        <header className="ooredoo-page-header">
          <TakiacademyBrand />
          <div className="auth-btns">
            <a href={studentLogin} className="login-btn">
              <span>Connexion</span>
            </a>

            <a href={studentRegister} className="register-btn">
              <span> Inscription</span>
            </a>
          </div>
        </header>
        <div className="ooredoo-page-content">
          <div className="ooredoo-page-title">
            <h1>اِبدأ الآن في تطوير مستواك الدّراسي</h1>
            <span className="ooredoo-offer-description">
              <p>تابع دروسك في أي وقت ومن أي مكان في منصّة تقي أكاديمي</p>
              <span>
                <div className="ooredoo-logo">
                  <img src={ooredooLogo} alt="ooredoo logo" />
                </div>
                <p>باستعمال رقم </p>
              </span>
            </span>
          </div>

          {silverOffersLoading ? (
            <section className="ooredoo-offers-tablet">
              <div ref={sliderRefSkeleton} className="keen-slider">
                {[...Array(4).keys()]?.map((_) => (
                  <div className="keen-slider__slide">
                    <SkeletonCard />
                  </div>
                ))}
              </div>
              {
                <div className="dots">
                  {[...Array(dotsNumber).keys()].map((idx) => {
                    return (
                      <button
                        key={idx}
                        onClick={() => instanceRefSkeleton?.current?.moveToIdx(idx)}
                        className={'dot' + (currentSlide === idx ? ' active' : '')}
                      ></button>
                    );
                  })}
                </div>
              }
            </section>
          ) : (
            <section className="ooredoo-offers-tablet">
              <div ref={sliderRef} className="keen-slider">
                {sortedOffers?.map((offer) => (
                  <div className="keen-slider__slide">
                    <OfferCard
                      offer={offer}
                      offerCard={offerCards.find((el) => el.id === offer?.id)}
                      offerHandler={() =>
                        handleOfferLevel(offer?.level, offer?.dailyPrice, offer?.weeklyPrice)
                      }
                      open={open}
                      setOpen={setOpen}
                      price={price}
                    />
                  </div>
                ))}
              </div>
              {
                <div className="dots">
                  {[...Array(dotsNumber).keys()].map((idx) => {
                    return (
                      <button
                        key={idx}
                        onClick={() => instanceRef?.current?.moveToIdx(idx)}
                        className={'dot' + (currentSlide === idx ? ' active' : '')}
                      ></button>
                    );
                  })}
                </div>
              }
            </section>
          )}

          <section className="ooredoo-offers">
            {silverOffersLoading
              ? [...Array(4).keys()]?.map((_) => <SkeletonCard />)
              : sortedOffers?.map((offer) => (
                  <OfferCard
                    offer={offer}
                    offerCard={offerCards.find((el) => el.id === offer?.id)}
                    offerHandler={() =>
                      handleOfferLevel(offer?.level, offer?.dailyPrice, offer?.weeklyPrice)
                    }
                    open={open}
                    setOpen={setOpen}
                    price={price}
                  />
                ))}
          </section>
          <section className="ooredoo-subsription">
            <article className="who-is-takiacademy">
              <h3 className="title">ماهي تقي أكاديمي ؟</h3>

              <span className="who-is-takiacademy-description">
                <p>
                  تقي أكاديمي هي أوّل منصّة تعليميّة عبر الإنترنت في تونس (منذ 2013) موجّهة لتلامذة
                  الابتدائي والأساسي والثّانوي
                </p>
                <p>(من الرابعة ابتدائي إلى البكالوريا)</p>
                <p className="takiacademy-offer">
                  نقدّم دروس دعم في شكل فيديوهات مسجّلة لشرح كلّ دروس البرنامج الدّراسي و حصص مباشرة
                  تفاعليّة تحت إشراف ثلّة من الأساتذة والمربّين من ذوي الخبرة من كامل أنحاء
                  الجمهوريّة.
                </p>
              </span>
            </article>
            <div className="ooredoo-how-to-subscribe">
              <h3 className="title">كيفيّة الاشتراك ؟</h3>

              <button className={` ${isVideoVisible ? 'hidden' : ''}`} onClick={handleClick}>
                <div className="video-img">
                  <div className="play-btn">
                    <img src={playImg} alt="video play button" />
                  </div>
                </div>
              </button>
              {isVideoVisible && (
                <YouTube
                  videoId="6l2N1y8EoEg"
                  opts={{ playerVars: { modestbranding: 1 } }}
                  onReady={onReady}
                  containerClassName="youtube-video-container"
                  className="youtube-video"
                />
              )}
            </div>
          </section>
          <div className="ooredoo-offer-details">
            {optionsCards.map((option) => (
              <OptionCard option={option} />
            ))}
          </div>
          <div className="ooredoo-offer-subsribe-now">
            <div className="subsribe-now-details">
              <span>
                تمتّع الآن بمحتوى قيّم مقدم من خيرة الأساتذة في تونس لجميع الأقسام والمستويات
                &nbsp;:&nbsp;
              </span>
              <br /> <span>من الرابعة ابتدائي إلى البكالوريا</span>
            </div>

            <a href={studentRegister} className="subscribe-now-btn">
              <span>اشترك الآن</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ooredoo;
