import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import { BasicModalFields } from '../..';

const Index = () => {
  return (
    <div className="transfer">
      <DialogContent >
        <BasicModalFields type={'2'} />
      </DialogContent>
    </div>
  );
};

export default Index;
