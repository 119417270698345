import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardContent, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PageHeader from '../components/PageHeader';
import './_index.scss';
import PurchaseHistoryItem from './components/PurchaseHistoryItem';
import PreviousButton from '../components/PreviousButton';
import { Box, Divider } from '@mui/material';
import { useLocation } from 'react-router-dom';
import userIcon from '../../../assets/img/icons/user-icon.svg';
import phoneIcon from '../../../assets/img/icons/phone-icon.svg';
import emailIcon from '../../../assets/img/icons/email-icon.svg';
import addressIcon from '../../../assets/img/icons/address-icon.svg';
import DeliveryDetailsItem from './components/DeliveryDetailsItem';
import NotFound from '../../NotFound';

const PurchaseHistory = () => {
  const { t, i18n } = useTranslation();
  const { informations, permissions } = useSelector((state) => state.user);
  const { language } = i18n;
  useEffect(() => {
    if (
      permissions &&
      permissions?.eligibleUserForGift === true &&
      informations &&
      !informations.gift_code
    ) {
      window.location.href = '/generate-gift-code';
    }
  }, [informations, permissions]);

  const headers = [
    { accessor: 'Product', label: t('Product') },
    { accessor: 'Jetons', label: t('Jetons') },
    { accessor: 'Description', label: t('Description') },
  ];
  const location = useLocation();
  const items = location.state?.gift_request_items;
  const adminNote = location.state?.note;

  const deliveryInfo = location.state?.gift_request_deliveries[0];

  return permissions?.eligibleUserForGift === true ? (
    <div
      className={
        language === 'ar'
          ? 'parrain-purchase-history-page parrain-page-arabic'
          : 'parrain-purchase-history-page'
      }
    >
      <PageHeader
        paths={[
          {
            label: 'Parrain',
            link: informations?.gift_code ? `/gift-code` : `/generate-gift-code`,
          },
          {
            label: 'History',
          },
        ]}
        noCart
      />
      <PreviousButton />
      <Card className="ta-card parrain-purchase-history-products-list">
        <CardContent>
          <div className="parrain-purchase-history-page-title">{t('Your purchase history')}</div>
          <div className="parrain-purchase-history-page-content">
            <div className="parrain-purchase-history-page-table">
              <div className="parrain-purchase-history-page-table-head">
                {headers.map((head) => (
                  <p key={head.accessor} className={language === 'ar' ? 'ar-th' : 'th'}>
                    {head.label}
                  </p>
                ))}
              </div>
              {items?.length > 0 ? (
                <div className="parrain-purchase-history-list-wrapper">
                  <div className="parrain-purchase-history-list">
                    {items?.map((item) => (
                      <PurchaseHistoryItem item={item} key={item?.id} />
                    ))}
                  </div>
                </div>
              ) : (
                <Typography className="no-products">{t('No products found')}</Typography>
              )}
            </div>
            <div className="order-details">
              <Box className="parrain-confirm-order-products-list-title">
                <Typography>{t('1 order')}</Typography>
                <Divider />
              </Box>
              <div className="delivery-details">
                <DeliveryDetailsItem icon={userIcon} label={t('Name')} info={deliveryInfo?.name} />
                <DeliveryDetailsItem
                  icon={phoneIcon}
                  label={t('Phone Number')}
                  info={deliveryInfo?.phone1}
                />
                <DeliveryDetailsItem
                  icon={emailIcon}
                  label={t('Email')}
                  info={deliveryInfo?.email}
                />
                <DeliveryDetailsItem
                  icon={addressIcon}
                  label={t('Adresse')}
                  info={deliveryInfo?.address}
                />
                {deliveryInfo?.note && (
                  <>
                    <div className="note">{t('Note')}</div>
                    <div className="note-content">{deliveryInfo?.note}</div>
                  </>
                )}
                {adminNote && (
                  <>
                    <div className="note">{t('Administrative Note')}</div>
                    <div className="note-content">{adminNote}</div>
                  </>
                )}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  ) : (
    <NotFound />
  );
};

export default PurchaseHistory;
