import React from 'react';
import ooredooLogo from '../../../../assets/img/icons/ORDS.png';
import './_index.scss';

const PlanCard = ({ price, period, setRecurrence }) => {
  const handleButtonClick = () => {
    setRecurrence(period);
  };
  const periodName = period === '1' ? ' jour' : period === '7' ? ' semaine' : '';

  return (
    <div className="plan-card">
      <div className="ooredoo-brand">
        <img src={ooredooLogo} />
      </div>
      <div className="plan-card-price">
        <span>{price} TND/</span>
        {periodName}
      </div>
      <div className="plan-description">
        Ce type d'abonnement est renouvelable automatiquement chaque {periodName}. Vous pouvez
        annuler votre abonnement à tout moment.
      </div>
      <button className="get-plan-btn" type="submit" onClick={handleButtonClick}>
        Acheter
      </button>
    </div>
  );
};

export default PlanCard;
