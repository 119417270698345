function formatAmount(amount, t) {
  if (amount == null) {
    return `0 ${t('jetons')}`;
  }
  if (amount === 1) {
    return t('1 jeton');
  }
  if (amount === 2) {
    return t('2 jetons');
  }
  if (amount > 10) {
    return `${amount} ${t('plus de 10 jetons')}`;
  }
  return `${amount} ${t('jetons')}`;
}
export default formatAmount;
