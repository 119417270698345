import React from 'react';

const OfferDetails = ({ hidden, description }) => {
  return (
    <div className="offer-list-details">
      <div
        className={!hidden ? 'offer-list-text' : 'offer-list-text offer-list-text-hidden'}
        style={{
          height: '100%',
        }}
      >
        {description}
      </div>
    </div>
  );
};

export default OfferDetails;
