import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import * as dayjs from 'dayjs';
import NoDataIllustration from '../NoDataIllustration';
import noNotificationIllustration from '../../assets/img/noNotificationIllustration.webp';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Loader } from '..';
import { updateNotificationARead } from '../../redux/slices/notifications';
import WindowDimensions from '../../utilities/windowDimenssions';

import constants from '../../utilities/constants';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ar';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

dayjs.extend(relativeTime);
const Index = ({ notifications, handleClose, loadMore, hasMore, isLoading }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { width } = WindowDimensions();
  const isMobileWidth = width <= 480;
  const isArabic = i18n.language === 'ar';
  const notificationIcons = constants.NOTIFICATIONS_ICONS;
  const currentLanguage = i18n.language;
  const sortedNotifications = [...notifications];
  useEffect(() => {
    if (currentLanguage === 'ar') {
      dayjs.locale('ar');
    } else if (currentLanguage === 'fr') {
      dayjs.locale('fr');
    } else {
      dayjs.locale('en');
    }
  }, [currentLanguage]);

  const handleNotficiationOnClick = (notificationId) => {
    dispatch(updateNotificationARead(notificationId));
  };
  if ((!notifications || notifications?.length === 0) && isLoading) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}
      >
        <CircularProgress style={{ color: '#2ba7df', height: '30px', width: '30px' }} />
      </div>
    );
  }
  return (
    <div style={{ maxHeight: '70vh', overflow: 'auto' }}>
      <InfiniteScroll
        pageStart={1}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={<Loader key={0} />}
        useWindow={false}
      >
        {sortedNotifications && sortedNotifications.length ? (
          sortedNotifications
            .sort((a, b) => (a?.created_at < b?.created_at ? 1 : -1))
            .map((notification, index) => {
              const notificationIcon = notificationIcons?.find(
                (icon) => icon?.title === notification?.title
              );
              return (
                <MenuItem
                  key={index}
                  onClick={handleClose}
                  style={{ backgroundColor: !notification.seen && 'rgba(233, 248, 253, 0.6)' }}
                >
                  <Link
                    to={notification?.link || '#'}
                    key={notification.id}
                    onClick={() => handleNotficiationOnClick(notification.id)}
                    className={`notifications-list ${isArabic ? 'rtl' : ''}`}
                  >
                    <img alt={notificationIcon?.title} src={notificationIcon?.icon} />
                    <div className="notification-wrapper ">
                      <Typography className="notification-body">
                        {isMobileWidth && notification?.body?.length > 25
                          ? isArabic
                            ? `...${t(notification.body).substring(0, 25)}`
                            : `${t(notification.body).substring(0, 25)}...`
                          : notification?.body.length > 50
                          ? isArabic
                            ? `...${t(notification.body).substring(0, 50)}`
                            : `${t(notification.body).substring(0, 50)}...`
                          : t(notification.body)}
                      </Typography>

                      <Typography className="notification-time">
                        {dayjs(notification.created_at).fromNow()}
                      </Typography>
                    </div>
                  </Link>
                </MenuItem>
              );
            })
        ) : (
          <NoDataIllustration
            image={noNotificationIllustration}
            text={t('No notifications')}
            title="noNotificationIllustration"
          />
        )}
      </InfiniteScroll>
    </div>
  );
};

export default Index;
