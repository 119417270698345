import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const BorderLinearProgress = withStyles((theme) => ({
  root: (props) => ({
    height: 5,
    borderRadius: 5,
    marginLeft: props.leftSize,
    marginRight: props.rightSize,
  }),
  colorPrimary: () => ({
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  }),
  bar: (props) => ({
    borderRadius: 5,
    backgroundColor: props.color,
  }),
}))(LinearProgress);

const Progress = (props) => {
  const {
    value,
    title,
    icon,
    color,
    leftSize,
    rightSize,
    classTitle,
    marginLeftSize,
    marginRightSize,
  } = props;
  return (
    <div className="progress">
      <br />
      {icon ? (
        <div>
          <div className="block">
            <div className="block">
              <img className="icon-subject" src={icon} />
              &nbsp;&nbsp;
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                display="inline"
                className={classTitle}
              >
                {title}
              </Typography>
            </div>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className="number"
              display="inline"
            >
              {value}%
            </Typography>
          </div>

          <BorderLinearProgress
            variant="determinate"
            value={value}
            display="inline"
            color={color}
            style={{ backgroundColor: '#F1F1F2' }}
            leftSize={leftSize}
            rightSize={rightSize}
          />
        </div>
      ) : (
        <div>
          <div
            className="block"
            style={{ marginLeft: marginLeftSize, marginRight: marginRightSize }}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classTitle}
              display="inline"
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className="number"
              display="inline-block"
            >
              {value}%
            </Typography>
          </div>
          <BorderLinearProgress
            variant="determinate"
            value={value}
            display="inline-block"
            color={color}
            leftSize={leftSize}
            rightSize={rightSize}
            style={{ backgroundColor: '#F1F1F2' }}
          />
        </div>
      )}
    </div>
  );
};

Progress.propTypes = {
  value: PropTypes.any,
  title: PropTypes.any,
  icon: PropTypes.any,
  color: PropTypes.string,
  leftSize: PropTypes.any,
  rightSize: PropTypes.any,
  classTitle: PropTypes.any,
  marginLeftSize: PropTypes.any,
  marginRightSize: PropTypes.any,
};

export default Progress;
