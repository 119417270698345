import React, { useEffect, useState } from 'react';
import './_index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TablePagination, Typography } from '@mui/material';
import { Card } from '@material-ui/core';
import ProductItem from './components/ProductItem';
import PageHeader from '../components/PageHeader';
import { getAllGifts } from '../../../redux/slices/parrain';
import useSettings from '../../../hooks/useSettings';
import NotFound from '../../NotFound';

const ParrainProducts = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const dispatch = useDispatch();
  const { allGifts, allGiftsTotal } = useSelector((state) => state.parrain);
  const { informations, permissions } = useSelector((state) => state.user);
  const { settings } = useSettings();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (
      permissions &&
      permissions?.eligibleUserForGift === true &&
      informations &&
      !informations.gift_code
    ) {
      window.location.href = '/generate-gift-code';
    }
  }, [informations, permissions]);

  useEffect(() => {
    dispatch(getAllGifts({ page: page + 1, perPage: rowsPerPage }));
  }, [page, rowsPerPage, dispatch]);

  return permissions?.eligibleUserForGift === true ? (
    <div
      className={
        settings.sidebar === 'full'
          ? language === 'ar'
            ? 'prrain-products-page-full-sidebar parrain-page-arabic'
            : 'prrain-products-page-full-sidebar'
          : 'prrain-products-page'
      }
    >
      <PageHeader
        paths={[
          {
            label: 'Parrain',
            link: informations?.gift_code ? `/gift-code` : `/generate-gift-code`,
          },
          { label: 'Product' },
        ]}
      />

      <Card className="ta-card prrain-products-list-card">
        {allGifts?.length > 0 ? (
          <div className="prrain-products-list-wrapper">
            <div className="prrain-products-list">
              {allGifts?.map((item) => (
                <ProductItem item={item} key={item?.id} />
              ))}
            </div>
            <TablePagination
              component="div"
              count={allGiftsTotal}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage={t('Rows per page')}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </div>
        ) : (
          <Typography>{t('No products found')}</Typography>
        )}
      </Card>
    </div>
  ) : (
    <NotFound />
  );
};

export default ParrainProducts;
