import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import './_index.scss';
import Select from '../../components/Form/select';
import Input from '../../components/Form/Input';
import { Form, Formik } from 'formik';
import { Alert, Box, Snackbar } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { getDivisions, getGeoInfo, getStates, register } from '../../redux/slices/auth';
import { getCountries } from '../../redux/slices/places';
import ReCAPTCHA from 'react-google-recaptcha';
import DatePicker from '../../components/Form/DatePicker';
import formatDate from '../../utilities/formatDate';
import { getOptionalSubjects } from '../../redux/slices/subjects';
import { Link, useLocation } from 'react-router-dom';
import removePonctuation from '../../utilities/removePonctuation';
import AuthLayout from '../../layouts/AuthLayout';
import { setSearchParam } from '../../utilities/setSearchParam';

const Register = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { geoIp, divisions, states, success, authResult } = useSelector((state) => state.auth);
  const { countries } = useSelector((state) => state.places);
  const { optional_subjects } = useSelector((state) => state.subjects);
  const recaptchaRef = useRef();
  const { i18n } = useTranslation();
  const [extraErrors, setExtraErrors] = useState();

  const [formErrors, setFormErrors] = useState();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [formValues, setFormValues] = useState();
  const [open, setOpen] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const language = i18n.language;

  useEffect(() => {
    document.title = 'Register | Takiacademy';
  }, []);

  useEffect(() => {
    if (!geoCookie) {
      dispatch(getGeoInfo());
    }
    dispatch(getDivisions());
    dispatch(getStates());
    dispatch(getCountries());
  }, [dispatch]);

  const location = useLocation();
  const previousUrl = document.referrer;

  useEffect(() => {
    if (location.state) {
      window.history.href = `${previousUrl}?${location.state}`;
    }
  }, [location.state]);

  useEffect(() => {
    const nextPage = sessionStorage.getItem('nextPage');

    if (nextPage) {
      setSearchParam('nextPage', 'offres');
    }
  }, []);

  const geoCookie = Cookies.get('GeoIp');

  useEffect(() => {
    if (formValues && formValues?.division) {
      dispatch(getOptionalSubjects(formValues?.division));
    }
  }, [formValues?.division]);

  const initialValues = {
    name: '',
    lastName: '',
    phone: '',
    email: '',
    birthDate: '',
    plainPassword: {
      first: '',
      second: '',
    },
    country: null,
    state: null,
    division: null,
    optionalSubject: null,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[\p{L} ]+$/u, 'Veuillez saisir un prénom valide')
      .required('Le prénom est requis')
      .min(2, 'Le prénom est trop court Il doit contenir au moins 2 caractères')
      .max(25, 'Le prénom est trop long Il doit contenir 20 caractères ou moins'),

    lastName: Yup.string()
      .matches(/^[\p{L} ]+$/u, 'Veuillez saisir un nom valide')
      .required('Le nom est requis')
      .min(2, 'Le nom est trop court Il doit contenir au moins 2 caractères')
      .max(25, 'Le nom est trop long Il doit contenir 20 caractères ou moins'),
    phone:
      (geoIp && geoIp.country === 'TN') ||
      (geoCookie && geoCookie === 'TN') ||
      (!geoIp && !geoCookie)
        ? Yup.string()
            .required('Le numéro de téléphone est requis')
            .test('test-name', 'Veuillez saisir un numéro de téléphone valide', function (value) {
              const phoneRegex = /^((\+|00)216)?([2459][0-9]{7})+$/;
              let isValidPhone = phoneRegex.test(value);
              if (!isValidPhone) {
                return false;
              }
              return true;
            })
        : Yup.string().optional(),

    email: !(
      (geoIp && geoIp.country === 'TN') ||
      (geoCookie && geoCookie === 'TN') ||
      (!geoIp && !geoCookie)
    )
      ? Yup.string().required("L'adresse e-mail est requise")
      : Yup.string().optional(),
    birthDate: Yup.string()
      .matches(
        /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
        'Format de date invalide'
      )
      .max(new Date('2018-05-16'), 'Cette valeur doit être antérieure au 16 mai 2018')
      .required('La date de naissance est requise'),

    plainPassword: Yup.object().shape({
      first: Yup.string()
        .max(
          16,
          'La valeur du mot de passe est trop longue Il doit contenir 16 caractères ou moins'
        )
        .required('Veuillez saisir votre mot de passe')
        .matches(/[A-Z]/, 'Le mot de passe doit contenir au moins une lettre majuscule')
        .matches(/[a-z]/, 'Le mot de passe doit contenir au moins une lettre minuscule')
        .matches(/[0-9]/, 'Le mot de passe doit contenir au moins un chiffre')
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          'Le mot de passe doit contenir au moins un caractère spécial'
        )
        .min(8, 'Mot de passe minimum 8 caractères'),
      second: Yup.string()
        .oneOf([Yup.ref('first'), null], 'Les deux mots de passe doivent correspondre')
        .required('La confirmation du mot de passe est requise')
        .max(
          16,
          'La valeur du mot de passe est trop longue Il doit contenir 16 caractères ou moins'
        ),
    }),

    division: Yup.string().required('La division est requise'),

    state: !((geoIp && geoIp.country !== 'TN') || (geoCookie && geoCookie !== 'TN'))
      ? Yup.string().nullable().required('Le gouvernorat est requis')
      : Yup.string().nullable().optional(),
    country:
      (geoIp && geoIp.country !== 'TN') || (geoCookie && geoCookie !== 'TN')
        ? Yup.string().nullable().required('Le pays est requis')
        : Yup.string().nullable().optional(),
  });

  const onSubmit = (values, submitProps) => {
    try {
      setIsSubmitting(true);

      const { birthDate, ...data } = values;
      setExtraErrors(null);

      const updatesValues = {
        ...data,
        birthDate: formatDate(
          new Date(
            birthDate.split('/')[1] + '/' + birthDate.split('/')[0] + '/' + birthDate.split('/')[2]
          ),
          false,
          '-',
          true
        ),
      };
      recaptchaRef.current
        .execute()
        .then((res) =>
          dispatch(register({ ...updatesValues, 'g-recaptcha-response': res }))
            .then((response) => {
              setIsSubmitting(false);
              setOpenSnackBar(true);

              if (response?.payload?.includes('Account information already used')) {
                if (
                  (geoIp && geoIp.country === 'TN') ||
                  (geoCookie && geoCookie === 'TN') ||
                  (!geoIp && !geoCookie)
                ) {
                  submitProps.setErrors({ phone: ' ' });
                } else {
                  submitProps.setErrors({ email: ' ' });
                }
              } else if (response?.payload?.includes('Birthdate')) {
                if (response?.payload?.includes('This value should be less than')) {
                  setExtraErrors(`Age must be 5 years or older`);
                }
                submitProps.setErrors({ birthDate: ' ' });
              } else if (response?.payload?.includes('Email')) {
                submitProps.setErrors({ email: ' ' });
              } else if (response?.payload?.includes('Server')) {
                setExtraErrors("Une erreur s'est produite, veuillez réessayer ultérieurement");
                setOpenSnackBar(true);
              } else if (response?.payload?.includes('first Password')) {
                submitProps.setErrors({
                  plainPassword: {
                    first: ' ',
                    second: '',
                  },
                });
              } else {
                setExtraErrors(removePonctuation(res?.payload));
                setOpenSnackBar(true);
              }
            })
            .catch((error) => {
              setExtraErrors(removePonctuation(error));
              setOpenSnackBar(true);
            })
        )
        .catch((error) => {
          setExtraErrors(removePonctuation(error));
          setOpenSnackBar(true);
        });

      submitProps.setStatus({ success: true });
      submitProps.setSubmitting(false);
    } catch (err) {
      if (!err.message) {
        setExtraErrors('error');
        setOpenSnackBar(true);
      } else {
        setExtraErrors(removePonctuation(err.message));
        setOpenSnackBar(true);
      }

      submitProps.setStatus({ success: false });
      submitProps.setSubmitting(false);
    }
  };

  return (
    <AuthLayout className="register-page">
      <h1 className="content-title">{t('Créer un compte')}</h1>
      <div className="auth-form register-form">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            setFormValues(formik.values);
            setFormErrors(formik.errors);

            return (
              <Form className="register-form-content">
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  className="form-grid"
                  gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                  }}
                >
                  <Input
                    type="text"
                    label={t('Nom')}
                    name="lastName"
                    placeholder={t('Nom')}
                    required
                    error={
                      formik.errors.lastName && formik.touched.lastName
                        ? t(removePonctuation(formErrors.lastName))
                        : null
                    }
                  />
                  <Input
                    type="text"
                    label={t('Prénom')}
                    name="name"
                    className={language === 'fr' ? '' : 'form-control-order'}
                    placeholder={t('Prénom')}
                    required
                    error={
                      formik.errors.name && formik.touched.name
                        ? t(removePonctuation(formErrors.name))
                        : null
                    }
                  />
                  {(geoIp && geoIp.country === 'TN') ||
                  (geoCookie && geoCookie === 'TN') ||
                  (!geoIp && !geoCookie) ? (
                    <Input
                      type="text"
                      label={t('Numéro de téléphone')}
                      name="phone"
                      placeholder={t('Numéro de téléphone')}
                      required
                      error={
                        formik.errors.phone && formik.touched.phone
                          ? t(removePonctuation(formErrors.phone))
                          : null
                      }
                      helper={t(
                        "Vous allez recevoir un SMS sur ce numéro, soyez sûr qu'il est correct"
                      )}
                    />
                  ) : (
                    <Input
                      type="text"
                      label={t('Email')}
                      name="email"
                      placeholder={t('Email')}
                      required
                      error={
                        formik.errors.email && formik.touched.email
                          ? t(removePonctuation(formErrors.email))
                          : null
                      }
                    />
                  )}

                  <DatePicker
                    label={t('Date de naissance')}
                    name="birthDate"
                    placeholder={t('Date de naissance')}
                    required
                    open={open}
                    calendarHeader={t('Choisissez votre date de naissance')}
                    setOpen={setOpen}
                    error={
                      !open && formik.errors.birthDate && formik.touched.birthDate
                        ? t(removePonctuation(formErrors.birthDate))
                        : null
                    }
                  />
                  {language === 'ar' ? (
                    <>
                      <Input
                        type="password"
                        label={t('Confirmez le mot de passe')}
                        name="plainPassword.second"
                        placeholder={t('Confirmez le mot de passe')}
                        required
                        error={
                          formik.errors.plainPassword?.second &&
                          formik.touched.plainPassword?.second
                            ? t(removePonctuation(formErrors.plainPassword?.second))
                            : null
                        }
                        className="form-control-arrange"
                      />
                      <Input
                        type="password"
                        label={t('Mot de passe')}
                        name="plainPassword.first"
                        placeholder={t('Mot de passe minimum 8 caractères')}
                        required
                        error={
                          formik.errors.plainPassword?.first && formik.touched.plainPassword?.first
                            ? t(removePonctuation(formik.errors.plainPassword?.first))
                            : null
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Input
                        type="password"
                        label={t('Mot de passe')}
                        name="plainPassword.first"
                        placeholder={t('Mot de passe minimum 8 caractères')}
                        required
                        error={
                          formik.errors.plainPassword?.first && formik.touched.plainPassword?.first
                            ? t(removePonctuation(formErrors.plainPassword?.first))
                            : null
                        }
                        className="form-control-arrange"
                      />
                      <Input
                        type="password"
                        label={t('Confirmez le mot de passe')}
                        name="plainPassword.second"
                        placeholder={t('Confirmez le mot de passe')}
                        required
                        error={
                          formik.errors.plainPassword?.second &&
                          formik.touched.plainPassword?.second
                            ? t(removePonctuation(formErrors.plainPassword?.second))
                            : null
                        }
                        className="form-control-arrange"
                      />
                    </>
                  )}
                  {(geoIp && geoIp.country !== 'TN') || (geoCookie && geoCookie !== 'TN') ? (
                    <Select
                      options={countries || []}
                      placeholder={t('Choisissez votre pays')}
                      label={t('Choisissez votre pays')}
                      name="country"
                      error={
                        formik.errors.country && formik.touched.country
                          ? t(removePonctuation(formErrors.country))
                          : null
                      }
                      className="form-control-arrange"
                      required
                    />
                  ) : (
                    <Select
                      options={states || []}
                      placeholder={t('Choisissez votre gouvernorat')}
                      label={t('Choisissez votre gouvernorat')}
                      name="state"
                      error={
                        formik.errors.state && formik.touched.state
                          ? t(removePonctuation(formErrors.state))
                          : null
                      }
                      required
                      className="form-control-arrange"
                    />
                  )}
                  <Select
                    options={divisions || []}
                    placeholder={t('Choisissez votre classe')}
                    label={t('Choisissez votre classe')}
                    name="division"
                    className="form-control-arrange"
                    error={
                      formik.errors.division && formik.touched.division
                        ? t(removePonctuation(formErrors.division))
                        : null
                    }
                    required
                  />
                  {optional_subjects && optional_subjects.length > 0 && (
                    <Select
                      options={optional_subjects || []}
                      placeholder={t('Choisissez votre option')}
                      label={t('Choisissez votre option')}
                      name="optionalSubject"
                      className="full-width form-control-arrange"
                      error={
                        formik.errors.optionalSubject && formik.touched.optionalSubject
                          ? t(removePonctuation(formErrors.optionalSubject))
                          : null
                      }
                      required
                    />
                  )}
                </Box>
                <button
                  type="submit"
                  className={
                    isSubmitting || !formik.isValid ? 'auth-btn disabled-auth-btn' : 'auth-btn'
                  }
                  disabled={isSubmitting || !formik.isValid}
                >
                  {isSubmitting ? <CircularProgress color="inherit" /> : t('Créer un compte')}
                </button>
              </Form>
            );
          }}
        </Formik>
        <span
          className={
            language === 'ar' ? 'arabic-return-to-login return-to-login' : 'return-to-login'
          }
        >
          {t('Avez vous déjà un compte ?')}
          &nbsp;<Link to="/login">{t('Se connecter')}&nbsp;</Link>
        </span>
        <Snackbar
          open={openSnackBar}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert
            severity={success === false ? 'error' : 'success'}
            onClose={() => setOpenSnackBar(false)}
          >
            {extraErrors ? t(removePonctuation(extraErrors)) : t(removePonctuation(authResult))}
          </Alert>
        </Snackbar>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        size="invisible"
      />
    </AuthLayout>
  );
};

export default Register;
