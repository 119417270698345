import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ViewTitle from '../../components/ViewTitle';
import { ProfileWidget, PubsCards } from '../../components';
import SubjectWidget from './components/SubjectWidget';
import SubjectTabs from './components/SubjectTabs';
import { getSubjects } from '../../redux/slices/subjects';
import { getChapters } from '../../redux/slices/chapters';
import {
  getNewSubjectChaptersProgress,
  getNewSubjectProgress,
} from '../../redux/slices/newProgress';
import { getPubsCards } from '../../redux/slices/offers';

const SubjectDetail = () => {
  const { t } = useTranslation();
  let { subject_id } = useParams();
  const subjectSlug = subject_id;
  const subjectId = subjectSlug.split('-')[0];
  const dispatch = useDispatch();
  const { subjects } = useSelector((state) => state.subjects);
  const { chaptersSubject, chapters, chapterSections } = useSelector((state) => state.chapters);
  const { pubsCards, pubsCardsStatus } = useSelector((state) => state.offers);
  const [Subjects, setSubjects] = useState([]);
  const [Chapters, setChapters] = useState([]);
  const [Subject, setSubject] = useState(null);
  const { newchaptersProgress, newsubjectProgress } = useSelector((state) => state.newProgress);
  useEffect(() => {
    if (subjects) {
      setSubject(subjects.find((subject) => subject.slug === subjectSlug));
    }
    if (subjectSlug) {
      dispatch(getChapters(subjectSlug));
    }
  }, [subjectSlug]);

  useEffect(() => {
    if (!subjects) {
      dispatch(getSubjects());
    }
    if (!chapters) {
      if (subjectSlug) {
        dispatch(getChapters(subjectSlug));
      }
    }
  }, []);

  useEffect(() => {
    if (subjects) {
      setSubjects(subjects);
      setSubject(subjects.find((subject) => subject.slug === subjectSlug));
    }
  }, [subjects]);

  useEffect(() => {
    if (chapters) {
      setChapters(chapters);
    }
  }, [chapters]);

  useEffect(() => {
    if (Subject) {
      dispatch(getNewSubjectChaptersProgress(subjectId));
      dispatch(getNewSubjectProgress(subjectId));
    }
  }, [Subject]);

  useEffect(() => {
    if (pubsCardsStatus === 'idle' && pubsCards?.length < 1) {
      dispatch(getPubsCards());
    }
  }, [pubsCards]);

  return (
    <div className="full-page">
      <ViewTitle title={t('Matières')} />
      <div className="ta-row">
        <div className="ta-column childrens-inverse right-margin layout-subjects-details">
          <div className="child-left">
            <SubjectTabs
              chapters={Chapters}
              slug={subjectSlug}
              chapterSections={chapterSections}
              newChaptersProgress={newchaptersProgress}
            />
          </div>
          <div className="child-right">
            <SubjectWidget
              subject={Subject}
              subjects={Subjects}
              totalChapters={chapters && chapters.length}
              totalVideos={chaptersSubject && chaptersSubject.subjectVideos}
              newsubjectProgress={newsubjectProgress}
            />
          </div>
        </div>
        <div className="ta-column fixed right">
          <div style={{ width: '100%', marginTop: '60px' }}></div>
          <ProfileWidget mini />
          {pubsCards
            ?.slice()
            ?.sort((a, b) => a.position - b.position)
            ?.map((card) => {
              return (
                <PubsCards
                  key={card?.id}
                  className={card?.type}
                  icon={card?.icon_url}
                  text={card?.text_image_url || card?.text}
                  isText={card?.text}
                  modal={card?.video_link != null}
                  path={card?.video_link || card?.url_link}
                  bg={card?.background_url}
                  visible={card?.visible}
                  iconPosition={card?.icon_position}
                  color={card?.color}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SubjectDetail;
