import React from 'react';
import './_index.scss';
import { Tooltip } from '@material-ui/core';

const PurchaseHistoryItem = ({ item, key }) => {
  const img = item?.gift?.gift_images?.find((el) => el.is_cover)?.file_url;

  return (
    <div className="purchase-history-item-wrapper" key={key}>
      <div className="purchase-history-item">
        <div className="purchase-history-item-img">
          <img src={img} alt="product-img" />
        </div>
        <div className="purchase-history-item-text">
          <h3>{item?.gift?.name}</h3>
        </div>
      </div>
      <div className="purchase-history-item-coins">{item?.amount}</div>
      <Tooltip title={item?.gift?.description}>
        <div className="purchase-history-item-description">{item?.gift?.description}</div>
      </Tooltip>
    </div>
  );
};

export default PurchaseHistoryItem;
