import React from 'react';
import './index.scss';

const DeliveryDetailsItem = ({ label, info, icon }) => {
  return (
    <div className="delivery-details-item">
      <div>
        <img src={icon} alt="user-icon" />
        <p>{label}</p>
      </div>
      <div>{info}</div>
    </div>
  );
};
export default DeliveryDetailsItem;
