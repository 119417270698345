import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openModal } from '../../redux/slices/modals';

const Index = () => {
  const { t } = useTranslation();
    const dispatch = useDispatch();
  return (
    <div className="transferLink" onClick={() => dispatch(openModal('transfer-point-modal'))}>
      <span className="transferDescription">
        {t('Vous pouvez transférer des points à un autre compte')}:{' '}
      </span>
      <Link className="link" to="#">
        {t('Transférer')}
      </Link>
    </div>
  );
};

export default Index;
