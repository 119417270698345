import React from 'react';
import { useTranslation } from 'react-i18next';
import ViewTitle from '../../components/ViewTitle';
import {
  SubscriptionList,
  ProfileWidget,
  PointsTransactionHistory,
  LiveSessionHistory,
  EditDivisionList,
} from '../../components';

const Index = () => {
  const { t } = useTranslation();
  return (
      <>
        <ViewTitle title={t('Historique')} />
        <div className="ta-row wallet">
          <div className="ta-column right-margin">
            <div className="child-full">
              <SubscriptionList />
            </div>
            <div className="child-full">
              <PointsTransactionHistory />
            </div>
            <div className="child-full">
              <EditDivisionList />
            </div>
            <div className="child-full">
              <LiveSessionHistory />
            </div>
          </div>
          <div className="ta-column fixed right">
            <ProfileWidget mini />
          </div>

        </div>
      </>
  );
};

export default Index;
