import * as dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import { days } from './constants';
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.locale('ar','fr','en');

const getLessonDuration = (duration) => {
  let DMs = 0;
 
  if (duration) {
    let h = dayjs(duration, 'HH:mm:ss').hour() * 60 * 60;
    DMs =
      (h + dayjs(duration, 'HH:mm:ss').minute() * 60 + dayjs(duration, 'HH:mm:ss').second()) * 1000;
    return h > 0
      ? `${dayjs.duration(DMs).format('HH')}:${dayjs
          .duration(DMs)
          .format('mm')}:${dayjs.duration(DMs).format('ss')}`
      : `${dayjs.duration(DMs).format('mm')}:${dayjs.duration(DMs).format('ss')}`;
  }
};
export default getLessonDuration;
