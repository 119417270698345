import React from 'react';
import './_index.scss';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isEqual,
  isSameMonth,
  isToday,
  parse,
  startOfToday,
  startOfWeek,
} from 'date-fns';

import { useEffect, useState } from 'react';
import Button from '../Button';
import formatDate from '../../utilities/formatDate';
import Months from './Components/Months';
import Years from './Components/Years';
import updateDate from '../../utilities/updateDate';
import { useTranslation } from 'react-i18next';
import { isNumeric } from '../../utilities/isNumeric';

const Calendar = ({ setOpen, helpers, field, calendarClassName, calendarHeader }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  //days
  let today = startOfToday();
  let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'));
  let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());
  let days = eachDayOfInterval({
    start: startOfWeek(firstDayCurrentMonth),
    end: endOfWeek(endOfMonth(firstDayCurrentMonth)),
  });
  let [selectedDay, setSelectedDay] = useState(formatDate(today, true, ' '));
  let [selectedDate, setSelectedDate] = useState(formatDate(today, true, ' '));

  //months
  let [openMonths, setOpenMonths] = useState(false);
  let [monthValue, setMonthValue] = useState(selectedDay?.split(' ')[1]);

  useEffect(() => {
    monthValue && setSelectedDay(updateDate(selectedDay, monthValue, 'month'));
    setCurrentMonth(monthValue?.slice(0, 3) + '-' + selectedDay.split(' ')[2]);
  }, [monthValue]);

  //years
  let [openYears, setOpenYears] = useState(false);
  let [yearValue, setYearValue] = useState(+selectedDay?.split(' ')[2]);

  useEffect(() => {
    yearValue && setSelectedDay(updateDate(selectedDay, yearValue, 'year'));
    setCurrentMonth(selectedDay?.split(' ')[1]?.slice(0, 3) + '-' + yearValue);
  }, [yearValue]);

  useEffect(() => {
    setSelectedDate(selectedDay);
  }, [selectedDay]);

  const nextMonth = () => {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
    setSelectedDate(formatDate(firstDayNextMonth, true, ' '));
  };

  const previousMonth = () => {
    let firstDayPreviousMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayPreviousMonth, 'MMM-yyyy'));
    setSelectedDate(formatDate(firstDayPreviousMonth, true, ' '));
  };
  useEffect(() => {
    field?.value?.length === 10 &&
      field?.value !== 'NaN/NaN/NaN' &&
      field.value?.split('/')[0] != 0 &&
      isNumeric(field.value?.split('/')[0]) &&
      field.value?.split('/')[1] != 0 &&
      isNumeric(field.value?.split('/')[1]) &&
      field.value?.split('/')[2] != 0 &&
      isNumeric(field.value?.split('/')[2]) &&
      setSelectedDay(
        formatDate(
          new Date(
            field.value?.slice(3, 5) +
              '/' +
              field.value?.slice(0, 2) +
              '/' +
              field.value?.slice(6, 10)
          ),

          true,
          ' '
        )
      );

    field?.value?.length === 10 &&
      field?.value !== 'NaN/NaN/NaN' &&
      field.value?.split('/')[0] !== '00' &&
      isNumeric(field.value?.split('/')[0]) &&
      field.value?.split('/')[1] !== '00' &&
      isNumeric(field.value?.split('/')[1]) &&
      field.value?.split('/')[2] !== '0000' &&
      isNumeric(field.value?.split('/')[2]) &&
      setCurrentMonth(
        format(
          new Date(
            field.value?.slice(3, 5) +
              '/' +
              field.value?.slice(0, 2) +
              '/' +
              field.value?.slice(6, 10)
          ),
          'MMM-yyyy'
        )
      );
  }, [field]);

  useEffect(() => {
    if (!isToday(new Date(selectedDate))) {
      helpers.setValue(formatDate(new Date(selectedDate), false, '/'));
    }
  }, [selectedDate]);

  return (
    <div className={`calendar ${calendarClassName}`}>
      <div
        className={
          language === 'ar' ? 'arabic-calendar-header  calendar-header' : 'calendar-header'
        }
      >
        {calendarHeader}
      </div>
      <div className={language === 'ar' ? 'arabic-calendar-body  calendar-body' : 'calendar-body'}>
        <div className="calendar-navigator">
          <span className="calendar-navigator-date">
            <span>{selectedDate.split(' ')[0] + ' '}</span>
            <span
              className="current-date-month"
              onClick={() => {
                setOpenMonths(!openMonths);
                openYears && setOpenYears(false);
              }}
            >
              {t(selectedDate.split(' ')[1].slice(0, 3)) + ' '}
            </span>
            <span
              className="current-date-year"
              onClick={() => {
                setOpenYears(!openYears);
                openMonths && setOpenMonths(false);
              }}
            >
              {selectedDate.split(' ')[2]}
            </span>
          </span>
          {!openMonths && !openYears && (
            <div
              className={
                language === 'ar'
                  ? 'arabic-calendar-navigator-btns  calendar-navigator-btns'
                  : 'calendar-navigator-btns'
              }
            >
              <ChevronLeftIcon onClick={previousMonth} />
              <ChevronRightIcon onClick={nextMonth} />
            </div>
          )}
        </div>
        {!openMonths && !openYears && (
          <div className="days-of-week">
            {days.slice(0, 7).map((day, i) => (
              <div className="day-of-week" key={day.toString()}>
                {t(format(day, 'ccc'))}
              </div>
            ))}
          </div>
        )}
        {!openMonths && !openYears && (
          <div className="days">
            {days.map((day, i) => (
              <div
                className={`day ${i === 0 && startMonthClassName[getDay(day) - 1]}
              ${isToday(day) && 'today'} 
               ${isSameMonth(day, firstDayCurrentMonth) && 'same-month'}     
            ${isEqual(day, new Date(selectedDay)) && 'selected-day'}    
                       ${isEqual(day, new Date(selectedDay)) && isToday(day) && 'selected-today'}
                  
            `}
                onClick={() => {
                  setSelectedDay(formatDate(day, true, ' '));
                }}
              >
                {day.getDate().toString().padStart(2, '0')}
              </div>
            ))}
          </div>
        )}
        {openMonths && (
          <Months
            monthValue={monthValue}
            setMonthValue={setMonthValue}
            setOpenMonths={setOpenMonths}
            selectedDay={selectedDay}
          />
        )}
        {openYears && (
          <Years
            yearValue={yearValue}
            setYearValue={setYearValue}
            setOpenYears={setOpenYears}
            selectedDay={selectedDay}
          />
        )}
      </div>
    </div>
  );
};
let startMonthClassName = [
  'start-of-month-monday',
  'start-of-month-tuesday',
  'start-of-month-wednesday',
  'start-of-month-thursday',
  'start-of-month-friday',
  'start-of-month-saturday',
  'start-of-month-sunday',
];
export default Calendar;
