import { createSlice } from '@reduxjs/toolkit';
import api, { headers, token, token_type } from '../../services/api';
import * as axios from 'axios';
import { ord } from '../../utilities/methods';
import { closeModal, openModal } from './modals';
import { enqueueSnackbar } from './notifier';
import i18n from '../../services/i18n';
import { me } from './user';

// Slice
const slice = createSlice({
  name: 'sessions',
  initialState: {
    sessions: [],
    isLoading: false,
    eventLoading: 'idle',
    errorSession: false,
    joinResult: null,
    sessionLink: null,
    joinLoading: false,
    sessionUpdated: false,
    emailLoading: false,
    email: null,
    emailError: null,
    isEmailError: false,
    code: null,
    codeError: null,
    codeLoading: false,
    rateables: null,
    historySessions: null,
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
      state.eventLoading = 'loading';
      state.errorSession = false;
      state.joinResult = '';
    },
    startLoadingJoin: (state) => {
      state.joinLoading = true;
    },
    startLoadingAddEmail: (state) => {
      state.codeLoading = true;
      state.code = '';
    },
    startLoadingVerifyEmail: (state) => {
      state.emailLoading = true;
      state.email = '';
      state.isEmailError = false;
    },
    hasError: (state, action) => {
      state.errorSession = action.payload;
      state.isLoading = false;
      state.joinResult = '';
      state.eventLoading = 'failed';
    },
    sessionsSuccess: (state, action) => {
      state.sessions = action.payload;
      state.isLoading = false;
      state.joinResult = null;
      state.eventLoading = 'succeeded';
    },
    joinSessionSuccess: (state, action) => {
      state.joinResult = action.payload;
      state.joinLoading = false;
      state.sessionUpdated = !state.sessionUpdated;
      state.errorSession = '';
    },
    joinSessionResult: (state, action) => {
      state.joinResult = action.payload?.payload;
      state.joinLoading = false;
    },
    sessionLink: (state, action) => {
      state.sessionLink = action.payload?.payload;
      state.joinLoading = false;
    },
    emailSuccess: (state, action) => {
      state.email = action.payload;
      state.emailLoading = false;
      state.isEmailError = false;
    },
    hasEmailError: (state, action) => {
      state.emailError = action.payload;
      state.isEmailError = true;
      state.emailLoading = false;
    },
    codeSuccess: (state, action) => {
      state.code = action.payload;
      state.codeLoading = false;
    },
    hasCodeError: (state, action) => {
      state.codeError = action.payload;
      state.codeLoading = false;
    },
    ratingSessionsSuccess: (state, action) => {
      state.rateables = action.payload.rateables;
    },
    sessionHistorySuccess: (state, action) => {
      const options = [
        { label: 'name', accessor: 'content_event.content.name' },
        { label: 'startTime', accessor: 'startTime' },
        {
          label: 'duration',
          accessor: 'webinar_session_attendee_rateable.attendanceTimeInSeconds',
        },
        { label: 'subject', accessor: 'content_event.content.subject.name' },
        { label: 'rateablescount', accessor: 'rateables_count' },
      ];
      state.historySessions = ord(action.payload.rateables, options);
      state.historySessions['rateablescount'] = action.payload.rateables_count;
    },
  },
});
export default slice.reducer;
// Actions
const {
  sessionsSuccess,
  hasError,
  joinSessionSuccess,
  joinSessionResult,
  startLoading,
  sessionLink,
  startLoadingJoin,
  startLoadingAddEmail,
  emailSuccess,
  hasEmailError,
  codeSuccess,
  hasCodeError,
  startLoadingVerifyEmail,
  ratingSessionsSuccess,
  sessionHistorySuccess,
} = slice.actions;

export const getSessions = (offset) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api
      .get(`/events?offset=${offset}`)
      .then((response) => dispatch(sessionsSuccess(response.data.payload)));
  } catch (e) {
    if (e?.response?.status === 401) {
      const adminID = localStorage.getItem('admin_user_id');
      if (adminID && adminID.includes('?redirectTo=RegisterPage')) {
        window.location = 'https://education-autrement.takiacademy.com/register';
        localStorage.removeItem('admin_user_id');
      } else {
        localStorage.clear();
        window.location = '/login';
      }
    }
    return dispatch(hasError(e.message));
  }
};

export const getJoinResult =
  (content_id, domain_id, result = null) =>
  async (dispatch) => {
    dispatch(startLoadingJoin());
    try {
      if (result) {
        dispatch(joinSessionSuccess(result));
      } else {
        await api.post(`/events/join-user`, { content_id, domain_id }).then((response) => {
          dispatch(joinSessionSuccess(response.data));
        });
      }
    } catch (e) {
      if (e.response) {
        return dispatch(hasError(e.response.data));
      }
    }
  };
export const getEventLink = (eventId) => async (dispatch) => {
  dispatch(startLoadingJoin());
  try {
    await api.get(`events/${eventId}/get-join-link`).then((response) => {
      if (response.data?.payload?.eventStartTime) {
        dispatch(joinSessionResult(response.data));
      } else {
        dispatch(sessionLink(response.data));
      }
    });
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};

export const addEmail = (email) => async (dispatch) => {
  dispatch(startLoadingAddEmail());
  try {
    await api
      .put(`/user/add-email`, {
        email,
      })
      .then((response) => dispatch(emailSuccess(response.data)));
    dispatch(closeModal('add-email-modal'));
    dispatch(openModal('verify-email-modal', { email }));
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      return dispatch(hasEmailError(message));
    }
  }
};

export const verifyEmail = (confirmationToken, email) => async (dispatch) => {
  dispatch(startLoadingVerifyEmail());

  try {
    await api
      .post(`/user/confirm-code`, {
        confirmationToken,
        email,
      })
      .then((response) => {
        dispatch(codeSuccess(response.data));
        if (response.status === 200) {
          dispatch(
            enqueueSnackbar({
              message: i18n.t(response?.data?.message),
              options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
              },
            })
          );
        }
        dispatch(closeModal('verify-email-modal'));
        dispatch(me());
      });
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      return dispatch(hasCodeError(message));
    }
  }
};

export const getRatingSessions = () => async (dispatch) => {
  headers['Authorization'] = `${token_type} ${token}`;
  try {
    await axios
      .get(`${process.env.REACT_APP_ANALYTICS_URL}/live-sessions/rating/rateables`, { headers })
      .then((response) => dispatch(ratingSessionsSuccess(response.data)));
  } catch (e) {}
};

export const hideRating = (data) => async (dispatch) => {
  headers['Authorization'] = `${token_type} ${token}`;
  try {
    await axios.post(
      `${process.env.REACT_APP_ANALYTICS_URL}/live-sessions/rating/dont-want-to-rate`,
      data,
      { headers }
    );
  } catch (e) {
    // if (e.response) {
    //
    // }
  }
};
export const submitRating = (data) => async (dispatch) => {
  headers['Authorization'] = `${token_type} ${token}`;
  try {
    await axios.post(`${process.env.REACT_APP_ANALYTICS_URL}/live-sessions/rating/submit`, data, {
      headers,
    });
  } catch (e) {
    // if (e.response) {
    //
    // }
  }
};
export const getSessionsHistory = (rows, sort, sortName) => async (dispatch) => {
  headers['Authorization'] = `${token_type} ${token}`;
  try {
    await axios
      .get(
        `${process.env.REACT_APP_ANALYTICS_URL}/live-sessions/history?rows=${rows}
    &sort=${sort}&sortName=${sortName}`,
        { headers }
      )
      .then((response) => {
        dispatch(sessionHistorySuccess(response.data));
      });
  } catch (e) {
    // if (e.response) {
    //
    // }
  }
};
