import i18n from 'i18next';
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationFr from '../locales/fr/translation.json';
import translationEn from '../locales/en/translation.json';
import translationAr from '../locales/ar/translation.json';

i18n
  .use(detector)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'fr',
    debug: false,
    
  //  lng: "fr",
    
    interpolation: {
      escapeValue: false,
    },

    react: {
      useSuspense: false,
      wait: false,
    },

    resources: {
      'fr': {
        translations: translationFr,
      },
      'en-US': {
        translations: translationEn,
      },
      'ar': {
        translations: translationAr,
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',
    detection: {
      order: ['cookie', 'localStorage', 'querystring', 'navigator'],
      caches: ['cookie', 'localStorage'],

    }
  });

export default i18n;
