import React from 'react';
import { useTranslation } from 'react-i18next';
import ViewTitle from '../../components/ViewTitle';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  message: {
  },
  message_rtl: {
    direction: "rtl",
  }
}))

const Index = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <ViewTitle title={t('Oups 404 Error')} />
      <div className={i18n.language === 'ar'
                            ? classes.message_rtl
                            : classes.message}>
        <span>{t('La page que vous recherchez semble introuvable')}</span>
      </div>
    </div>
  );
};

export default Index;
