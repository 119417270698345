import { createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';
import { ord } from '../../utilities/methods';

// Slice
const slice = createSlice({
  name: 'search',
  initialState: {
    autocomplete_result: null,
    search_result: null,
  },
  reducers: {
    autocompleteSuccess: (state, action) => {
      const options = [
        { label: 'name', accessor: 'content_name' },
        { label: 'chapter_name', accessor: 'chapter_name' },
        { label: 'subject_name', accessor: 'subject_name' },
        { label: 'content_slug', accessor: 'content_slug' },
        { label: 'chapter_slug', accessor: 'chapter_slug' },
        { label: 'subject_slug', accessor: 'subject_slug' },
      ];
      const data = action.payload.data;
      state.autocomplete_result = data?.length > 0 ? ord(data, options) : [];
    },
    searchSuccess: (state, action) => {
      const options = [
        { label: 'id', accessor: 'id' },
        { label: 'name', accessor: 'name' },
      ];
      const data = action.payload.data.results;
      state.search_result = data.length > 0 ? ord(data, options) : [];
    },
  },
});
export default slice.reducer;
// Actions
const { searchSuccess, autocompleteSuccess } = slice.actions;

export const getAutocomplete = (term) => async (dispatch) => {
  try {
    const res = await api.post('/search/autocomplete', { term });
    dispatch(autocompleteSuccess(res));
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSearch = (keyword) => async (dispatch) => {
  try {
    const res = await api.post('/search', { keyword });
    dispatch(searchSuccess(res));
  } catch (e) {
    return console.error(e.message);
  }
};
