import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import { useDispatch } from 'react-redux';
import history from '../../assets/img/icons/history.png';
import { Empty } from '../../components';
import { Checkbox } from '@material-ui/core';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import { openModal } from '../../redux/slices/modals';

const ParentRequestsResponsiveTable = ({ cols, rows, setRows, rowsCount, emptyComponent }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const respoContEl = useRef(null);
  const tableContEl = useRef(null);
  const paginContEl = useRef(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableContHeight, setTableContHeight] = useState(0);
  const [paginContHeight, setPaginContHeight] = useState(0);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [approvedRequest, setApprovedRequest] = useState({ approved: null, id: null });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleUpdateParentRequest = (id, approved, name) => {
    dispatch(openModal('parent-rquest-confirm-modal', { id, name, approved, setApprovedRequest }));
    setApprovedRequest({ approved, id });
    setOpenSnackBar(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRows(+event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const updateTableDemonsions = (taH, PaH) => {
    respoContEl.current.style.height = `${taH + PaH - 16}px`;
    paginContEl.current.style.marginTop = `${taH}px`;
  };

  useLayoutEffect(() => {
    setTableContHeight(tableContEl.current.clientHeight);
    setPaginContHeight(paginContEl.current.clientHeight);
    updateTableDemonsions(tableContHeight, paginContHeight);
  });

  return (
    <div className="table-responsive" ref={respoContEl}>
      <TableContainer className="table-container" ref={tableContEl} component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {cols &&
                cols.map((header, index) => {
                  return <TableCell key={index + 1}>{header.label}</TableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.length > 0 ? (
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {cols &&
                      cols.length > 0 &&
                      cols.map((col, index) => {
                        return col.accessor === 'approved' ? (
                          <TableCell
                            key={index}
                            className={row['approved'] === null ? 'parent-requests-action' : ''}
                          >
                            {(row['approved'] || row['approved'] === null) && (
                              <div className="title">
                                <Checkbox
                                  disabled={row['approved']}
                                  style={{
                                    color: '#50bf88',
                                  }}
                                  onClick={() =>
                                    row['approved']
                                      ? null
                                      : handleUpdateParentRequest(
                                          row.parentID,
                                          1,
                                          row.name + ' ' + row.last_name
                                        )
                                  }
                                  checked={
                                    (approvedRequest.id === row.parentID &&
                                      approvedRequest.approved) ||
                                    row['approved']
                                  }
                                  className="button-check"
                                  icon={<CircleUnchecked />}
                                  checkedIcon={<CircleCheckedFilled />}
                                />
                                <span
                                  className="label"
                                  style={{
                                    color: row['approved'] && 'gray',
                                    textDecoration: row['approved'] && 'line-through',
                                  }}
                                >
                                  {row['approved'] === null
                                    ? t('Confirm')
                                    : row['approved']
                                    ? t('Action Confirmed')
                                    : null}
                                </span>
                              </div>
                            )}
                            {(row['approved'] === 0 ||
                              row['approved'] === false ||
                              row['approved'] === null) && (
                              <div className="title">
                                <Checkbox
                                  disabled={row['approved'] === 0 || row['approved'] === false}
                                  style={{
                                    color: '#fe726e',
                                  }}
                                  onClick={() =>
                                    row['approved'] !== null
                                      ? null
                                      : handleUpdateParentRequest(
                                          row.parentID,
                                          0,
                                          row.name + ' ' + row.last_name
                                        )
                                  }
                                  checked={
                                    (approvedRequest.id === row.parentID &&
                                      approvedRequest.approved === 0) ||
                                    row['approved'] === 0 ||
                                    row['approved'] === false
                                  }
                                  className="button-check"
                                  icon={<CircleUnchecked />}
                                  checkedIcon={<CircleCheckedFilled />}
                                />
                                <span
                                  className="label"
                                  style={{
                                    color:
                                      (row['approved'] === 0 || row['approved'] === false) &&
                                      'gray',
                                    textDecoration:
                                      (row['approved'] === 0 || row['approved'] === false) &&
                                      'line-through',
                                  }}
                                >
                                  {row['approved'] === null
                                    ? t('Reject')
                                    : row['approved'] === 0 || row['approved'] === false
                                    ? t('Action Rejected')
                                    : null}
                                </span>
                              </div>
                            )}
                          </TableCell>
                        ) : (
                          <TableCell key={index}>
                            <div>{row[col.accessor]}</div>
                          </TableCell>
                        );
                      })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell colSpan={(cols && cols.length) || 1} align={'center'}>
                  {
                    (emptyComponent = (
                      <Empty label={t('pas d historique')} icon={history} className="no-borders" />
                    ))
                  }
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        ref={paginContEl}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowsCount || rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t('Rows per page')}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')}  ${count}`}
      />
    </div>
  );
};

ParentRequestsResponsiveTable.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
};

export default ParentRequestsResponsiveTable;
