import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const OfferOoredooPeriods = (props) => {
  const { t } = useTranslation();
  const {
    color,
    pricings,
    setOoredooPeriod,
    setPricingIndex,
    selectedOoredooPeriod,
    width,
    classCard,
    daily,
  } = props;

  const ooredooPeriods = [
    { label: '1 jour', value: 'jour' },
    { label: '7 jours', value: '7jours' },
  ];
  return (
    <div className={`offer-periods ${classCard}`}>
      {ooredooPeriods &&
        ooredooPeriods.map((period, index) => {
          return (
            <div
              onClick={() => {
                setOoredooPeriod(period.label);
              }}
              className={
                selectedOoredooPeriod === period.label
                  ? period.label
                    ? 'period selected-period'
                    : 'last-period selected-period'
                  : period.label
                  ? 'period'
                  : 'last-period'
              }
              style={{
                backgroundColor: selectedOoredooPeriod === period.label ? color : null,
                borderColor: color,
                width: 70, //!pricing.quantity
                //   ? width <= 230
                //     ? ''
                //     : width <= 320 && width > 230
                //     ? width / 1.8 / pricings.length - 1
                //     : width / 1.2 / pricings.length - 1
                //   : width <= 230
                //   ? ''
                //   : width <= 320 && width > 230
                //   ? width / 2
                //   : width / 2.5,
              }}
            >
              {t(period.label)}{' '}
              {
                //!pricing.quantity
                !isNaN(period) && (
                  <div>
                    {period.label}
                    {/* { ((i18n.language === 'ar' || 'en') && (pricing.period)>=3) ? t("trois ou six mois"): t("mois")} */}
                  </div>
                )
              }
            </div>
          );
        })}
    </div>
  );
};

OfferOoredooPeriods.propTypes = {
  color: PropTypes.string,
  pricings: PropTypes.any,
  setOoredooPeriod: PropTypes.func,
  setPricingIndex: PropTypes.func,
  selectedOoredooPeriod: PropTypes.any,
};

export default OfferOoredooPeriods;
