import { useEffect, useState } from 'react';
import { getToken, isSupported } from 'firebase/messaging';
import { messaging } from '../utilities/firebase';

const useGetFcmToken = () => {
  const [token, setToken] = useState(null);
  useEffect(() => {
    const retrieveToken = async () => {
      try {
        const support = await isSupported();
        if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
          const permission = await Notification.requestPermission();
          if (permission === 'granted') {
            console.log('Notification permission granted.');

            const currentToken = await getToken(messaging, {
              vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
            });
            if (currentToken) {
              setToken(currentToken);
            } else {
              console.log('No registration token available.');
            }
          }
        }
      } catch (error) {
        console.error('Error getting FCM token:', error);
      }
    };
    retrieveToken();
  }, []);
  return token;
};
export default useGetFcmToken;
