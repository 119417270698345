import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const BorderLinearProgress = withStyles((theme) => ({
  root: (props) => ({
    height: 5,
    borderRadius: 5,
  }),
  colorPrimary: () => ({
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  }),
  bar: (props) => ({
    borderRadius: 5,
    backgroundColor: props.color,
  }),
}))(LinearProgress);

const Index = (props) => {
  const { subject, detailed,
    //progressValue,
    isLoading, newSubjectProgress } = props;

  return (
    <>
      {subject && (
        <>
          {detailed !== true ? (
            <Link
              to={`/subjects/${subject.slug}/details`}
              key={subject.id}
              style={{ textDecoration: 'none' }}
              className="MuiLink-root"
            >
              <Card className="ta-card">
                <CardContent className="subject-card">
                  <img src={subject.img} alt=".." />
                  <div className="title">
                    <span className="label">{subject.label}</span>
                   <span className="progress-nu">{`${newSubjectProgress > 100 ? 100 : newSubjectProgress}%`}</span>
                  </div>
                  {/* <BorderLinearProgress variant="determinate" value={progressValue} /> */}
                <BorderLinearProgress
                    variant="determinate"
                    value={newSubjectProgress > 100 ? 100 : newSubjectProgress}
                    color={subject.color ? subject.color : '#fe726e'}
                    style={{ backgroundColor: '#F1F1F2' }}
                  />
                </CardContent>
              </Card>
            </Link>
          ) : (
            <>
              <div className="title">
                <img src={subject.thumbnail} alt="....." />
                <span className="label">{subject.name}</span>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

Index.propTypes = {
  subject: PropTypes.object,
  detailed: PropTypes.bool,
  progressValue: PropTypes.any,
};

export default Index;
