import React from 'react';
import { useTranslation } from 'react-i18next';

const OfferPriceCards = ({
  subjects,
  color,
  totalPrice,
  selectedSubjects,
  offerPrice,
  offerBasePrice,
  subjectDetailsLength,
  discount,
  
}) => {
  const { t } = useTranslation();

  return (
    <div className="offer-price-cards">
      {subjects.map((subject) => {
        return (
          <div className="subject-card">
            {(selectedSubjects.includes(subject.id) || selectedSubjects.includes('all')) && (
              <>
                <div className="subject-name">{subject.subject}</div>
                <div className="subject-price" style={{ color: color }}>
                  {subject.price} {t('TND')}
                </div>
              </>
            )}
          </div>
        );
      })}
      <div
        className="subject-card"
        style={{
          backgroundColor: color,
          color: 'white',
        }}
      >
        <div className="subject-name" style={{ fontWeight: 'bold' }}>
          {t('Total')}
        </div>
        {
            ((selectedSubjects.includes('all')) ||(selectedSubjects.length===subjectDetailsLength)) &&  discount &&
            <div className="subject-price" >
              <span style={{color:'white', textDecoration: 'line-through', fontSize : '12px',}}>{offerBasePrice} TND</span>
              </div>

          }

        <div className="subject-price" style={{ color: 'white' }}>
          {selectedSubjects.includes('all') || selectedSubjects.length === subjectDetailsLength
            ? offerPrice
            : totalPrice}{' '}
          {t('TND')}
        </div>
      </div>
    </div>
  );
};

export default OfferPriceCards;
