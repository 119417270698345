import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { CardTitle, PaymentMethodsList, PointsTransferButton, TransferLink } from '../../components';

const Index = () => {
  const { t } = useTranslation();
  return (
    <Card className="ta-card wallet-services">
      <CardHeader title={<CardTitle title={t("services")} />} />
      <CardContent>
        <div className="child-full custom-services-child">
          <CardTitle title={t("charger mon compte")} className="dark-blue" />
          <PaymentMethodsList />
          <PointsTransferButton />
          <TransferLink />
        </div>
      </CardContent>
    </Card>
  );
};

export default Index;
