import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import useSettings from '../../hooks/useSettings';
import ar from '../../assets/img/icons/ar.svg';
import fr from '../../assets/img/icons/fr.svg';
import en from '../../assets/img/icons/en.svg';
import { Box } from '@material-ui/core';

const Index = ({ WhiteBox }) => {
  const { i18n } = useTranslation();
  const { settings } = useSettings();
  const [language, setLanguage] = useState('Français');

  const onClickFr = () => {
    i18n.changeLanguage('fr');
    if (settings.sidebar === 'full') {
      setLanguage('Français');
    }
    setLanguage(language);
  };
  const onClickEn = () => {
    i18n.changeLanguage('en-US');
    setLanguage('English');
  };
  const onClickAr = () => {
    i18n.changeLanguage('ar');
    setLanguage('العربية');
  };
  return (
    <div>
      {settings.sidebar === 'mini' && (
        <div className={'language-dev'}>
          <FormControl size="small">
            <Select
              disableUnderline
              className={WhiteBox ? 'languages-whiteBox' : 'languages'}
              labelId="language-label"
              value={
                i18n.language === 'fr'
                  ? 'Français'
                  : i18n.language === 'ar'
                  ? 'العربية'
                  : i18n.language === 'en-US'
                  ? 'English'
                  : language
              }
              onChange={(e) => setLanguage(e.target.value)}
            >
              <MenuItem
                disableGutters={true}
                value="العربية"
                className={'languages select'}
                onClick={() => i18n.changeLanguage('ar')}
              >
                <img className="flag" alt="ar" src={ar} />
                <span className="text">Ar </span>
              </MenuItem>
              <MenuItem
                disableGutters={true}
                value="Français"
                className={'languages select'}
                onClick={() => i18n.changeLanguage('fr')}
              >
                <img className="flag" alt="fr" src={fr} />
                <span className="text">Fr </span>
              </MenuItem>
              <MenuItem
                disableGutters={true}
                value="English"
                className={'languages select'}
                onClick={() => i18n.changeLanguage('en-US')}
              >
                <img className="flag" alt="en" src={en} />
                <span className="text">En </span>
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
      {settings.sidebar === 'full' && (
        <div className="languages-full">
          <span onClick={onClickFr}>
            <img
              className={
                i18n.language === 'fr'
                  ? 'flag-selected'
                  : i18n.language === 'en-US' || i18n.language === 'ar'
                  ? 'flag'
                  : 'flag-selected'
              }
              alt="fr"
              src={fr}
            />
          </span>
          <span onClick={onClickEn}>
            <img
              className={i18n.language === 'en-US' ? 'flag-selected' : 'flag'}
              alt="en-US"
              src={en}
            />
          </span>
          <span onClick={onClickAr}>
            <img className={i18n.language === 'ar' ? 'flag-selected' : 'flag'} alt="ar" src={ar} />
          </span>
        </div>
      )}
    </div>
  );
};

export default Index;
