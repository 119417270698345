import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { CardTitle, ResponsiveTable } from '../../components';
import { subscriptionsHistory } from '../../redux/slices/userHistory';
import { useDispatch, useSelector } from 'react-redux';

const Index = () => {
  const { t } = useTranslation();
  const { subscriptionList } = useSelector((state) => state.userHistory);
  const dispatch = useDispatch();
  const headers = [
    { accessor: 'name', label: t('Offre') },
    { accessor: 'price', label: t('Prix') },
    { accessor: 'start_date', label: t('Date de début'), type: 'date' },
    { accessor: 'end_date', label: t('Date de fin'), type: 'date' },
  ];
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    dispatch(subscriptionsHistory());
  }, []);

  React.useEffect(() => {
    if (subscriptionList) {
      setData(subscriptionList || []);
    }
  }, [subscriptionList]);

  return (
    <Card className="ta-card">
      <CardHeader title={<CardTitle title={t('historique des abonnements')} />} />
      <CardContent>
        <div className="child-full">
          <ResponsiveTable cols={headers} rows={data} />
        </div>
      </CardContent>
    </Card>
  );
};

export default Index;
