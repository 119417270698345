import React from 'react';
import Box from '@material-ui/core/Box';
import {CircularProgress} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const Index = (props) => {
  return (
      <Box position="relative" display="inline-flex" title='نسبة استعمالك للدقائق المجانية'>
        <CircularProgress variant="determinate" {...props} className='circular-progress'/>
        <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
          <Typography variant="caption" component="div"
                      color="textSecondary">{`${Math.round(
              props.value,
          )}%`}</Typography>
        </Box>
      </Box>
  );
};
export default Index;