import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import parse from 'html-react-parser';

const OfferSubjectDetails = ({name, description, color,handleSelectSubject,selectedSubjects,id , price ,offers, subjectDetailsLength, disabledOption}) => {
  
  return (

      <div className="offers-card">
        <div className="offer-subject-list-container">
          <div className="box">
            <div className="title">
              <Checkbox
                  disabled={disabledOption}
                  style={{
                    color: color,
                  }}
                  onClick={()=>handleSelectSubject(id,price)}
                  checked={selectedSubjects?.includes(id) || selectedSubjects?.includes('all') || (selectedSubjects.length === subjectDetailsLength)}
                  className="button-check"
                  icon={<CircleUnchecked/>}
                  checkedIcon={<CircleCheckedFilled/>}
              />
              <span className="label" style={{color: disabledOption && 'gray' , textDecoration: disabledOption && 'line-through'}}>{name}</span>
            </div>
            <div className="description">
              {description ? parse(description) : ''}
            </div>
          </div>
        </div>
      </div>

  );
};

export default OfferSubjectDetails;
