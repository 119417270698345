import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BookmarksWidget,
  ForumWidget,
  FreeVideosWidget,
  MyProgressWidget,
  ProfileWidget,
  PubsCards,
  TodayEventsWidget,
  WarningWidget,
} from '../../components';
import { getSubscriptionWarning } from '../../redux/slices/user';
import WelcomeModel from '../../components/WelcomeModal';
import { getPubsCards } from '../../redux/slices/offers';

const Index = () => {
  const dispatch = useDispatch();

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [open, setOpen] = useState(false);
  const { warning, informations, permissions } = useSelector((state) => state.user);
  const { pubsCards, pubsCardsStatus } = useSelector((state) => state.offers);

  const offerFreeId = 104;
  const offerFreeCondition = informations?.offers?.filter(
    (offer) => offer.group.id === offerFreeId // this condition is temporary for offre free//
  ).length;

  useEffect(() => {
    dispatch(getSubscriptionWarning());
  }, []);

  useEffect(() => {
    if (pubsCardsStatus === 'idle' && pubsCards?.length < 1) {
      dispatch(getPubsCards());
    }
  }, [pubsCards]);

  useEffect(() => {
    const clientType = sessionStorage.getItem('clientType');

    if (clientType) {
      setOpen(true);
    }
  }, []);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const useWindowDimensions = () => {
    useEffect(() => {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
  };
  return (
    <div className="full-page">
      <div className="ta-row">
        <div className="ta-column right-margin layout-home">
          <div className="child-left">
            {warning && !!Object.keys(warning)?.length && <WarningWidget warning={warning} />}
            {useWindowDimensions(windowDimensions).width <= 600 && <ProfileWidget mini />}
            <TodayEventsWidget />
            {offerFreeCondition === 0 && <BookmarksWidget />}
            <FreeVideosWidget />
          </div>
          <div className="child-right">
            <MyProgressWidget />
            <ForumWidget />
            {useWindowDimensions(windowDimensions).width <= 1199 && (
              <div>
                {pubsCards
                  ?.slice()
                  ?.sort((a, b) => a.position - b.position)
                  ?.map((card) => {
                    return (
                      <PubsCards
                        key={card?.id}
                        className={card?.type}
                        icon={card?.icon_url}
                        text={card?.text_image_url || card?.text}
                        isText={card?.text}
                        modal={card?.video_link != null}
                        path={card?.video_link || card?.url_link}
                        bg={card?.background_url}
                        visible={card?.visible}
                        iconPosition={card?.icon_position}
                        color={card?.color}
                      />
                    );
                  })}
              </div>
            )}
          </div>
        </div>
        <div className="ta-column fixed right">
          <ProfileWidget mini />
          {pubsCards
            ?.slice()
            ?.sort((a, b) => a.position - b.position)
            ?.map((card) => {
              return (
                <PubsCards
                  key={card?.id}
                  className={card?.type}
                  icon={card?.icon_url}
                  text={card?.text_image_url || card?.text}
                  isText={card?.text}
                  modal={card?.video_link != null}
                  path={card?.video_link || card?.url_link}
                  bg={card?.background_url}
                  visible={card?.visible}
                  iconPosition={card?.icon_position}
                  color={card?.color}
                />
              );
            })}
        </div>
      </div>
      <WelcomeModel open={open} setOpen={setOpen} />
    </div>
  );
};

export default Index;
