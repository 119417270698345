import React, { useCallback, useState } from 'react';
import copyImg from '../../../../assets/img/icons/copy.svg';
import { Button, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CopyCodeField = ({ copied, setCopied }) => {
  const [isHovered, seIsHovered] = useState(false);
  const { t } = useTranslation();

  const { informations } = useSelector((state) => state.user);

  const CopyToClipboard = () => {
    var copyText = document.getElementById('myInput');
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    navigator.clipboard.writeText(copyText.value);
    setCopied(true);
  };

  const hoverEnter = () => {
    seIsHovered(true);
  };

  const hoverLeave = () => {
    seIsHovered(false);
  };

  return (
    <div
      className={
        isHovered
          ? `parrain-page-parrain-card-content-code-field-wrapper active`
          : 'parrain-page-parrain-card-content-code-field-wrapper'
      }
    >
      <input
        className="parrain-page-parrain-card-content-code-field"
        id="myInput"
        value={informations?.gift_code}
        disabled
      />
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={
          <Typography
            color="primary"
            className="parrain-page-parrain-card-content-code-field-copy-btn-tooltip"
          >
            {copied ? t('copied') : t('Copy')}
          </Typography>
        }
      >
        <Button
          onMouseEnter={hoverEnter}
          onMouseLeave={hoverLeave}
          variant="text"
          className="parrain-page-parrain-card-content-code-field-copy-btn-wrapper"
          disabled={!informations?.gift_code}
          onClick={() => CopyToClipboard()}
        >
          <div className="parrain-page-parrain-card-content-code-field-copy-btn">
            <img src={copyImg} alt="copy-icon" />
          </div>
        </Button>
      </Tooltip>
    </div>
  );
};

export default CopyCodeField;
