import React, { useState } from 'react';
import CopyCodeField from '../CopyCodeField';
import shareImg from '../../../../assets/img/icons/share.svg';
import fbIcon from '../../../../assets/img/icons/fb.svg';
import instagramIcon from '../../../../assets/img/icons/insta.svg';
import twitterIcon from '../../../../assets/img/icons/twitter.svg';

import './_index.scss';
import { Popover } from '@mui/material';
import SocialMediaItem from '../SocialMediaItem';
import { useTranslation } from 'react-i18next';
import useSettings from '../../../../hooks/useSettings';

const socialMedia = [
  { icon: fbIcon, name: 'Facebook' },
  { icon: instagramIcon, name: 'Instagram' },
  { icon: twitterIcon, name: 'Twitter' },
];
const CopyCodeCard = () => {
  const [copied, setCopied] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const { t } = useTranslation();
  const { settings } = useSettings();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div className={settings.sidebar === 'full' ? 'copy-code-card-full-sidebar' : 'copy-code-card'}>
      <div className="copy-code-card-header">
        <div
          className={
            settings.sidebar === 'full'
              ? 'copy-code-card-header-title-full-sidebar'
              : 'copy-code-card-header-title'
          }
        >
          {t('veuillez copier votre code de parrainage')}
        </div>
      </div>
      <div className="copy-code-card-content">
        <CopyCodeField copied={copied} setCopied={setCopied} />

        {/* <div
          className="copy-code-card-share-code-btn"
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
        >
          <img src={shareImg} alt="share-icon" />
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          className="parrain-social-media-list"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          {socialMedia.map((el, index) => (
            <SocialMediaItem icon={el.icon} name={el.name} key={index} />
          ))}
        </Popover> */}
      </div>
    </div>
  );
};

export default CopyCodeCard;
