import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {CardTitle, TransactionsResponsiveTable} from '..';
import {useDispatch, useSelector} from 'react-redux';
import {getSessionsHistory} from '../../redux/slices/sessions';

const Index = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {historySessions} = useSelector((state) => state.sessions);
  const [rows, setRows] = useState(10);
  const [rowsLength, setRowsLength] = useState(10);
  const [sortOrder, setOrder] = useState('asc');
  const [sortName, setSortName] = useState('start_time');
  const headers = [
    {accessor: 'startTime', label: t('Date'), type: 'date'},
    {accessor: 'name', label: t('Nom')},
   // {accessor: 'subject', label: t('Matière')},
    {accessor: 'duration', label: t('Durée de la participation'), type: 'time'},
  ];
  const [data, setData] = React.useState([]);
  const handleSort = (value, order) => {
    if(value === sortName) {
      setOrder(order === 'asc'? 'desc':'asc');
    }
    else {
      setSortName(value);
    }
  };
  React.useEffect(() => {
    dispatch(getSessionsHistory(rows, sortOrder, sortName));
  }, [rows, sortName, sortOrder]);

  React.useEffect(() => {
    if (historySessions) {
      setData(historySessions || []);
      setRowsLength(historySessions.rateablescount)
      setRows(historySessions.rateablescount)
    }
  }, [historySessions]);

  return (
      <Card className="ta-card">
        <CardHeader
            title={<CardTitle title={t("historique des sessions en direct")}/>}/>
        <CardContent>
          <div className="child-full">
            <TransactionsResponsiveTable cols={headers} rows={data}
                                         setRows={setRows}
                                         isSessionTable={true} sort={sortOrder}
                                         handleSort={handleSort}
                                         rowsCount={rowsLength}
                                         sortName={sortName}/>
          </div>
        </CardContent>
      </Card>
  );
};

export default Index;
