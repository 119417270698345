import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import EmptyCartImg from '../../../assets/img/no-coins-1.png';
import NoCoins from '../components/NotCoins';
import NotFound from '../../NotFound';

const EmptyCart = () => {
  const { informations, permissions } = useSelector((state) => state.user);
  useEffect(() => {
    if (
      permissions &&
      permissions?.eligibleUserForGift === true &&
      informations &&
      !informations.gift_code
    ) {
      window.location.href = '/generate-gift-code';
    }
  }, [informations, permissions]);

  return permissions?.eligibleUserForGift === true ? (
    <NoCoins description="Your cart is currently empty" img={EmptyCartImg} />
  ) : (
    <NotFound />
  );
};

export default EmptyCart;
