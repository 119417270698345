import Im_Accueil from '../assets/img/icons/home.svg';
import Im_Profile from '../assets/img/icons/user.svg';
import Im_Offres from '../assets/img/icons/gem.svg';
import Im_Matieres from '../assets/img/icons/book.svg';
import Im_Examens from '../assets/img/icons/graduation.svg';
import Im_Direct from '../assets/img/icons/cast.svg';
import Im_Forum from '../assets/img/icons/chat.svg';
import Im_Porte from '../assets/img/icons/wallet.svg';
import Im_Agent from '../assets/img/icons/agent.png';
import Im_Facebook from '../assets/img/icons/facebook.svg';
import Im_Instagram from '../assets/img/icons/instagram.svg';
import Im_Youtube from '../assets/img/icons/youtube.svg';
import Im_Money from '../assets/img/icons/revenu.png';
import Im_Gift from '../assets/img/icons/parrainage.svg';

import Im_MoneyBag from '../assets/img/icons/money-bag.svg';
import Im_D17 from '../assets/img/icons/d17.png';
import Im_CreditCard from '../assets/img/icons/credit-card.svg';

import Im_Calculator from '../assets/img/icons/calculator.svg';
import Im_Plant from '../assets/img/icons/plant.svg';
import Im_Science from '../assets/img/icons/science.svg';
import Im_Lightbulb from '../assets/img/icons/lightbulb.svg';

import exercisesImg from '../assets/img/icons/exercises.svg';
import videosImg from '../assets/img/icons/videos.svg';
import magazinesImg from '../assets/img/icons/magazines.svg';
import coursesImg from '../assets/img/icons/courses.svg';

import boxImg from '../assets/img/icons/box.svg';
import offerImg from '../assets/img/icons/offer.svg';

export default {
  HEADERS_API: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  DRAWER_WIDTH: 280,
  DRAWER_WIDTH_MINI: 80,
  SIDEBAR_LIST: [
    {
      id: 1,
      icon: Im_Accueil,
      name: 'Accueil',
      url: '/',
      link: false,
      permission: false,
    },
    {
      id: 2,
      icon: Im_Profile,
      name: 'Mon profil',
      url: '/mon-profil',
      link: false,
      permission: false,
    },
    {
      id: 3,
      icon: Im_Offres,
      name: 'Offres',
      url: '/offres',
      link: false,
      permission: false,
    },
    {
      id: 4,
      icon: Im_Matieres,
      name: 'Matières',
      url: '/subjects',
      link: false,
      permission: false,
    },
    {
      id: 5,
      icon: Im_Examens,
      name: 'Examens',
      url: '/exams',
      link: false,
      permission: true,
      permission_key: 'HAS_EXAM',
    },
    {
      id: 6,
      icon: Im_Direct,
      name: 'En direct',
      url: '/sessions',
      link: false,
      permission: false,
    },
    {
      id: 7,
      icon: Im_Forum,
      name: 'Le forum',
      url: 'https://forum.takiacademy.com',
      link: true,
      permission: true,
      permission_key: 'HAS_FORUM',
    },
    {
      id: 8,
      icon: Im_Porte,
      name: 'Porte monnaie',
      url: '/porte-monnaie',
      link: false,
      permission: false,
    },
    {
      id: 9,
      icon: Im_Agent,
      name: 'Assistance',
      url: '/assistance',
      link: false,
      permission: false,
    },
    {
      id: 10,
      icon: Im_Money,
      name: 'Revenu',
      url: '/revenu',
      link: false,
      permission: true,
      permission_key: 'IS_INFLUENCER',
    },
    {
      id: 11,
      icon: Im_Gift,
      name: 'Parrain',
      url: '/generate-gift-code',
      link: false,
      permission: true,
      permission_key: 'IS_ELIGIBLE_FOR_GIFT',
      related_urls: [
        '/parrain/confirm-order',
        '/parrain/products',
        '/convert-coins',
        '/parrain/purchase-history',
        '/parrain/empty-cart',
        '/parrain/no-coins',
      ],
    },
  ],
  SOCIALS_LIST: [
    {
      name: 'Facebook',
      img: Im_Facebook,
      url: 'https://www.facebook.com/takiacademy',
    },
    {
      name: 'Instagram',
      img: Im_Instagram,
      url: 'https://www.instagram.com/takiacademy',
    },
    {
      name: 'Youtube',
      img: Im_Youtube,
      url: 'https://www.youtube.com/takiacademy',
    },
  ],
  BANK_ACCOUNTS: [
    {
      name: 'Banque zitouna',
      details: [
        {
          owner: 'TakiAcademy',
          number: '25 006 0000000317041 86',
        },
      ],
    },
    {
      name: 'Banque biat',
      details: [
        {
          owner: 'TakiAcademy',
          number: '08 139 0310110000790 88',
        },
      ],
    },
    {
      name: 'La poste tunisienne',
      details: [
        {
          owner: 'TakiAcademy',
          number: '1750 3000 0003 1179 2828',
        },
      ],
    },
  ],
  POINTS: 78,
  PAYMENT_METHODS: [
    {
      id: 'transfer',
      name: 'Versement bancaire',
      img: Im_MoneyBag,
      color: 'color-1',
      modal: null,
    },
    {
      id: 'd17',
      name: 'D17',
      img: Im_D17,
      color: 'color-2',
      modal: null,
    },
    {
      id: 'online',
      name: 'Paiement en ligne',
      img: Im_CreditCard,
      color: 'color-3',
      modal: null,
    },
  ],
  POINTS_HISTORY: {
    cols: [
      { accessor: 'code', label: 'Code' },
      { accessor: 'service', label: 'Mode de paiement' },
      { accessor: 'amount', label: 'Montant en dinars' },
      { accessor: 'desc', label: 'Description' },
      { accessor: 'date', label: 'Date' },
      { accessor: 'status', label: 'Statut' },
    ],
    rows: [
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
      {
        code: 'TT0000001',
        service: 'Banque Zitouna',
        amount: 80,
        desc: 'null',
        date: '01/01/2020',
        status: 'Terminé',
      },
    ],
  },
  TODAY_EVENTS: [
    {
      title: null,
      description:
        "Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte aléatoire.",
      teacher: 'Ben Khalifa Skander',
      date: null,
      time: '15:00',
    },
    {
      title: null,
      description:
        "Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte aléatoire.",
      teacher: 'Hachem Charfi',
      date: null,
      time: '18:00',
    },
  ],
  MY_PROGRESS: [
    {
      icon: Im_Calculator,
      color: 'bg-orange',
      label: 'Maths',
      progress: 47,
    },
    {
      icon: Im_Science,
      color: 'bg-green',
      label: 'Physiqueee',
      progress: 85,
    },
    {
      icon: Im_Plant,
      color: 'bg-purple',
      label: 'SVT',
      progress: 67,
    },
    {
      icon: Im_Lightbulb,
      color: 'bg-orange',
      label: 'Technique',
      progress: 32,
    },
    // {
    //   icon: Im_Arabic,
    //   color: 'bg-green',
    //   label: 'Arabe',
    //   progress: 90,
    // },
  ],
  NOTIFICATION_PREFERENCES_LIST: [
    {
      id: 1,
      name: 'completeInformation',
      value: 'Complete your information',
      family: 'General',
    },
    { id: 8, name: 'giftCodeUsed', value: 'Gift Code', family: 'General' },
    {
      id: 9,
      name: 'parentRequest',
      value: 'Submit a parent request',
      family: 'General',
    },
    {
      id: 10,
      name: 'changePassword',
      value: 'Change your password',
      family: 'General',
    },

    {
      id: 2,
      name: 'uploadNewSessionRecord',
      value: 'Upload a new session record',
      family: 'Contents',
    },
    {
      id: 3,
      name: 'uploadNewContent',
      value: 'Upload new content',
      family: 'Contents',
    },

    {
      id: 4,
      name: 'updateSession',
      value: 'Update session details',
      family: 'Sessions',
    },
    {
      id: 5,
      name: 'updateVideoReport',
      value: 'Update video report',
      family: 'Sessions',
    },

    {
      id: 6,
      name: 'paySlice',
      value: 'Complete a payment slice',
      family: 'Payment',
    },
    {
      id: 7,
      name: 'transferPoint',
      value: 'Transfer points',
      family: 'Payment',
    },
  ],
  NOTIFICATIONS_ICONS: [
    {
      id: 1,
      title: 'Complete account information',
      icon: Im_Profile,
    },
    { id: 8, title: 'Gift Code', icon: Im_Gift },
    {
      id: 9,
      title: 'Parent Request',
      icon: Im_Profile,
    },
    {
      id: 10,
      title: 'Change Password',
      icon: Im_Profile,
    },

    {
      id: 2,
      title: 'Record Live Session',
      icon: Im_Matieres,
    },
    {
      id: 3,
      title: 'New Content',
      icon: Im_Matieres,
    },

    {
      id: 4,
      title: 'Live Session Updated',
      icon: Im_Direct,
    },
    {
      id: 5,
      title: 'Video Report',
      icon: Im_Agent,
    },

    {
      id: 6,
      title: 'Pay slice',
      icon: Im_Offres,
    },
    {
      id: 7,
      title: 'Transfert Point',
      icon: Im_Porte,
    },
  ],
};
export const calenderTimes = [
  { time: '' },
  { time: '08:00' },
  { time: '09:00' },
  { time: '10:00' },
  { time: '11:00' },
  { time: '12:00' },
  { time: '13:00' },
  { time: '14:00' },
  { time: '15:00' },
  { time: '16:00' },
  { time: '17:00' },
  { time: '18:00' },
  { time: '19:00' },
  { time: '20:00' },
  { time: '21:00' },
  { time: '22:00' },
  { time: '23:00' },
];
export const days = [
  { index: 0 },
  { index: 1 },
  { index: 2 },
  { index: 3 },
  { index: 4 },
  { index: 5 },
  { index: 6 },
  { index: 7 },
];

export const optionsCards = [
  { id: 0, icon: exercisesImg, title: 'تمارين مع الإصلاح' },
  { id: 1, icon: videosImg, title: 'فيديوهات تفسير الدروس' },
  { id: 2, icon: magazinesImg, title: 'PDF Magazines' },
  { id: 3, icon: coursesImg, title: 'مجلات تلاخيص الدروس' },
];
export const offerCards = [
  {
    id: 872,
    level: 'الابتدائي',
    divisions: [4, 5, 6],
    perDay: '0.95',
    perWeek: '7.75',
    bgColor: '#4FB552',
    borderColor: '#3D963F',
    divisionsLabels: ['الرابعة إبتدائي', 'الخامسة إبتدائي', 'السادسة إبتدائي'],
  },
  {
    id: 291,
    level: 'الأساسي',
    divisions: [7, 8, 9],
    perDay: '1.6',
    perWeek: '12.25',
    bgColor: '#EA8121',
    borderColor: '#B76315',
    divisionsLabels: ['السابعة أساسي', 'الثامنة أساسي', 'التاسعة أساسي'],
  },
  {
    id: 863,
    level: 'الثانوي',
    divisions: [1, 2, 3],
    perDay: '1.6',
    perWeek: '12.25',
    bgColor: '#2199CF',
    borderColor: '#1881B0',
    divisionsLabels: ['الأولى ثانوي', 'الثانية ثانوي', 'الثالثة ثانوي'],
  },
  {
    id: 147,
    level: 'البكالوريا',
    divisions: [],
    perDay: '2.3',
    perWeek: '17.25',
    bgColor: '#004F8B',
    borderColor: '#0080E0',
    divisionsLabels: [
      'بكالوريا رياضيات',
      'بكالوريا  آداب',
      'بكالوريا  علوم تجريبية',
      'بكالوريا  علوم تقنية',
      'بكالوريا  علوم إعلامية',
      'بكالوريا علوم اقتصاد و تصرف',
    ],
  },
];
export const levelValues = ['primaire', 'base', 'secondaire', 'bac'];
export const cardDefaultHour = 1.6;

export const calenderMobileWidth = 599;
export const offerCardMaxWidth = 463.25;
export const offerCardMaxHeight = 850;
export const recGeneralRec = 'Général';
export const months = [
  { label: 'Jan', value: 'January' },
  { label: 'Feb', value: 'February' },
  { label: 'Mar', value: 'March' },
  { label: 'Apr', value: 'April' },
  { label: 'May', value: 'May' },
  { label: 'Jun', value: 'June' },
  { label: 'Jul', value: 'July' },
  { label: 'Aug', value: 'August' },
  { label: 'Sep', value: 'September' },
  { label: 'Oct', value: 'October' },
  { label: 'Nov', value: 'November' },
  { label: 'Dec', value: 'December' },
];

export const ooredooPageSliderBreakpoints = {
  '(max-width: 1300px)': {
    slides: {
      perView: 3.5,
      spacing: 30,
    },
  },
  '(max-width: 1260px)': {
    slides: {
      perView: 3.4,
      spacing: 5,
    },
  },
  '(max-width: 1183px)': {
    slides: {
      perView: 3.1,
      spacing: 5,
    },
  },
  '(max-width: 1118px)': {
    slides: {
      perView: 2.9,
      spacing: 5,
    },
  },
  '(max-width: 1030px)': {
    slides: {
      perView: 2.7,
      spacing: 5,
    },
  },
  '(max-width: 990px)': {
    slides: {
      perView: 2.5,
      spacing: 5,
    },
  },

  '(max-width: 919px)': {
    slides: {
      perView: 2.4,
      spacing: 5,
    },
  },
  '(max-width: 881px)': {
    slides: {
      perView: 2.3,
      spacing: 5,
    },
  },
  '(max-width: 855px)': {
    slides: {
      perView: 2.2,
      spacing: 5,
    },
  },
  '(max-width: 799px)': {
    slides: {
      perView: 2,
      spacing: 5,
    },
  },
  '(max-width: 730px)': {
    slides: {
      perView: 1.9,
      spacing: 5,
    },
  },
  '(max-width: 704px)': {
    slides: {
      perView: 1.8,
      spacing: 5,
    },
  },
  '(max-width: 664px)': {
    slides: {
      perView: 1.7,
      spacing: 5,
    },
  },
  '(max-width: 635px)': {
    slides: {
      perView: 1.6,
      spacing: 5,
    },
  },
  '(max-width: 598px)': {
    slides: {
      perView: 1.5,
      spacing: 5,
    },
  },
  '(max-width: 565px)': {
    slides: {
      perView: 1.4,
      spacing: 5,
    },
  },
  '(max-width: 537px)': {
    slides: {
      perView: 1.3,
      spacing: 5,
    },
  },
  '(max-width: 500px)': {
    slides: {
      perView: 1.2,
      spacing: 5,
    },
  },
  '(max-width: 470px)': {
    slides: {
      perView: 1.3,
      spacing: 5,
    },
  },
  '(max-width: 430px)': {
    slides: {
      perView: 1.2,
      spacing: 5,
    },
  },
  '(max-width: 407px)': {
    slides: {
      perView: 1.2,
      spacing: 5,
    },
  },
  '(max-width: 397px)': {
    slides: {
      perView: 1.15,
      spacing: 5,
    },
  },
  '(max-width: 383px)': {
    slides: {
      perView: 1.1,
      spacing: 5,
    },
  },
  '(max-width: 380px)': {
    slides: {
      perView: 1.17,
      spacing: 5,
    },
  },
  '(max-width: 347px)': {
    slides: {
      perView: 1.1,
      spacing: 2,
    },
  },
  '(max-width: 329px)': {
    slides: {
      perView: 1.07,
      spacing: 2,
    },
  },
  '(max-width: 319px)': {
    slides: {
      perView: 1,
      spacing: 5,
    },
  },
};

export const parrainSliderBreakpoints = {
  '(max-width: 1600px)': {
    slides: {
      perView: 3.4,
    },
  },
  '(max-width: 1580px)': {
    slides: {
      perView: 2.9,
    },
  },
  '(max-width: 1515px)': {
    slides: {
      perView: 2.7,
    },
  },
  '(max-width: 1488px)': {
    slides: {
      perView: 2.5,
    },
  },

  '(max-width: 1420px)': {
    slides: {
      perView: 2.2,
    },
  },

  '(max-width: 1270px)': {
    slides: {
      perView: 1.8,
    },
  },

  '(max-width: 1189px)': {
    slides: {
      perView: 3.4,
    },
  },

  '(max-width: 1080px)': {
    slides: {
      perView: 2.7,
    },
  },
  '(max-width: 1060px)': {
    slides: {
      perView: 2.5,
    },
  },

  '(max-width: 850px)': {
    slides: {
      perView: 2.2,
    },
  },

  '(max-width: 770px)': {
    slides: {
      perView: 2,
    },
  },

  '(max-width: 720px)': {
    slides: {
      perView: 1.8,
    },
  },
  '(max-width: 700px)': {
    slides: {
      perView: 1.5,
    },
  },
  '(max-width: 660px)': {
    slides: {
      perView: 1.6,
    },
  },

  '(max-width: 599px)': {
    slides: {
      perView: 1.8,
    },
  },
  '(max-width: 522px)': {
    slides: {
      perView: 1.6,
    },
  },
  '(max-width: 505px)': {
    slides: {
      perView: 1.8,
    },
  },
  '(max-width: 435px)': {
    slides: {
      perView: 1.6,
    },
  },
  '(max-width: 394px)': {
    slides: {
      perView: 1.5,
    },
  },
  '(max-width: 370px)': {
    slides: {
      perView: 1.4,
    },
  },
  '(max-width: 360px)': {
    slides: {
      perView: 1.3,
    },
  },
  '(max-width: 335px)': {
    slides: {
      perView: 1.2,
    },
  },
  '(max-width: 325px)': {
    slides: {
      perView: 1.1,
    },
  },
};
export const parrainSliderBeakpointsFullSidebar = {
  '(max-width: 1873px)': {
    slides: {
      perView: 3.2,
    },
  },
  '(max-width: 1806px)': {
    slides: {
      perView: 3,
    },
  },
  '(max-width: 1740px)': {
    slides: {
      perView: 2.8,
    },
  },
  '(max-width: 1630px)': {
    slides: {
      perView: 2.2,
    },
  },

  '(max-width: 1560px)': {
    slides: {
      perView: 2.2,
    },
  },

  '(max-width: 1500px)': {
    slides: {
      perView: 2,
    },
  },

  '(max-width: 1434px)': {
    slides: {
      perView: 1.8,
    },
  },
  '(max-width: 1376px)': {
    slides: {
      perView: 1.7,
    },
  },

  '(max-width: 1292px)': {
    slides: {
      perView: 1.6,
    },
  },
  '(max-width: 1288px)': {
    slides: {
      perView: 1.8,
    },
  },

  '(max-width: 1192px)': {
    slides: {
      perView: 1.6,
    },
  },
  '(max-width: 1189px)': {
    slides: {
      perView: 3.1,
    },
  },
  '(max-width: 1167px)': {
    slides: {
      perView: 2.9,
    },
  },

  '(max-width: 1028px)': {
    slides: {
      perView: 2.8,
    },
  },

  '(max-width: 975px)': {
    slides: {
      perView: 2.4,
    },
  },
  '(max-width: 873px)': {
    slides: {
      perView: 2.2,
    },
  },

  '(max-width: 823px)': {
    slides: {
      perView: 2.1,
    },
  },
  '(max-width: 814px)': {
    slides: {
      perView: 1.9,
    },
  },

  '(max-width: 740px)': {
    slides: {
      perView: 1.6,
    },
  },
  '(max-width: 687px)': {
    slides: {
      perView: 1.4,
    },
  },

  '(max-width: 656px)': {
    slides: {
      perView: 1.2,
    },
  },
  '(max-width: 630px)': {
    slides: {
      perView: 1.1,
    },
  },
  '(max-width: 610px)': {
    slides: {
      perView: 1.07,
    },
  },
  '(max-width: 599px)': {
    slides: {
      perView: 2.4,
    },
  },
  '(max-width: 558px)': {
    slides: {
      perView: 2.2,
    },
  },
  '(max-width: 499px)': {
    slides: {
      perView: 1.9,
    },
  },
  '(max-width: 450px)': {
    slides: {
      perView: 1.7,
    },
  },
  '(max-width: 410px)': {
    slides: {
      perView: 1.5,
    },
  },
  '(max-width: 375px)': {
    slides: {
      perView: 1.4,
    },
  },
  '(max-width: 363px)': {
    slides: {
      perView: 1.2,
    },
  },
  '(max-width: 335px)': {
    slides: {
      perView: 1.15,
    },
  },
};

export const giftCodeSliderBreakpoints = {
  '(max-width: 1590px)': {
    slides: {
      perView: 3.3,
    },
  },
  '(max-width: 1515px)': {
    slides: {
      perView: 3,
    },
  },
  '(max-width: 1437px)': {
    slides: {
      perView: 2.6,
    },
  },
  '(max-width: 1408px)': {
    slides: {
      perView: 2.5,
    },
  },
  '(max-width: 1355px)': {
    slides: {
      perView: 2.3,
    },
  },
  '(max-width: 1324px)': {
    slides: {
      perView: 2.2,
    },
  },
  '(max-width: 1290px)': {
    slides: {
      perView: 2.1,
    },
  },
  '(max-width: 1259px)': {
    slides: {
      perView: 2,
    },
  },
  '(max-width: 1243px)': {
    slides: {
      perView: 1.7,
    },
  },

  '(max-width: 1199px)': {
    slides: {
      perView: 1.9,
    },
  },
  '(max-width: 1189px)': {
    slides: {
      perView: 3.3,
    },
  },
  '(max-width: 1128px)': {
    slides: {
      perView: 3.1,
    },
  },
  '(max-width: 1080px)': {
    slides: {
      perView: 3,
    },
  },

  '(max-width: 975px)': {
    slides: {
      perView: 2.8,
    },
  },
  '(max-width: 900px)': {
    slides: {
      perView: 2.6,
    },
  },
  '(max-width: 860px)': {
    slides: {
      perView: 2.4,
    },
  },
  '(max-width: 840px)': {
    slides: {
      perView: 2.2,
    },
  },
  '(max-width: 788px)': {
    slides: {
      perView: 2,
    },
  },
  '(max-width: 734px)': {
    slides: {
      perView: 1.8,
    },
  },
  '(max-width: 715px)': {
    slides: {
      perView: 1.7,
    },
  },
  '(max-width: 690px)': {
    slides: {
      perView: 1.6,
    },
  },
  '(max-width: 664px)': {
    slides: {
      perView: 1.4,
    },
  },
  '(max-width: 614px)': {
    slides: {
      perView: 1.6,
    },
  },

  '(max-width: 599px)': {
    slides: {
      perView: 1.8,
    },
  },
  '(max-width: 522px)': {
    slides: {
      perView: 1.6,
    },
  },
  '(max-width: 505px)': {
    slides: {
      perView: 1.8,
    },
  },
  '(max-width: 435px)': {
    slides: {
      perView: 1.6,
    },
  },
  '(max-width: 394px)': {
    slides: {
      perView: 1.5,
    },
  },
  '(max-width: 370px)': {
    slides: {
      perView: 1.4,
    },
  },
  '(max-width: 360px)': {
    slides: {
      perView: 1.3,
    },
  },
  '(max-width: 335px)': {
    slides: {
      perView: 1.2,
    },
  },
  '(max-width: 325px)': {
    slides: {
      perView: 1.1,
    },
  },
};
export const giftCodeSliderBeakpointsFullSidebar = {
  '(max-width: 1717px)': {
    slides: {
      perView: 3.4,
    },
  },
  '(max-width: 1655px)': {
    slides: {
      perView: 3.2,
    },
  },
  '(max-width: 1630px)': {
    slides: {
      perView: 3,
    },
  },
  '(max-width: 1550px)': {
    slides: {
      perView: 2.8,
    },
  },
  '(max-width: 1520px)': {
    slides: {
      perView: 2.6,
    },
  },

  '(max-width: 1500px)': {
    slides: {
      perView: 1.9,
    },
  },

  '(max-width: 1460px)': {
    slides: {
      perView: 1.8,
    },
  },
  '(max-width: 1434px)': {
    slides: {
      perView: 1.6,
    },
  },
  '(max-width: 1376px)': {
    slides: {
      perView: 1.4,
    },
  },

  '(max-width: 1306px)': {
    slides: {
      perView: 1.3,
    },
  },
  '(max-width: 1288px)': {
    slides: {
      perView: 1.6,
    },
  },

  '(max-width: 1259px)': {
    slides: {
      perView: 1.4,
    },
  },
  '(max-width: 1215px)': {
    slides: {
      perView: 1.5,
    },
  },
  '(max-width: 1189px)': {
    slides: {
      perView: 3.2,
    },
  },

  '(max-width: 1080px)': {
    slides: {
      perView: 3.1,
    },
  },
  '(max-width: 1028px)': {
    slides: {
      perView: 3,
    },
  },
  '(max-width: 995px)': {
    slides: {
      perView: 2.8,
    },
  },
  '(max-width: 975px)': {
    slides: {
      perView: 2.6,
    },
  },
  '(max-width: 958px)': {
    slides: {
      perView: 2.4,
    },
  },
  '(max-width: 900px)': {
    slides: {
      perView: 2.2,
    },
  },
  '(max-width: 860px)': {
    slides: {
      perView: 2.1,
    },
  },
  '(max-width: 850px)': {
    slides: {
      perView: 2,
    },
  },
  '(max-width: 834px)': {
    slides: {
      perView: 1.9,
    },
  },
  '(max-width: 805px)': {
    slides: {
      perView: 1.7,
    },
  },
  '(max-width: 744px)': {
    slides: {
      perView: 1.5,
    },
  },
  '(max-width: 715px)': {
    slides: {
      perView: 1.3,
    },
  },
  '(max-width: 670px)': {
    slides: {
      perView: 1.1,
    },
  },

  '(max-width: 599px)': {
    slides: {
      perView: 2.4,
    },
  },
  '(max-width: 558px)': {
    slides: {
      perView: 2.2,
    },
  },
  '(max-width: 499px)': {
    slides: {
      perView: 1.9,
    },
  },
  '(max-width: 450px)': {
    slides: {
      perView: 1.7,
    },
  },
  '(max-width: 410px)': {
    slides: {
      perView: 1.5,
    },
  },
  '(max-width: 375px)': {
    slides: {
      perView: 1.4,
    },
  },
  '(max-width: 363px)': {
    slides: {
      perView: 1.2,
    },
  },
  '(max-width: 335px)': {
    slides: {
      perView: 1.15,
    },
  },
};
export const infoList = [
  {
    id: 1,
    title: 'Qui peut parrainer et ou avoir le code de parrainage',
    description:
      "Les élèves possédant l'une de nos grandes offres ou les élèves fidèles (adhérant à l'une de nos offres mensuelles tout au long de l'année) sont les seuls autorisés à bénéficier du programme de parrainage pour un ami",
  },
  {
    id: 2,
    title: 'Quels sont les avantages dont bénéficie ton ami utilisateur du code',
    description:
      "En achetant l'une des offres grâce à ton code, ton ami bénéficiera d'une réduction de 5% sur le prix global de l'offre",
  },
  {
    id: 3,
    title: 'Quel est le nombre de points dont je vais bénéficier pour chaque parrainage effectué',
    description:
      "En participant au programme de parrainage l'élève fidèle bénéficie de 5% du total des points du prix de l'offre acquise par l'ami qui profite du service de parrainage",
  },
  {
    id: 4,
    title: 'Comment bénéficier des points accumulés',
    description: 'Après avoir accumulé un certain nombre de Jetons, vous avez le choix entre',
    details: [
      "Les convertir en un solde de points qui vous permet d'acquérir l'une de nos offres",
      "Les échanger contre l'un de nos cadeaux",
    ],
  },
];

export const ConvertCoinsCards = [
  {
    img: boxImg,
    title: 'Cadeaux',
    description:
      "Après avoir converti tes points en jetons tu peux choisir une récompense spéciale dans notre liste de cadeaux réservés aux membres du programme de parrainage d'amis",
    link: '/parrain/products',
  },
  {
    img: offerImg,
    title: 'The points',
    description:
      "Les bénéficiaires du programme de parrainage peuvent choisir parmi nos offres disponibles et profiter d'un contenu éducatif de qualité",
    link: '/convert-coins',
    noCoins: '/parrain/no-coins',
  },
];
export const states = [
  { label: 'Tunis' },
  { label: 'Ariana' },
  { label: 'Manouba' },
  { label: 'Ben Arous' },
  { label: 'Nabeul' },
  { label: 'Bizerte' },
  { label: 'Zaghouan' },
  { label: 'Sousse' },
  { label: 'Monastir' },
  { label: 'Mahdia' },
  { label: 'Sfax' },
  { label: 'Beja' },
  { label: 'Jendouba' },
  { label: 'Kef' },
  { label: 'Siliana' },
  { label: 'Kairouan' },
  { label: 'Sidi Bouzid' },
  { label: 'Kasserine' },
  { label: 'Gabès' },
  { label: 'Medenine' },
  { label: 'Gafsa' },
  { label: 'Tozeur' },
  { label: 'Tataouine' },
  { label: 'Kebili' },
];
