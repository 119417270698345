function subscribeToPush(registration) {
  registration.pushManager
    .subscribe({ userVisibleOnly: true })
    .then((subscription) => {})
    .catch((error) => {
      console.error('Error during subscription:', error);
    });
}
export const initializeServiceWorker = (token) => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then(function (registration) {
        if (token) {
          subscribeToPush(registration);
        } else {
          unregisterServiceWorker();
        }
      })
      .catch((err) => {
        console.log('error here', err);
      });
  }
};
export async function unregisterServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((r) => {
      Promise.all(r.map((reg) => reg.unregister()));
    });
  }
}

export const notifySWLanguageChanged = (language) => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.controller?.postMessage({
      type: 'LANGUAGE_CHANGE',
      language,
    });
  }
};
