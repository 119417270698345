import { createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';
import { ord } from '../../utilities/methods';
import * as dayjs from 'dayjs';
// import { enqueueSnackbar, closeSnackbar } from './notifier';

// Slice
const slice = createSlice({
  name: 'today_events',
  initialState: {
    today_events: null,
    last_request_at: null,
  },
  reducers: {
    eventsSuccess: (state, action) => {
      // const week = action.payload.data.days;
      let today_events = action?.payload?.data?.payload?.events || [];
      // Object.keys(week).forEach((day) => {
      //   if (week[day].currentDay === true) {
      //     today_events = week[day].events || [];
      //   }
      // });
      const options = [
        { label: 'id', accessor: 'id' },
        { label: 'description', accessor: 'description' },
        { label: 'subject', accessor: 'subject.name' },
        { label: 'teacher', accessor: 'teacher' },
        { label: 'start_time', accessor: 'event.start_time' },
      ];
      state.today_events = ord(today_events, options);
      state.last_request_at = dayjs();
    },
  },
});
export default slice.reducer;

// Actions
const { eventsSuccess } = slice.actions;

export const getEvents = () => async (dispatch) => {
  // const new_key = new Date().getTime() + Math.random();
  try {
    // dispatch(
    //   enqueueSnackbar({
    //     message: 'En traitement...',
    //     options: {
    //       key: new_key,
    //       variant: 'info',
    //       persist: true,
    //     },
    //   })
    // );
    const res = await api.get('/events?offset=0&today=1');
    dispatch(eventsSuccess(res));
    // dispatch(closeSnackbar(new_key));
    // dispatch(
    //   enqueueSnackbar({
    //     message: 'Le Traitement est terminé.',
    //     options: {
    //       key: new Date().getTime() + Math.random(),
    //       variant: 'success',
    //     },
    //   })
    // );
  } catch (e) {
    // dispatch(closeSnackbar(new_key));
    // dispatch(
    //   enqueueSnackbar({
    //     message: e.message,
    //     options: {
    //       key: new Date().getTime() + Math.random(),
    //       variant: 'error',
    //     },
    //   })
    // );
    return console.error(e.message);
  }
};
