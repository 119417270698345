import React, { useEffect, useRef, useState } from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import success from '../../../assets/img/icons/success.svg';
import Button from '@material-ui/core/Button';
import WindowDimensions from '../../../utilities/windowDimenssions';

const SuccessReset = () => {
  const { t } = useTranslation();
  const {width} = WindowDimensions();
  const maxWidth = width >= 750 ? 750 : width - 50;

//  Validate with key press Enter
  useEffect(() => {
    const handleKeyPress = (e) => {
      const {keyCode} = e;
        if( keyCode === 13) {
          handleSubmit(e);
        }
    }
    window.addEventListener('keydown',handleKeyPress);
    return () => { 
       window.removeEventListener('keydown',handleKeyPress);
    }
  },);

  const handleSubmit = async (e) => {
        window.location = '/';
  };

  return (
    <div className="login-card" style={{ margin:'30px 0px'}}>
      <div className="centered-box" style={{ minHeight: 500 }}>
        <img src={success}
             width={170}
             style={{margin: 10}}/>
      <span className="title-warning">{t("Success")}</span>
      <span className="text-warning">{t("Your password has been changed successfully Now you are well protected")}</span>
        
        <Button
          variant="outlined"
          disableElevation
          onClick={(e) => handleSubmit(e)}
          className="submit-reset"
        >
          {t("Back to Home")}
        </Button>
      </div>
    </div>
  );
};

SuccessReset.propTypes = {
  dispatch: PropTypes.func,
};

export default SuccessReset;