const formatDate = (date, name, delimiter, inverse) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return delimiter
    ? inverse
      ? year + delimiter + (name ? monthNames[month - 1] : month) + delimiter + day
      : day + delimiter + (name ? monthNames[month - 1] : month) + delimiter + year
    : inverse
    ? year + '/' + month + '/' + day
    : day + '/' + month + '/' + year;
};
export default formatDate;
