import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ViewTitle from '../../components/ViewTitle';
import {
  WalletDetails,
  WalletServices,
  PointsHistory,
  ProfileWidget,
  PubsCards,
} from '../../components';

import { useDispatch, useSelector } from 'react-redux';
import { getPubsCards } from '../../redux/slices/offers';
// ta-column: use "childrens-inverse" to inverse childrens.

const Index = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { pubsCards, pubsCardsStatus } = useSelector((state) => state.offers);

  useEffect(() => {
    if (pubsCardsStatus === 'idle' && pubsCards?.length < 1) {
      dispatch(getPubsCards());
    }
  }, [pubsCards]);

  return (
    <div className="full-page">
      <ViewTitle title={t('Porte monnaie')} />
      <div className="ta-row wallet">
        <div className="ta-column right-margin layout-2">
          <div className="child-left">
            <WalletDetails />
          </div>
          <div className="child-right">
            <WalletServices />
          </div>
          <div className="child-full">
            <PointsHistory />
          </div>
        </div>
        <div className="ta-column fixed right">
          <ProfileWidget mini />
          {pubsCards
            ?.slice()
            ?.sort((a, b) => a.position - b.position)
            ?.map((card) => {
              return (
                <PubsCards
                  key={card?.id}
                  className={card?.type}
                  icon={card?.icon_url}
                  text={card?.text_image_url || card?.text}
                  isText={card?.text}
                  modal={card?.video_link != null}
                  path={card?.video_link || card?.url_link}
                  bg={card?.background_url}
                  visible={card?.visible}
                  iconPosition={card?.icon_position}
                  color={card?.color}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Index;
