import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Index = ({ data }) => {
  const { t } = useTranslation();
  const { name, details } = data;
  return (
    <>
      {data && (
        <div className="bank-account-item">
          <span className="title">{t(name)}</span>
          {details &&
            details.length > 0 &&
            details.map((item, index) => {
              const { owner, number } = item;
              return owner !== null ? (
                <div key={index}>
                  <span>
                    <span>{t("compte")}:</span>
                    {` ${owner}`}
                  </span>
                  <span>
                    {name === "La poste tunisienne" ? (
                    <span>{t("rip")}:</span>):(
                    <span>{t("rib")}:</span>)}
                    {` ${number}`}
                  </span>
                </div>
              ) : (
                <div key={index}>
                  <span>
                    <span>{t("compte")} :</span>
                    {` ${number}`}
                  </span>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

Index.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  details: PropTypes.string,
};

export default Index;
