import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import history from '../../assets/img/icons/history.png';
import { Empty } from '../../components';
import { Checkbox } from '@material-ui/core';
import { Brightness1 } from '@material-ui/icons';
import i18n from '../../services/i18n';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch } from 'react-redux';
import { openModal } from '../../redux/slices/modals';

const VideoReportsResponsiveTable = ({ cols, rows, setRows, rowsCount, emptyComponent }) => {
  const { t } = useTranslation();
  const respoContEl = useRef(null);
  const tableContEl = useRef(null);
  const paginContEl = useRef(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableContHeight, setTableContHeight] = useState(0);
  const [paginContHeight, setPaginContHeight] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRows(+event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const updateTableDemonsions = (taH, PaH) => {
    respoContEl.current.style.height = `${taH + PaH - 16}px`;
    paginContEl.current.style.marginTop = `${taH}px`;
  };

  useLayoutEffect(() => {
    setTableContHeight(tableContEl.current.clientHeight);
    setPaginContHeight(paginContEl.current.clientHeight);
    updateTableDemonsions(tableContHeight, paginContHeight);
  });

  return (
    <div className="table-responsive" ref={respoContEl}>
      <TableContainer className="table-container" ref={tableContEl} component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {cols &&
                cols
                  .filter(
                    (header) => header.accessor !== 'reasonId' && header.accessor !== 'contentId'
                  )
                  .map((header, index) => {
                    return <TableCell key={index}>{header.label}</TableCell>;
                  })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.length > 0 ? (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                    {cols &&
                      cols.length > 0 &&
                      cols
                        .filter(
                          (header) =>
                            header.accessor !== 'reasonId' && header.accessor !== 'contentId'
                        )
                        .map((col, colIndex) => {
                          if (col.accessor === 'status') {
                            return (
                              <TableCell key={colIndex}>
                                <div
                                  className={`status-container ${
                                    i18n.language === 'ar' ? 'rtl' : ''
                                  }`}
                                >
                                  <Checkbox
                                    checkedIcon={<Brightness1 style={{ width: 12, height: 12 }} />}
                                    checked
                                    disabled
                                    className={`report-status ${row.status
                                      .replace(/\s+/g, '')
                                      .toLowerCase()}`}
                                  />
                                  <span>{t(row.status)}</span>
                                </div>
                              </TableCell>
                            );
                          }

                          if (col.accessor === 'is_confirmed') {
                            return (
                              <TableCell key={colIndex}>
                                {row.status === 'Pending' && (
                                  <div className="buttons-container">
                                    <IconButton
                                      aria-label="delete"
                                      onClick={(e) => {
                                        e.preventDefault();

                                        dispatch(
                                          openModal('video-report-modal', {
                                            id: row.id,
                                            contentId: row?.contentId,
                                            name: row.content,
                                            report: row.report,
                                            reasonId: row.reasonId,
                                            edit: true,
                                          })
                                        );
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton
                                      aria-label="delete"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(openModal('delete-video-report', { id: row.id }));
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                )}
                              </TableCell>
                            );
                          }

                          return (
                            <TableCell key={colIndex}>
                              {row[col.accessor] && col.type === 'date'
                                ? dayjs(row[col.accessor]).format('DD/MM/YYYY')
                                : col.accessor !== 'reasonId' && t(row[col.accessor])}
                            </TableCell>
                          );
                        })}
                  </TableRow>
                ))
            ) : (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell colSpan={cols?.length || 1} align="center">
                  <Empty label={t('pas d historique')} icon={history} className="no-borders" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        ref={paginContEl}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowsCount || rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t('Rows per page')}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')}  ${count}`}
      />
    </div>
  );
};

VideoReportsResponsiveTable.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
};

export default VideoReportsResponsiveTable;
