import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const Index = (props) => {
  const { lesson } = props;
  return (
    <div className="video-title">
      <Typography className="title">{lesson ? lesson?.content?.name :''}</Typography>

    </div>
  );
};

Index.propTypes = {
  lesson: PropTypes.object,
};

export default Index;
