import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { CardTitle, Empty, ActionsGroup, ActionLink, FreeVideoItem } from '../../components';
import ImVideos from '../../assets/img/icons/online-course.svg';
import { getLatestCourses } from '../../redux/slices/latest_courses';

const Index = () => {
  const { t } = useTranslation();
  const { latest_courses } = useSelector((state) => state.latest_courses);
  const { informations} = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [latestVideos, setLatestVideos] = useState([]);
  useEffect(() => {
    if (!latest_courses && informations?.division_id) {
      dispatch(getLatestCourses());
    }
  }, [informations?.division_id]);
  useEffect(() => {
    if (latest_courses) {
      setLatestVideos(latest_courses || []);
    }
  }, [latest_courses]);
  const List = ({ items }) => {
    return items.map((item, index) => {
      // return <Empty key={index} label={item.chapter} icon={ImVideos} />;
      return <FreeVideoItem key={index} data={item} />;
    });
  };
  return (
    <Card className="ta-card">
      <CardHeader
        title={
          <CardTitle title={t("les derniers cours enregistres")} className="underlined last-courses" />
        }
        action={
          <ActionsGroup>
            <ActionLink
              label={t('les matieres')}
              icon={<ChevronRightIcon />}
              className="text-muted"
              url="/subjects"
            />
          </ActionsGroup>
        }
      />
      <CardContent>
        {latestVideos && latestVideos.length > 0 ? (
          <div className="latest-courses-list">
            <List key="discourse" items={latestVideos} />
          </div>
        ) : (
          <Empty label={t("pas de videos")} icon={ImVideos} />
        )}
      </CardContent>
    </Card>
  );
};

export default Index;
