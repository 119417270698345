import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TodayEventsItem, Empty } from '../../components';
import ImEvent from '../../assets/img/icons/event.svg';

const Index = ({ events }) => {
  const { t } = useTranslation();
  return (
    <div className="today-events-list" style={{ width: '100%' }}>
      {events && events.length > 0 ? (
        events.map((event, index) => {
          return <TodayEventsItem event={event} key={index} />;
        })
      ) : (
        <Empty label={t("pas de seances")} icon={ImEvent} />
      )}
    </div>
  );
};

Index.propTypes = {
  events: PropTypes.array,
};

export default Index;
