import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Popover,
  Typography,
} from '@material-ui/core';
import selectIcon from '../../../../assets/img/icons/select.svg';
import closeIcon from '../../../../assets/img/icons/plus.svg';

import './_index.scss';
import { infoList } from '../../../../utilities/constants';
import { useTranslation } from 'react-i18next';

const ParrainInfoCard = () => {
  const [accordion, setIsAccordion] = useState(5);
  const [open, setopen] = useState(true);

  const { t, i18n } = useTranslation();

  return (
    <Card className="parrain-page-card-info">
      <iframe
        src="https://www.youtube.com/embed/Vg1KjNonqA8?mute=1"
        loading="lazy"
        title="parrain-info-video-iframe"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <CardContent className="parrain-page-card-info-content">
        <Accordion
          expanded={open}
          onChange={(e, expanded) => {
            setopen(expanded);
            if (expanded) {
              setIsAccordion(5);
            } else {
              setIsAccordion(null);
            }
          }}
          className="parrain-page-card-info-accordion-wrapper"
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <AccordionSummary
            className={
              accordion === 5
                ? 'parrain-page-card-info-accordion-summary-expanded'
                : 'parrain-page-card-info-accordion-summary'
            }
          >
            <div className="parrain-page-card-info-select">
              <p> {t('How to invite someone')}</p>
              <img src={selectIcon} alt="select-icon" />
            </div>
          </AccordionSummary>
          <AccordionDetails className="parrain-page-card-info-accordion-details">
            {infoList.map((el) => {
              return (
                <Accordion
                  expanded={accordion === el.id ? true : false}
                  onChange={(e, expanded) => {
                    if (expanded) {
                      setIsAccordion(el.id);
                    } else {
                      setIsAccordion(null);
                    }
                  }}
                  className="parrain-page-card-info-info-details-accordion"
                >
                  <AccordionSummary
                    className={
                      accordion === el.id
                        ? 'parrain-page-card-info-title-expanded'
                        : 'parrain-page-card-info-title'
                    }
                    expandIcon={<img src={closeIcon} alt="close-icon" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{t(el.title)}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="parrain-page-card-info-info-details-accordion-details">
                    <Typography className="parrain-page-card-info-description">
                      {t(el.description)}
                    </Typography>
                    <ol>
                      {el.details?.map((item) => (
                        <li className="parrain-page-card-info-description">{t(item)}</li>
                      ))}
                    </ol>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};

export default ParrainInfoCard;
