export const setSearchParam = (searchName, searchValue) => {
  // Get the current URL and its search parameters
  const currentUrl = new URL(window.location.href);
  const searchParams = new URLSearchParams(currentUrl.search);

  // Add a new search parameter

  searchParams.set(searchName, searchValue);

  // Update the current URL with the new search parameters
  currentUrl.search = searchParams.toString();

  // Push the updated URL to the browser's history
  window.history.pushState({}, '', currentUrl.toString());
};
