import React, { useEffect, useState } from 'react';
import './_index.scss';
import Cart from '../components/Cart';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  TextField,
} from '@material-ui/core';
import convertCoinsImg from '../../../assets/img/parrain-Illustration.png';
import infoIcon from '../../../assets/img/icons/Info-icon.svg';
import { DivisionsReformat, avatarByGender } from '../../../utilities/methods';
import { useDispatch, useSelector } from 'react-redux';
import { convertGiftPoints, getConversionRateGiftPoint } from '../../../redux/slices/parrain';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSettings from '../../../hooks/useSettings';
import NotFound from '../../NotFound';

const ConvertCoins = () => {
  const { informations, permissions } = useSelector((state) => state.user);
  const { conversionRate, conversionRateStatus } = useSelector((state) => state.parrain);
  const [coins, setCoins] = useState('');
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { settings } = useSettings();
  const { language } = i18n;
  const avatar = avatarByGender(informations?.avatar, informations?.gender);

  useEffect(() => {
    if (
      permissions &&
      permissions?.eligibleUserForGift === true &&
      informations &&
      !informations.gift_code
    ) {
      window.location.href = '/generate-gift-code';
    }
  }, [informations, permissions]);

  const handleInputChange = (event) => {
    // Check if the input is a valid positive number
    const inputValue = event.target.value;
    if (/^\d+$/.test(inputValue) || inputValue === '') {
      setCoins(inputValue);
    }
  };

  const convertGiftPointsHandler = () => {
    dispatch(convertGiftPoints(points));
    setCoins('');
  };

  useEffect(() => {
    if (conversionRate == null && conversionRateStatus === 'idle') {
      dispatch(getConversionRateGiftPoint());
    }
  }, [conversionRate]);
  const points = Math.round(coins / conversionRate);
  return permissions?.eligibleUserForGift === true ? (
    <div
      className={
        settings.sidebar === 'full'
          ? language === 'ar'
            ? 'convert-coins-page-full-sidebar parrain-page-arabic'
            : 'convert-coins-page-full-sidebar'
          : 'convert-coins-page'
      }
    >
      <Cart />
      <Card className="convet-coins-card">
        <CardContent>
          <Box className="convet-coins-card-content">
            <Box className="convet-coins-card-img">
              <img src={convertCoinsImg} alt="convert-coins-img" />
            </Box>
            <Box className="convet-coins-card-form">
              <p className="convet-coins-card-content-description">
                {t('Introduis le nombre de jetons que tu souhaites convertir en points')}
              </p>
              <Box className="convet-coins-card-content-field-wrapper">
                <InputLabel htmlFor="coins" className="convet-coins-card-content-field-label">
                  {t('Nombre de jetons')}
                </InputLabel>
                <Box className="convet-coins-card-content-field-content">
                  <TextField
                    id="coins"
                    variant="outlined"
                    type="number"
                    size="small"
                    name="coins"
                    min="5"
                    placeholder={t('Exp1')}
                    className="convet-coins-card-content-field"
                    value={coins}
                    onChange={handleInputChange}
                  />
                  <p className="convet-coins-card-content-field-description">
                    = {points + (points > 1 ? ' ' + t('points ') : ' ' + t('point'))}
                  </p>
                </Box>
                <div className="convet-coins-card-content-field-info">
                  <img src={infoIcon} alt="info-icon" />
                  <span>
                    {t('Chaque jeton sera converti en')} {Math.round(1 / conversionRate)}{' '}
                    {t('point')}.
                  </span>
                </div>
              </Box>
            </Box>
          </Box>
          <Box className="convet-coins-card-action">
            <Link to="/gift-code" className="convet-coins-card-btn cancel-btn">
              {t('Annuler')}
            </Link>
            <Button
              disabled={coins === ''}
              className={
                coins === ''
                  ? 'convet-coins-card-btn convert-btn disabled-btn'
                  : 'convet-coins-card-btn convert-btn'
              }
              onClick={() => convertGiftPointsHandler()}
            >
              {t('Convertir')}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </div>
  ) : (
    <NotFound />
  );
};

export default ConvertCoins;
