export const diffTimes = (start, end) => {
  start = start.split(':');
  end = end.split(':');
  const startDate = new Date(0, 0, 0, start[0], start[1], 0);
  const endDate = new Date(0, 0, 0, end[0], end[1], 0);
  const diff = endDate.getTime() - startDate.getTime();
  return Math.floor(diff / 1000 / 60) / 60;
};

export const convertSecondDuration = (time) => {
  return new Date(parseInt(time) * 1000).toISOString().substr(11, 8);
};
