import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, CircularProgress } from '@material-ui/core';
import { removeReportVideo } from '../../../redux/slices/courses';
import { useTranslation } from 'react-i18next';

const DeleteVideoReportModal = ({ id, open = true, handleClose, data, ...rest }) => {
  const { i18n, t } = useTranslation();
  const language = i18n.language;
  const dispatch = useDispatch();
  const { isDeleteReportVideoLoading } = useSelector((state) => state.courses);

  const handleCloseModal = () => {
    handleClose(id);
  };
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-delete-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">{t('Delete the report')}</span>
        <span className="close-btn" onClick={() => handleCloseModal()}>
          {t('Fermer')}
        </span>
      </DialogTitle>
      <DialogContent className="reject-parent-request ta-form">
        <div className={language === 'ar' ? 'confirm-text confirm-arabic-text' : 'confirm-text'}>
          {t('Are you sure you want to delete this report ?')}
        </div>

        <div className="ta-btns-group">
          <Button className="ta-btn btn-rounded btn-lg blue" onClick={() => handleCloseModal()}>
            {t('Annuler')}
          </Button>
          <Button
            className="ta-btn btn-rounded btn-lg parent-request-confirm-btn"
            onClick={() => {
              dispatch(removeReportVideo(data?.id));
            }}
          >
            {/* {t('Delete')} */}
            {isDeleteReportVideoLoading == true ? (
              <CircularProgress style={{ color: 'white' }} />
            ) : (
              t('Delete')
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteVideoReportModal;
