import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';

const Index = (props) => {
  const { data } = props;
  useEffect(() => {}, []);

  return (
    <div>
      <Typography>{data}</Typography>
    </div>
  );
};

export default Index;
