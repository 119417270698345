import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const OfferPeriod = (props) => {
  const { i18n,t } = useTranslation();
  const { color, pricings, setPeriod, setPricingIndex, selectedPeriod, width,classCard} = props;
  return (
    <div className={`offer-periods ${classCard}`}>
      {pricings &&
          pricings.map((pricing, index) => {
          return (
            <div
                onClick={() => {
                  setPeriod(pricing.quantity ? pricing.quantity : pricing.period);
                  setPricingIndex(index);
                }}
              className={
                selectedPeriod === pricing.quantity || selectedPeriod === pricing.period
                  ? pricing.quantity
                    ? 'last-period selected-period'
                    : 'period selected-period'
                  : pricing.quantity
                  ? 'last-period'
                  : 'period'
              }
              style={{
                backgroundColor:
                  selectedPeriod === pricing.period || selectedPeriod === pricing.quantity
                    ? color
                    : null,
                borderColor: color,
                width: 70 //!pricing.quantity
                //   ? width <= 230
                //     ? ''
                //     : width <= 320 && width > 230
                //     ? width / 1.8 / pricings.length - 1
                //     : width / 1.2 / pricings.length - 1
                //   : width <= 230
                //   ? ''
                //   : width <= 320 && width > 230
                //   ? width / 2
                //   : width / 2.5,
              }}
            >
              {pricing.period} {
              //!pricing.quantity
              !isNaN
              (pricing.period) && <div>
                  { ((i18n.language === 'ar' || 'en') && (pricing.period)>=3) ? t("trois ou six mois"): t("mois")}
                </div>}
            </div>
          );
        })}
    </div>
  );
};

OfferPeriod.propTypes = {
  color: PropTypes.string,
  pricings: PropTypes.any,
  setPeriod: PropTypes.func,
  setPricingIndex: PropTypes.func,
  selectedPeriod: PropTypes.any,
};

export default OfferPeriod;
