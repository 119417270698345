import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { CardTitle, Empty, ActionsGroup, ActionLink, FreeVideoItem } from '../../components';
import ImVideos from '../../assets/img/icons/multimedia.svg';
import { getFreeVideos } from '../../redux/slices/free_videos';

const Index = () => {
  const { t } = useTranslation();
  const { free_videos } = useSelector((state) => state.free_videos);
  const { informations} = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [freeVideos, setFreeVideos] = useState([]);
  useEffect(() => {
    if (!free_videos && informations?.division_id) {
      dispatch(getFreeVideos());
    }
  }, [informations?.division_id]);
  useEffect(() => {
    if (free_videos) {
      setFreeVideos(free_videos || []);
    }
  }, [free_videos]);
  const List = ({ items }) => {
    return items.map((item, index) => {
      // return <Empty key={index} label={item.chapter} icon={ImVideos} />;
      return <FreeVideoItem key={index} data={item} />;
    });
  };
  return (
    <Card className="ta-card">
      <CardHeader
        title={<CardTitle title={t("des videos gratuites")} className="underlined free-videos" />}
        action={
          <ActionsGroup>
            <ActionLink
              label={t("les matieres")}
              icon={<ChevronRightIcon />}
              className="text-muted"
              url="/subjects"
            />
          </ActionsGroup>
        }
      />
      <CardContent>
        {freeVideos && freeVideos.length > 0 ? (
          <div className="free-videos-list">
            <List items={freeVideos} />
          </div>
        ) : (
          <Empty label={t("pas de videos")} icon={ImVideos} />
        )}
      </CardContent>
    </Card>
  );
};

export default Index;
