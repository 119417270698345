import React from 'react';
import authPageImg from '../../assets/img/student-img-bg.png';
import authPageMobileImg from '../../assets/img/mobile-img.png';
import logo from '../../assets/img/icons/new-logo.svg';
import Language from '../../pages/Register/components/Language';
import './_index.scss';

const homeURL = process.env.REACT_APP_LANDING_URL;

const AuthLayout = ({ className, children }) => {
  return (
    <div className={`auth-page ${className}`}>
      <div className="auth-page-container">
        <div className="auth-page-img">
          <img src={authPageImg} alt="student" />
        </div>
        <div className="auth-page-mobile-img">
          <img src={authPageMobileImg} alt="student" />
        </div>
        <div className="auth-page-content">
          <div className="auth-page-header">
            <Language className="Language" />
            <div className="auth-logo" onClick={() => (window.location.href = homeURL)}>
              <img src={logo} alt="takiacademy logo" />
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
