import React from 'react';
import infoImg from '../../assets/img/icons/Info_duotone_line.svg';
import './_index.scss';

const MoreInformation = ({ informations, withLabel }) => {
  return (
    <div className="more-information">
      <div className="more-information-content">
        {!withLabel ? (
          <div className="more-information-details">
            {informations?.map((information, index) => (
              <h5 key={index}>{information}</h5>
            ))}
          </div>
        ) : (
          informations?.map((information, index) => (
            <div key={index}>
              <h3 className="more-information-title">{information?.label}</h3>
              <div className="more-information-details">
                {information?.subjects?.map((subject, index) => (
                  <p key={index}>{subject}</p>
                ))}
              </div>
            </div>
          ))
        )}
      </div>
      <div className="more-information-icon">
        <span>i</span>
      </div>
    </div>
  );
};

export default MoreInformation;
