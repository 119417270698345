import React from 'react';
import PropTypes from 'prop-types';

const Index = ({ title }) => {
  return <div className="view-title">{title && <span>{title}</span>}</div>;
};

Index.propTypes = {
  title: PropTypes.string,
};

export default Index;
