import React from 'react';
import './_index.scss';
import { Button, Card } from '@material-ui/core';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ConvertCoinsCard = ({ img, title, description, link }) => {
  const { t } = useTranslation();
  return (
    <Card className="convert-coins-card">
      <div className="convert-coins-card-img">
        <img src={img} alt="offer-icon" />
      </div>
      <Typography className="convert-coins-title"> {t(title)}</Typography>
      <Typography className="convert-coins-description">{t(description)}</Typography>

      <Button className="convert-coins-btn" onClick={() => (window.location.href = link)}>
        {t('Commencer')}
      </Button>
    </Card>
  );
};

export default ConvertCoinsCard;
