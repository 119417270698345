import React from 'react';
import PropTypes from 'prop-types';

const ActionsGroup = ({ children }) => {
  return <div className="actions-group">{children && children}</div>;
};

ActionsGroup.propTypes = {
  children: PropTypes.any,
};

export default ActionsGroup;
