import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Modal from '../../../components/Modal/Modal';
import SessionModal from '../../../components/common/SessionModal/index';
import { useDispatch, useSelector } from 'react-redux';
import { getSessions } from '../../../redux/slices/sessions';
import EventResult from '.././EventResult';
import HessatyModal from '../../../components/common/SessionModal/HessatyModal';
import PropTypes from 'prop-types';
import { calenderTimes, cardDefaultHour, days } from '../../../utilities/constants';
import { diffTimes } from '../../../utilities/timeMethods';
import { extractMonthFromWeek, extractMonthFromDay } from '../../../utilities/translateHelper';

const Calender = ({
  events,
  week,
  currentWeek,
  setOffset,
  studentLevels,
  openSnackBar,
  openLink,
  setOpenLink,
  setOpenSnackBar,
  open,
  modalButton,
  profSon,
  versPilote,
  joinEvent,
  startEvent,
  currentSession,
  openEventModal,
  onCloseModal,
  buyHessaty,
  disableBuy,
  divisionId,
  notificationEventId,
  openModal,
}) => {
  const { i18n, t } = useTranslation();
  let currentDay = null;
  const { informations } = useSelector((state) => state.user);
  const { joinResult, sessionLink, joinLoading, errorSession } = useSelector(
    (state) => state.sessions
  );
  const { error, loadingId } = useSelector((state) => state.offers);
  const sessionPilote = currentSession?.event?.versPilote;
  const isallowedPilote = versPilote && currentSession?.event?.versPilote;
  const offerSecondTirm = informations?.offers?.find(
    (offer) => offer.group.id === 107 || offer.group.id === 112
  );
  const isallowedProfSon = (profSon || offerSecondTirm) && !sessionPilote;
  const isVersPiloteSixDegree = versPilote && divisionId === 31;
  const currentNotifEvent =
    events && events.find((event) => event?.event?.id === parseInt(notificationEventId));

  useEffect(() => {
    if (currentNotifEvent) openModal(true);
    if (!currentSession && !currentNotifEvent) openModal(false);
  }, []);
  const dispatch = useDispatch();
  const checkCurrent = (day) => {
    if (day.currentDay) {
      currentDay = day.id;
    }
  };
  let currentTime = new Date();
  currentTime = currentTime.getHours() + ':' + ('00' + currentTime.getMinutes()).slice(-2);
  const validEvent = (day, timeValue) => {
    return events.filter(function (event) {
      if (
        event.day === day.index &&
        timeValue.time.substr(0, 2) === event.event.start_time.substr(0, 2)
      ) {
        return event;
      }
      return null;
    });
  };

  const translateWeekDateCalender = (currentWeek) => {
    const currentWeekArr = extractMonthFromWeek(currentWeek);
    if (currentWeekArr) {
      return (
        currentWeekArr[0] +
        t(currentWeekArr[1]) +
        ' - ' +
        currentWeekArr[2] +
        t(currentWeekArr[3]) +
        ' ' +
        currentWeekArr[4]
      );
    }
  };

  const translateMonthDay = (day) => {
    const dayDate = extractMonthFromDay(day);
    if (dayDate) {
      return dayDate[0] + ' ' + t(dayDate[1]);
    }
  };

  return (
    <div className="calender">
      {studentLevels && !!studentLevels.length && (
        <div className="student-levels">
          {studentLevels &&
            studentLevels.map((studentLevel) => {
              return (
                <div className="levels-card">
                  <div className="subject-name">{studentLevel?.subject?.name}</div>
                  <div className="student-level">{studentLevel?.student_level?.name}</div>
                </div>
              );
            })}
        </div>
      )}
      <div className="changeWeek">
        <div className="headerDate">
          <ArrowBackIosIcon
            className="headerIcon"
            onClick={() =>
              i18n.language === 'ar'
                ? setOffset((prevCount) => prevCount + 1)
                : setOffset((prevCount) => prevCount - 1)
            }
          />
          <Typography variant="h5" className="headerDateText">
            {translateWeekDateCalender(currentWeek?.now)}
          </Typography>
          <ArrowBackIosIcon
            className="headerIcon"
            style={{ transform: 'rotate(180deg)' }}
            onClick={() =>
              i18n.language === 'ar'
                ? setOffset((prevCount) => prevCount - 1)
                : setOffset((prevCount) => prevCount + 1)
            }
          />
        </div>
        <div className="refreshHeader" onClick={() => dispatch(getSessions(0))}>
          <RefreshIcon fontSize="small" />
          <Typography className="refreshText">{t('Actualiser')}</Typography>
        </div>
      </div>
      <div>
        <Table className="table">
          <TableHead>
            <TableRow className="week">
              <TableCell key={0} className="weekDay">
                <div className="dayOfWeekName">{ }</div>
                <div className="dayOfWeekDate">{ }</div>
              </TableCell>
              {week &&
                Object.keys(week)?.map((key, index) => {
                  return (
                    <TableCell
                      key={index}
                      className={!week[key].currentDay ? 'weekDay' : 'currentWeekDay'}
                    >
                      {checkCurrent(week[key])}
                      <div className="dayOfWeekName">{t(`${week[key].translated_name}`)}</div>
                      <div className="dayOfWeekDate">{translateMonthDay(week[key].day)}</div>
                    </TableCell>
                  );
                })}
            </TableRow>
            <TableRow className="weekBlank">
              <TableCell key={0} className="blankWeekDay"></TableCell>
              {week &&
                Object.keys(week)?.map((key, index) => {
                  return (
                    <TableCell
                      key={index}
                      className={!week[key].currentDay ? 'blankWeekDay' : 'currentBlankWeekDay'}
                    ></TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody className="calenderTable">
            {calenderTimes.map((calenderTime, indexR) => (
              <TableRow key={indexR} className="calenderBody">
                {days.map((day, indexC) => {
                  if (day.index === 0) {
                    return (
                      <TableCell className="calenderEvent" key={indexC}>
                        <div>{calenderTime.time}</div>
                      </TableCell>
                    );
                  } else if (events && validEvent(day, calenderTime).length) {
                    const currentEvent = [];
                    const dayExist = [];
                    for (let [index, session] of validEvent(day, calenderTime).entries()) {
                      const inProgressSession =
                        currentDay == session.day &&
                        session.event.start_time <= currentTime &&
                        session.event.end_time >= currentTime;
                      let hours = diffTimes(session.event.start_time, session.event.end_time);
                      const height = 45 * hours;
                      const minutes = session.event.start_time.substr(3, 4);

                      const top = minutes !== '00' && minutes !== '01'
                        ? (minutes === '25' ? 20 : minutes === '30' ? 23 : minutes === '15' ? 11 : +minutes)
                        : 0;

                      const width =
                        (validEvent(day, calenderTime).length > 1 ? 101 : 98) /
                        validEvent(day, calenderTime).length;
                      const left = index > 0 ? (width - 2.5) * index : 1;
                      currentEvent.push(
                        <div
                          className="singleEvent"
                          key={session.index}
                          style={{
                            height: height,
                            zIndex: index,
                            position: 'absolute',
                            width: `${width}%`,
                            left: `${left}%`,
                            borderColor: !session.disabled ? '#3DEC37' : '#2ba7df',
                            top: top,
                          }}
                          key={index}
                          onClick={() => openEventModal(session)}
                        >
                          <div className="cardEventHeader">
                            <img
                              // width={'30%'}
                              height={hours > cardDefaultHour ? height / 3 : height / 2.5}
                              src={session.subject.thumbnail}
                            />
                            <div
                              className="eventName"
                              style={{
                                fontSize: hours > cardDefaultHour ? height / 10.5 : height / 7,
                              }}
                            >
                              {session.name.length > 30
                                ? session.name.substring(0, 30 - 3) + ' ...'
                                : session.name}
                            </div>
                          </div>
                          <div className="cardEventFooter">
                            <div className="cardEventTime">
                              <div
                                style={{
                                  fontSize: hours > cardDefaultHour ? height / 12 : height / 8,
                                }}
                              >
                                {session.event.start_time}
                              </div>
                              <div
                                style={{
                                  fontSize: hours > cardDefaultHour ? height / 12 : height / 8,
                                }}
                              >
                                {t('à')}
                              </div>
                              <div
                                style={{
                                  fontSize: hours > cardDefaultHour ? height / 12 : height / 8,
                                }}
                              >
                                {session.event.end_time}
                              </div>
                            </div>
                            <div
                              className="eventStatus"
                              style={{
                                height: hours > cardDefaultHour ? 18 : 15,
                              }}
                            >
                              <div
                                className="eventStatusText"
                                style={{
                                  backgroundColor: inProgressSession
                                    ? '#EE6969'
                                    : !session.disabled
                                      ? '#3DEC37'
                                      : '#2ba7df',
                                  fontSize: hours > cardDefaultHour ? height / 12 : height / 9,
                                }}
                              >
                                {inProgressSession
                                  ? t('En Cours')
                                  : indexC >= currentDay && !session.disabled
                                    ? t('Bientôt')
                                    : t('Terminée')}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                      dayExist.push({ day: calenderTime.time });
                    }
                    return (
                      <TableCell
                        key={indexC}
                        className={
                          indexC === currentDay
                            ? indexR === calenderTimes.length - 1
                              ? 'lastCurrentDay calenderEvent'
                              : 'currentDay calenderEvent'
                            : 'calenderEvent'
                        }
                      >
                        <div
                          style={{ zIndex: 0, position: 'relative' }}
                          className="calenderEventHours"
                        >
                          {currentEvent}
                        </div>
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        key={indexC}
                        className={
                          indexC === currentDay
                            ? indexR === calenderTimes.length - 1
                              ? 'lastCurrentDay calenderEvent'
                              : 'currentDay calenderEvent'
                            : 'calenderEvent'
                        }
                      >
                        <div>{ }</div>
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {open && (
        <Modal
          onCloseModal={onCloseModal}
          component={
            currentSession?.event?.chapterId &&
              !currentSession.event?.has_hessaty_chapter &&
              !isallowedProfSon &&
              !isallowedPilote &&
              !isVersPiloteSixDegree
              ? HessatyModal
              : SessionModal
          }
          openModal={open}
          title={
            currentSession?.event?.versPilote == true
              ? t('Séance en direct Vers Pilote')
              : t('Séance en direct')
          }
          modalAction={
            currentSession && !currentSession.participated
              ? currentSession?.event?.chapterId &&
                !currentSession?.event?.has_hessaty_chapter &&
                !isallowedProfSon &&
                !isallowedPilote &&
                !isVersPiloteSixDegree
                ? buyHessaty
                : joinEvent
              : startEvent
          }
          disableBuy={disableBuy}
          modalActionName={modalButton}
          modalProps={currentSession || currentNotifEvent}
          modalWidth={'xs'}
          isLoading={loadingId || joinLoading || false}
        />
      )}
      <EventResult
        join={joinResult}
        link={sessionLink}
        setOpenSnackBar={setOpenSnackBar}
        setOpenLink={setOpenLink}
        open={openLink}
        openSnackBar={openSnackBar}
        loading={joinLoading}
        error={
          currentSession?.event?.chapterId ? (error !== false ? error : errorSession) : errorSession
        }
      />
    </div>
  );
};

Calender.propTypes = {
  events: PropTypes.any,
  week: PropTypes.any,
  currentWeek: PropTypes.any,
  setOffset: PropTypes.func,
};

export default Calender;
