import React from 'react';
import './_index.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import formatAmount from '../../../../utilities/formatAmount';
const ProductCard = ({ img, name, coins }) => {
  const { t, i18n } = useTranslation();

  return (
    <Link className="product-card-wrapper" to="/parrain/products">
      <div className="product-card">
        <div className="product-card-img">
          <img src={img} alt="product-img" />
        </div>
        <div className="product-card-content">
          <div className="poduct-name">{name}</div>
          <div className="card-btn blue-btn">{formatAmount(coins, t)}</div>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;
