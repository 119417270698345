import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ProductsList from '../components/ProductsList';
import ParrainInfoCard from '../components/ParrainInfoCard';
import CopyCodeCard from '../components/CopyCodeCard';
import BalanceCard from '../components/BalanceCard';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { Empty, ResponsiveTable } from '../../../components';
import ImPoints from '../../../assets/img/icons/discount.svg';
import './_index.scss';
import { getGeneratedGiftsHistory, getPurchaseHistory } from '../../../redux/slices/parrain';
import PageHeader from '../components/PageHeader';
import Tab from '../components/Tab';
import PurchaseHistoryTable from '../../../components/ResponsiveTable/PurchaseHistoryTable';
import useSettings from '../../../hooks/useSettings';
import NotFound from '../../NotFound';

const GiftCode = () => {
  const {
    generatedGiftsHistory,
    generatedGiftsHistoryStatus,
    purchaseHistory,
    purchaseHistoryStatus,
  } = useSelector((state) => state.parrain);

  const { informations, permissions } = useSelector((state) => state.user);
  const [userData, setUserData] = useState([]);
  const [purchaseData, setPurchaseData] = useState([]);
  const { settings } = useSettings();

  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  useEffect(() => {
    if (
      permissions &&
      permissions?.eligibleUserForGift === true &&
      informations &&
      !informations.gift_code
    ) {
      window.location.href = '/generate-gift-code';
    }
  }, [informations, permissions]);

  useEffect(() => {
    if (
      generatedGiftsHistory?.length === 0 &&
      generatedGiftsHistoryStatus === 'idle' &&
      selectedTab === 0
    ) {
      dispatch(getGeneratedGiftsHistory());
    }
  }, [generatedGiftsHistory, selectedTab]);

  useEffect(() => {
    if (purchaseHistory?.length === 0 && purchaseHistoryStatus === 'idle' && selectedTab === 1) {
      dispatch(getPurchaseHistory());
    }
  }, [purchaseHistory, selectedTab]);

  useEffect(() => {
    if (generatedGiftsHistory) {
      const generatedGiftsHistoryData = generatedGiftsHistory?.map((el) => ({
        user: el?.user?.name + ' ' + el?.user?.last_name,
        date: el?.affiliation?.start_date,
        offer: el?.affiliation?.group?.name,
        profit_amount: el?.profit_amount,
      }));

      setUserData(generatedGiftsHistoryData || []);
    }
  }, [generatedGiftsHistory]);

  useEffect(() => {
    if (purchaseHistory) {
      const purchaseHistoryData = purchaseHistory?.map((el) => ({
        id: el.id,
        amount: el?.amount,
        number: el?.gift_request_items?.length,
        note: el?.note,
        status: el?.status,
        gift_request_items: el?.gift_request_items,
        gift_request_deliveries: el?.gift_request_deliveries,
      }));

      setPurchaseData(purchaseHistoryData);
    }
  }, [purchaseHistory]);

  const userHeader = [
    { accessor: 'user', label: t('Used by the user') },
    { accessor: 'date', label: t('Date'), type: 'date' },
    { accessor: 'offer', label: t('Offer') },
    { accessor: 'profit_amount', label: t('Jetons') },
  ];
  const purchaseHeader = [
    { accessor: 'id', label: t('ID commande') },
    { accessor: 'amount', label: t('Jetons') },
    { accessor: 'number', label: t('Number of gifts') },
    { accessor: 'note', label: t('Note') },
    { accessor: 'status', label: t('Status') },
    { accessor: '', label: '' },
  ];

  return permissions?.eligibleUserForGift === true ? (
    <div
      className={
        language === 'ar'
          ? 'parraing-gift-code-page parrain-page-arabic'
          : 'parraing-gift-code-page'
      }
    >
      <PageHeader
        paths={[
          {
            label: 'Parrain',
          },
        ]}
      />

      <Box className="parraing-gift-code-page-content">
        <Box
          className={
            settings.sidebar === 'full'
              ? 'parraing-gift-code-after-generation-cards-full-sidebar'
              : 'parraing-gift-code-after-generation-cards'
          }
        >
          <CopyCodeCard className="copy-card-after-code-generation" />
          <BalanceCard />
        </Box>
        <Card className="ta-card">
          <div className="card-header-wrapper">
            <CardHeader
              title={
                <Tab
                  title={t("Historique de l'utilisateur")}
                  className={
                    selectedTab === 0 ? 'parrain-array-selected-title' : 'parrain-array-title'
                  }
                />
              }
              onClick={() => setSelectedTab(0)}
            />
            <CardHeader
              title={
                <Tab
                  title={t('Historique des commandes')}
                  className={
                    selectedTab === 1 ? 'parrain-array-selected-title' : 'parrain-array-title'
                  }
                />
              }
              onClick={() => setSelectedTab(1)}
            />
          </div>

          <CardContent>
            <div className="child-full">
              {selectedTab === 0 ? (
                <ResponsiveTable
                  cols={userHeader}
                  rows={userData}
                  emptyComponent={
                    <Empty label={t('pas de jetons')} icon={ImPoints} className="no-borders" />
                  }
                  className="user-history"
                />
              ) : (
                <PurchaseHistoryTable
                  cols={purchaseHeader}
                  rows={purchaseData}
                  emptyComponent={
                    <Empty label={t('pas de jetons')} icon={ImPoints} className="no-borders" />
                  }
                  className="purchase-history"
                />
              )}
            </div>
          </CardContent>
        </Card>
        <Box className="parraing-gift-code-page-products-and-info-card">
          <ProductsList seeMore={informations?.gift_code} />
          <ParrainInfoCard />
        </Box>
      </Box>
    </div>
  ) : (
    <NotFound />
  );
};

export default GiftCode;
