import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Index = ({ label, path, className }) => {
  const { t } = useTranslation();
  return (
    <Link to={path ? path : '#'} className={`show-more-link${className ? ' ' + className : ''}`}>
      {label ? label : t('voir plus')}
    </Link>
  );
};

Index.propTypes = {
  label: PropTypes.string,
  path: PropTypes.string,
  className: PropTypes.string,
};

export default Index;
