import React from 'react';
import { ReportProblem as ReportProblemIcon } from '@mui/icons-material';
import './_index.scss';

function TextError({ children }) {
  return (
    <div className="error">
      {children?.trim().length > 0 && <ReportProblemIcon />}
      {children}
    </div>
  );
}

export default TextError;
