import React, { useState, useEffect } from 'react';

const Index = (props) => {
  const { data } = props;
  useEffect(() => {}, []);

  return (
    <div className="videoModal">
      {data && (
        <iframe
          title="ytplayer"
          id="ytplayer"
          className="embed-responsive-item"
          type="text/html"
          src={data}
          frameBorder="0"
          allowFullScreen
          //  top= "-50%"
          width="100%"
          height="100%"
        ></iframe>
      )}
    </div>
  );
};

export default Index;
