import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import { TextField, Typography, InputAdornment } from '@material-ui/core';
import { Dialog, DialogContent } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import iconHeader from '../../assets/img/icons_coup.svg';
import wrongIcon from '../../assets/img/wrong.svg';
import correctIcon from '../../assets/img/correct.svg';
import { addDiscountCode } from '../../redux/slices/points';

const Index = (props) => {
  const { open, setOpen,handleClick,offre } = props;
  const { discount, discountError } = useSelector((state) => state.points);
  const { permissions} = useSelector((state) => state.user);
  const dispatch = useDispatch();
  //const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [code, setCode] = React.useState('');
  const [showMessage, setShow] = React.useState(false);

  

const handleClose = () => {
    setOpen(false);
    setShow(false);
  };
  const handleSubmit = () => {
    dispatch(addDiscountCode(code));
     setOpen(true)
    setShow(true);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      titleStyle={{ textAlign: 'center' }}
      aria-describedby="alert-dialog-description"
      className="confirmationModal"
      fullWidth={true}
      maxWidth="xs"
    >
     <div className="modal-close-confirmation" onClick={handleClose}>
        <IconButton onClick={handleClose}>
          <CloseIcon className="close-confirmation" />
        </IconButton>
        </div>
     
      <DialogTitle className="confirmationTitle" style={{ flex: 1 }}>
        Confirmation d'achat
      </DialogTitle>

      <DialogContent className="content-confirmation">
        <div className="message-group">
        
            <span className="message-confirmation">
            Vous allez acheter l'offre {offre} avec une réduction de {permissions.discountType=='percentage'? `${permissions.discountAmount}% .` :`${permissions.discountAmount} pts.`}
            </span>
       
         
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClick}
          className="confirmation"
          style={{ backgroundColor: '#2BA7DF' }}
          //disabled={disableBuy}
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Index.propTypes = {
  onCloseModal: PropTypes.func,
};

export default Index;