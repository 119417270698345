import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import './_index.scss';
import Input from '../../components/Form/Input';
import { Form, Formik } from 'formik';
import { Alert, Box, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/slices/auth';
import ReCAPTCHA from 'react-google-recaptcha';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useLocation } from 'react-router-dom';
import removePonctuation from '../../utilities/removePonctuation';
import AuthLayout from '../../layouts/AuthLayout';
import { setSearchParam } from '../../utilities/setSearchParam';
import useGetFcmToken from '../../hooks/useGetFcmToken';
// import { sendFcmTokens } from '../../redux/slices/notifications';
// import CryptoJS from "crypto-js";
// import { FormControlLabel, FormGroup, Switch } from '@material-ui/core';
const Login = () => {
  const [showPassword, setshowPassword] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { success, authResult, loginSuccess } = useSelector((state) => state.auth);
  const recaptchaRef = useRef();
  const [formErrors, setFormErrors] = useState();
  const [extraErrors, setExtraErrors] = useState();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [rememberMe, setRememberMe] = useState(false);
  // const secretKey = process.env.REACT_APP_CRYPT_SECRET_KEY;
  const language = i18n.language;
  const fcmToken = useGetFcmToken();

  useEffect(() => {
    document.title = 'Login | Takiacademy';
  }, []);

  const location = useLocation();

  const previousUrl = document.referrer;

  useEffect(() => {
    if (location.state) {
      window.history.href = `${previousUrl}?${location.state}`;
    }
  }, [location.state]);

  const referrer = window.location.search;

  useEffect(() => {
    if (referrer) {
      sessionStorage.setItem('nextPage', referrer);
    }
  }, [referrer]);

  useEffect(() => {
    const nextPage = sessionStorage.getItem('nextPage');
    const clientType = sessionStorage.getItem('clientType');

    if (nextPage) {
      setSearchParam('nextPage', 'offres');
    }
    if (nextPage && clientType) {
      setSearchParam('clientType', 'new');
    }
  }, []);

  useEffect(() => {
    setFormErrors((prev) => ({ ...prev }));
  }, [i18n.language]);

  useEffect(() => {
    if (extraErrors || success) {
      setOpenSnackBar(true);
    }
  }, [extraErrors, success]);
  // const storedValues = localStorage.getItem("credentiel");
  // useEffect(() => {
  //   if (storedValues) {
  //     setRememberMe(true)
  //   } else setRememberMe(false)
  // }, [storedValues]);

  // const initialValues = {
  //   username: storedValues ? JSON.parse(storedValues).username : '',
  //   password: storedValues ? 'password crypted' : '',
  // };

  const initialValues = {
    username: '',
    password: '',
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .required('Veuillez saisir votre adresse e-mail/numéro de téléphone')
      .test(
        'test-name',
        'Veuillez saisir un numéro de téléphone/une adresse e-mail valide',
        function (value) {
          const emailRegex = /.+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

          const phoneRegex = /^((\+|00)216)?([2459][0-9]{7})+$/;
          let isValidEmail = emailRegex.test(value);
          let isValidPhone = phoneRegex.test(value);
          if (!isValidEmail && !isValidPhone) {
            return false;
          }
          return true;
        }
      ),
    password: Yup.string()
      .min(8, 'Mot de passe minimum 8 caractères')
      .required('Veuillez saisir votre mot de passe'),
  });

  const onSubmit = async (values, submitProps) => {
    try {
      setIsSubmitting(true);
      setExtraErrors('');
      // if (storedValues && rememberMe) {
      //   if (values.password === "password crypted") {
      //     values.password = CryptoJS.AES.decrypt(JSON.parse(storedValues).password, secretKey).toString(CryptoJS.enc.Utf8)
      //   }
      // }
      recaptchaRef.current
        .execute()
        .then(async (res) => {
          dispatch(login({ data: { ...values, 'g-recaptcha-response': res }, fcmToken: fcmToken }))
            .then(async (response) => {
              setIsSubmitting(false);
              // if (rememberMe && !response) {
              //   const encryptedPassword = CryptoJS.AES.encrypt(values.password, secretKey).toString();
              //   const encryptedValues = { ...values, password: encryptedPassword };
              //   localStorage.setItem("credentiel", JSON.stringify(encryptedValues));
              // }
              if (response?.payload?.includes('Server')) {
                setExtraErrors("Une erreur s'est produite, veuillez réessayer ultérieurement");
              } else {
                setExtraErrors(removePonctuation(response?.payload));
              }
            })
            .catch((error) => setExtraErrors(removePonctuation(error)));
        })

        .catch((error) => setExtraErrors(removePonctuation(error)));

      submitProps.setStatus({ success: true });
      submitProps.setSubmitting(false);
    } catch (err) {
      if (!err.message) {
        setExtraErrors('error');
      } else {
        setExtraErrors(removePonctuation(err.message));
      }

      submitProps.setStatus({ success: false });
      submitProps.setSubmitting(false);
    }
  };

  // const handleRememberMe = (e) => {
  //   setRememberMe(e.target.checked)
  //   if (e.target.checked === false) {
  //     localStorage.removeItem("credentiel")
  //   }
  // }

  return (
    <AuthLayout className="login-page">
      <div className="login-page-content">
        <h1 className="content-title">{t('Se connecter')}</h1>
        <div className="auth-form small-form">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              setFormErrors(formik.errors);

              return (
                <Form className="">
                  <Box
                    rowGap={3}
                    columnGap={2}
                    display="grid"
                    gridTemplateColumns={{
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(1, 1fr)',
                    }}
                  >
                    <Input
                      type="text"
                      label={t('Numéro de téléphone ou email')}
                      name="username"
                      placeholder={t('Email ou numéro de téléphone')}
                      required
                      error={
                        extraErrors?.includes('Invalid credentials')
                          ? ' '
                          : formik.errors.username && formik.touched.username
                          ? formErrors.username
                          : null
                      }
                    />

                    <Input
                      type={`${showPassword ? 'text' : 'password'}`}
                      label={t('Mot de passe')}
                      name="password"
                      className="signin-form"
                      placeholder={t('Entrez votre mot de passe')}
                      leftIcon={
                        language == 'ar' &&
                        (showPassword ? (
                          <VisibilityOutlinedIcon
                            onClick={() => setshowPassword(!showPassword)}
                            color="#0000"
                            style={{ cursor: 'pointer' }}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            onClick={() => setshowPassword(!showPassword)}
                            color="#0000"
                            style={{ cursor: 'pointer' }}
                          />
                        ))
                      }
                      rightIcon={
                        language !== 'ar' &&
                        (showPassword ? (
                          <VisibilityOutlinedIcon
                            onClick={() => setshowPassword(!showPassword)}
                            color="#0000"
                            style={{ cursor: 'pointer' }}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            onClick={() => setshowPassword(!showPassword)}
                            color="#0000"
                            style={{ cursor: 'pointer' }}
                          />
                        ))
                      }
                      required
                      error={
                        extraErrors?.includes('Invalid credentials')
                          ? ' '
                          : formik.errors.password && formik.touched.password
                          ? formErrors.password
                          : null
                      }
                    />
                  </Box>
                  <div className="forget-password-remmeber-me-container">
                    {' '}
                    <Link
                      to={{
                        pathname: '/create/reset-password',
                        state: window.location.href.split('?')[1],
                      }}
                      className={
                        language === 'ar' ? 'forgot-password arabic-text' : 'forgot-password'
                      }
                    >
                      <span>{t('Mot de passe oublié')}</span>
                    </Link>
                    {/* <FormGroup style={{ paddingTop: "18px" }}
                    >
                      <FormControlLabel control={<Switch
                        checked={rememberMe}
                        color="primary"
                        edge="start"
                        name={"remember me"}
                        onChange={handleRememberMe}
                      />} label="Remember me" />

                    </FormGroup> */}
                  </div>

                  <button
                    type="submit"
                    className={
                      isSubmitting || !formik.isValid ? 'auth-btn disabled-auth-btn' : 'auth-btn'
                    }
                    disabled={isSubmitting || !formik.isValid}
                  >
                    {isSubmitting ? <CircularProgress color="inherit" /> : t('Se connecter')}
                  </button>
                </Form>
              );
            }}
          </Formik>
          <span className="create-new-account">
            {t("Vous n'avez pas de compte ?") + ' '}
            <Link to="/register">{t('Créez un nouveau compte')}</Link>
          </span>
          <Snackbar
            open={openSnackBar}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            onClose={() => setOpenSnackBar(false)}
          >
            <Alert
              severity={success === false ? 'error' : 'success'}
              onClose={() => setOpenSnackBar(false)}
            >
              {authResult ? t(removePonctuation(authResult)) : t(removePonctuation(extraErrors))}
            </Alert>
          </Snackbar>
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          size="invisible"
        />
      </div>
    </AuthLayout>
  );
};

export default Login;
