import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  makeStyles,
  TableHead,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  DialogTitle,
} from '@material-ui/core';
import { Dialog, DialogContent } from '@material-ui/core';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
  error: {
    border: '1px solid red',
    marginBottom: '0px',
    '&$focused': {
      color: '#4A90E2',
    },
    '&$focused $notchedOutline': {
      border: '0.5px solid red',
    },
  },
  MuiTableCell_stickyHeader: {
    backgroundColor: '#ebebf3',
    borderBottom: '1px solid #d6d6da',
    padding: '2px 6px',
    paddingBottom: '12px',
    color: '#ababb0',
    fontSize: '18px',
    fontWeight: 400,
    textAlign: 'left',
  },
  tab_row: {
    color: '#fffff',
    fontSize: '16px !important',
    padding: '8px',
    '&:hover': {
      color: '#212121',
    },
  },
  tab_row_rtl: {
    direction: 'rtl',
    textAlign: 'right',
  },
  MuiTableCell_stickyHeader_rtl: {
    backgroundColor: '#ebebf3',
    borderBottom: '1px solid #d6d6da',
    padding: '2px 6px',
    paddingBottom: '12px',
    color: '#ababb0',
    fontSize: '18px',
    fontWeight: 400,
    textAlign: 'left',
    direction: 'rtl',
  },
}));

const Index = (props) => {
  const { i18n, t } = useTranslation();
  const { open, setOpen, handleAcheter, prix, color, amount, offerId, selectedPeriod } = props;
  const { benefitHistorySuccess } = useSelector((state) => state.points);
  const classes = useStyles();
  const [showMessage, setShow] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setShow(false);
  };
  const headers = [
    { accessor: 'payed_at', label: t('Date de paiement') },
    { accessor: 'amount', label: t('Montant reçu') },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      titleStyle={{ textAlign: 'center' }}
      aria-describedby="alert-dialog-description"
      className="couponModal"
      fullWidth={true}
      maxWidth="md"
    >
      <div className="modalHeader">
        <DialogTitle id="alert-dialog-title" style={{ color: '#2ba7df' }}>
          {t('Historique de paiement')}
        </DialogTitle>
        <DialogTitle id="alert-dialog-title" className="modalClose" onClick={handleClose}>
          <span className="closeTitle">{t('Fermer')}</span>
        </DialogTitle>
      </div>
      <DialogContent className={'content-history'}>
        <TableContainer className="table-container">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers &&
                  headers.length > 0 &&
                  headers.map((col, index) => {
                    return (
                      <TableCell
                        className={
                          i18n.language === 'ar'
                            ? classes.MuiTableCell_stickyHeader_rtl
                            : classes.MuiTableCell_stickyHeader
                        }
                        key={index}
                      >
                        {col.label}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {benefitHistorySuccess ? (
                benefitHistorySuccess
                  .slice()
                  // .sort((a, b) =>
                  //   a?.discountCode?.expired_at < b?.discountCode?.expired_at ? 1 : -1
                  // )
                  .map((row, index) => {
                    const newRow = {
                      payed_at: dayjs(row.payed_at).format('DD/MM/YYYY'),
                      amount: `${row.amount} ${t('TND')}`,
                    };
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {headers &&
                          headers.length > 0 &&
                          headers.map((col, index) => {
                            return (
                              <TableCell
                                className={
                                  i18n.language === 'ar' ? classes.tab_row_rtl : classes.tab_row
                                }
                                key={index}
                                align="center"
                              >
                                {newRow[col.accessor]}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow hover role="checkbox" tabIndex={-1}>
                  <TableCell
                    className={i18n.language === 'ar' ? classes.tab_row_rtl : classes.tab_row}
                    colSpan={headers && headers.length}
                    style={{ textAlign: 'center' }}
                  >
                    <span style={{ color: '#a2a2a7' }}>{t('Aucun Historique ')}</span>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

Index.propTypes = {
  onCloseModal: PropTypes.func,
};

export default Index;
