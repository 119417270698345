import React from 'react';
import { ReportProblem as ReportProblemIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import './_index.scss';

function TextError({ children }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return (
    children?.trim().length > 0 && (
      <div className={language === 'ar' ? 'arabic-error  error' : 'error'}>
        {language === 'ar' ? (
          <>
            {t(children)} {<ReportProblemIcon />}
          </>
        ) : (
          <>
            {<ReportProblemIcon />}
            {t(children)}
          </>
        )}
      </div>
    )
  );
}

export default TextError;
